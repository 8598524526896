import {
  ActionTypeEnum,
  RequestActionModel,
  TableActionColumnsStyleEnum,
} from "../../../../../models";
import { getLabelName, isArabicCurrentLanguage } from "../../../../../utils";
import { ColumnActions } from "../utils/actions";
import { Dropdown } from "react-bootstrap";
import { ButtonBox, PrivilegesChecker, CustomDropdown } from "../../../..";
import { IconType } from "react-icons";
import { TableColumn } from "react-data-table-component";
import React from "react";
import "../../css/dataTable.css"
//#region 1. GetActionsColumns
export const getServerActionsColumns = (
    actionsPermitted: ActionTypeEnum[],
    formId: number,
    onActionEvent: (o: RequestActionModel) => void,
    style: TableActionColumnsStyleEnum = TableActionColumnsStyleEnum.DropDownText
) => {
  return {
    cell: (row: any) => {
      if (style === TableActionColumnsStyleEnum.Button) {
        return (
            <ServerButtonActions
                actionsPermitted={actionsPermitted}
                formId={formId}
                onActionEvent={onActionEvent}
                row={row}
            />
        );
      } else {
        const isIconRender: boolean =
            style === TableActionColumnsStyleEnum.Dropdown ||
            style === TableActionColumnsStyleEnum.DropdownIcon;
        const isTextRender: boolean =
            style === TableActionColumnsStyleEnum.Dropdown ||
            style === TableActionColumnsStyleEnum.DropDownText;
        return (
            <ServerDropdownActions
                actionsPermitted={actionsPermitted}
                formId={formId}
                onActionEvent={onActionEvent}
                row={row}
                isIconRender={isIconRender}
                isTextRender={isTextRender}
            />
        );
      }
    },
  };
};
export const getClientActionsColumns = (
    buttonColumns: TableColumn<any>[],
    style: TableActionColumnsStyleEnum = TableActionColumnsStyleEnum.Dropdown
): TableColumn<any> => {
  return {
    name: "",
    cell: (row: any, index: number, column: TableColumn<any>) => {
      if (style === TableActionColumnsStyleEnum.Button) {
        return buttonColumns.map((col) => {
          if (col.cell) {
            return col.cell(
                row,
                index,
                column,
                row.id || `client_button_id_${index || 0}`
            );
          }
          return null;
        });
      } else {
        return (
            <ClientDropdownActions buttonColumns={buttonColumns} row={row} />
        );
      }
    },
  };
};
//#endregion
//#region 2. ButtonActions
const ServerButtonActions = ({
                               actionsPermitted,
                               formId,
                               onActionEvent,
                               row,
                             }: {
  actionsPermitted: ActionTypeEnum[];
  formId: number;
  onActionEvent: (o: RequestActionModel) => void;
  row: any;
}) => (
    <div className="d-flex gap-3 align-items-center">
      {actionsPermitted.map((action, index) => (
          <PrivilegesChecker
              formId={formId}
              action={ColumnActions[action]?.privilege}
              key={`privilege_${action.toString()}_${index}`}
          >
            <ButtonBox
                className="btn btn-sm"
                variant={ColumnActions[action]?.variant}
                id={`button_id_b_${action.toString()}_${index}`}
                key={`button_${action.toString()}_${index}`}
                onClick={() => {
                  if (action !== ActionTypeEnum.Delete) scrollToTop();
                  onActionEvent({
                    id: row.ID,
                    request: row,
                    action: action,
                  });
                }}
            >
              {getLabelName(ColumnActions[action]?.btnText ?? "")}
            </ButtonBox>
          </PrivilegesChecker>
      ))}
    </div>
);
//#endregion
//#region 3. DropdownActions
const ServerDropdownActions = ({
                                 actionsPermitted,
                                 formId,
                                 onActionEvent,
                                 row,
                                 isIconRender,
                                 isTextRender,
                               }: {
  actionsPermitted: ActionTypeEnum[];
  formId: number;
  onActionEvent: (o: RequestActionModel) => void;
  row: any;
  isIconRender: boolean;
  isTextRender: boolean;
}) => {
  const dropdownItems = actionsPermitted
      ?.map((action, index) => {
        const IconComponent: IconType | null =
            (isIconRender && ColumnActions[action]?.icon) || null;

        return (
            <PrivilegesChecker
                key={index} // Ensure unique key for each item
                formId={formId}
                action={ColumnActions[action]!.privilege}
            >
              <Dropdown.Item
                  style={{ width: `200px` }}
                  className={`dropdown-item-${ColumnActions[action]!.variant}`}
                  //variant={ColumnActions[action]!.variant}
                  id={`button_id_d_${action.toString()}_${index}`}
                  onClick={() => {
                    if (action !== ActionTypeEnum.Delete) scrollToTop();

                    onActionEvent({
                      id: row.ID,
                      request: row,
                      action,
                    });
                  }}
              >
                {IconComponent && <IconComponent style={{ marginRight: 8 }} />}
                {isTextRender && (
                    <span>{getLabelName(ColumnActions[action]!.btnText)}</span>
                )}
              </Dropdown.Item>
            </PrivilegesChecker>
        );
      })
      .filter(Boolean); // This will remove null values from the array
  return (
      <div className="d-flex gap-3 align-items-center">
        {dropdownItems.length > 0 && (
            <CustomDropdown
                style={{
                  position: "absolute",
                  left: isArabicCurrentLanguage() ? "20px" : "unset",
                  right: isArabicCurrentLanguage() ? "unset" : "20px",
                  top: "11px",
                  // zIndex: 1,
                }}
                dropdownIcon={{ color: "black", size: 16 }}
            >
              {dropdownItems}
            </CustomDropdown>
        )}
      </div>
  );
};

const ClientDropdownActions = ({
                                 buttonColumns,
                                 row,
                               }: {
  buttonColumns: TableColumn<any>[];
  row: any;
}) => {
  const dropdownItems = buttonColumns
      .map((column, index) => {
        if (typeof column.cell === "function") {
          const actionComponent = column.cell(
              row,
              index,
              column,
              row.id || `client_dropdown_id_${index || 0}`
          );
          let btnText = column.name ?? column.id;

          // Type guard to check if actionComponent is a valid React element
          if (React.isValidElement(actionComponent)) {
            // Now TypeScript knows this is a React element and props are accessible
            const props = actionComponent.props as {
              children?: React.ReactNode;
              onClick?: () => void;
              variant?: string;
            };

            btnText = props.children ? props.children : btnText;

            return (
                <Dropdown.Item
                    key={index}
                    id={`client_dropdown_id_${index}`}
                    className={`dropdown-item-${props.variant || 'primary'}`}
                    onClick={() => {
                      if (props.onClick) {
                        scrollToTop();
                        props.onClick();
                      }
                    }}
                >
                  {btnText}
                </Dropdown.Item>
            );
          }
        }
        return null;
      })
      .filter(Boolean);

  return (
      <div className="d-flex gap-3 align-items-center">
        {dropdownItems.length > 0 && (
            <CustomDropdown
                style={{
                  position: "absolute",
                  left: isArabicCurrentLanguage() ? "20px" : "unset",
                  right: isArabicCurrentLanguage() ? "unset" : "20px",
                  top: "11px",
                }}
                dropdownIcon={{ color: "black", size: 16 }}
            >
              {dropdownItems}
            </CustomDropdown>
        )}
      </div>
  );
};

const scrollToTop = () => {
  setTimeout(() => {
    window.document.querySelector("body")?.scrollTo(0, 0);
  }, 100);
};
//#endregion
