import {SystemConfiguration} from "../../configuration";
import {LanguageList} from "../../resources";
import { secureLocalStorageGet} from "..";

export const getLanguageCode = (key: string): string => {
    return LanguageList.filter(
        (p) => p.key.toLowerCase() === key.toLowerCase()
    )[0].code;
};
export const getLanguageKey = (code: string): string => {
    return LanguageList.filter(
        (p) => p.code.toLowerCase() === code.toLowerCase()
    )[0].key;
};
export const getLanguagesTitle = (key: string): any => {
    return LanguageList.filter((p) => p.key === key.toLowerCase())[0];
};
export const isArabicCurrentLanguage = (): boolean => {
    // const cookieValue = cookieGet(SystemConfiguration.cookie_Configurations.culture || "");
    // if (!cookieValue) {
    //     const localStorageValue = localStorageGet(SystemConfiguration.cacheKey_Configurations.language.culture);
    //     return localStorageValue === "ar-AE";
    // }
    // return cookieValue === "ar-AE";
    //return cookieGet(SystemConfiguration.cookie_Configurations.culture || "") === "ar-AE";
    return secureLocalStorageGet(SystemConfiguration.culture_Configurations.currentCulture) === SystemConfiguration.culture_Configurations.arabicCulture;
};

export  const getDefaultLanguage = (): string => {
    const culture = secureLocalStorageGet(
        SystemConfiguration.culture_Configurations.currentCulture ??
        SystemConfiguration.culture_Configurations.defaultUiLanguage
    ) as string;
    return culture ?? SystemConfiguration.culture_Configurations.arabicCulture;
    //return cookieGet(SystemConfiguration.cookie_Configurations.culture??SystemConfiguration.culture_Configurations.defaultUiLanguage) ??"ar-AE"
}
export const getStorageLanguage = (): string => {
    return (
        (secureLocalStorageGet(SystemConfiguration.culture_Configurations.currentCulture) as string) ??
        SystemConfiguration.culture_Configurations.defaultUiLanguage
    );
};