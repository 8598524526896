import React, {Dispatch, FC, SetStateAction} from "react";
import {AntdTableComponent, ButtonBox} from "..";
import {
    RequestActionModel,
    RowStateEnum, ItemModel,
} from "../../models";
import {
    getLabelName,
    isArabicCurrentLanguage,
} from "../../utils";
import {handleGenerateAntdItemModifierColumns} from "./uiHelper/itemModifierDataTableColumns";
import {handleAddModifierRow,getItemModifiers} from"./businessLogic/itemModifiersBl";
export const ItemModifierManager: FC<{
    setState: Dispatch<SetStateAction<ItemModel>>,
    state: ItemModel,
    onActionEvent: (o: RequestActionModel) => void;
}> = ({ setState,state, onActionEvent = () => {} }) => {
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    const itemModifiersAntdColumns = [
        ...handleGenerateAntdItemModifierColumns(
            isArabic,
            setState
        ),
    ];
    //#endregion
    //#region html
    return (
        <>
            <div className="item-unit-list">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                    <ButtonBox
                        iconType="plus"
                        variant="primary"
                        size="sm"
                        className="mb-3"
                        onClick={() => {
                            handleAddModifierRow(
                                setState,
                            );
                        }}
                    >
                        {getLabelName("Add")}
                    </ButtonBox>
                </div>
                <AntdTableComponent
                    data={getItemModifiers(state).filter(
                        (row) => row.rowState !== RowStateEnum.Delete
                    )}
                    columns={itemModifiersAntdColumns}
                />
            </div>
        </>
    );
    //#endregion
};
