import {isArabicCurrentLanguage, secureLocalStorageGet} from "..";
import {SystemConfiguration} from "../../configuration";
import {PrivilegeModel, UserResponseModel} from "../../models";
export const isUserAuthenticated = (): boolean => {
    //return Cookies.get(SystemConfiguration.keys.token || "") ? true : false;
    return !!secureLocalStorageGet(SystemConfiguration.miscellaneous_Configurations.token);
};
export const getUserId = (): number => {
    const result: UserResponseModel = JSON.parse(
        <string>secureLocalStorageGet(SystemConfiguration.cacheKey_Configurations.user.user || "")
    ) as UserResponseModel;
    return result !== null && result !== undefined ? result.UserID : 0;
};
export const getUserStationId = (): number => {
    const result: UserResponseModel = JSON.parse(
        <string>secureLocalStorageGet(SystemConfiguration.cacheKey_Configurations.user.user || "")
    ) as UserResponseModel;
    return result !== null &&
    result !== undefined &&
    result.PosUserSetting !== null &&
    result.PosUserSetting !== undefined
        ? result.PosUserSetting.Station_ID
        : 0;
};
export const getUserName = (): string => {
    const result: UserResponseModel = JSON.parse(
        <string>secureLocalStorageGet(SystemConfiguration.cacheKey_Configurations.user.user || "")
    ) as UserResponseModel;
    return result !== null && result !== undefined
        ? isArabicCurrentLanguage()
            ? result.Name
            : result.Name_En
        : ".";
};
export const getUser = (): UserResponseModel | null => {
    const result: UserResponseModel = JSON.parse(
        <string>secureLocalStorageGet(SystemConfiguration.cacheKey_Configurations.user.user)
    ) as UserResponseModel;
    return result !== null && result !== undefined ? result : null;
};
export const getPagePrivileges = (): PrivilegeModel => {
    const pageName = window.location.pathname.toLowerCase();
    const pagePrivilege: PrivilegeModel = JSON.parse(
        <string>secureLocalStorageGet(SystemConfiguration.cacheKey_Configurations.miscellaneous.privileges || "")
    ).filter((p: any) => p.url === pageName)[0];
    return (
        pagePrivilege || {
            id: 0,
            view: false,
            EnableSave: false,
            EnableUpdate: false,
            EnableDelete: false,
            EnableSearch: false,
            url: "",
        }
    );
};

export const getFormPrivileges = (formId: number): PrivilegeModel => {

    const privileges:PrivilegeModel[] = JSON.parse(
        <string>secureLocalStorageGet(SystemConfiguration.cacheKey_Configurations.miscellaneous.privileges || "")
    );
    const initializes:PrivilegeModel={
        id: 0,
        ColumnsSettingId:0,
        view: false,
        EnableSave: false,
        EnableUpdate: false,
        EnableDelete: false,
        EnableSearch: false,
        url: "",
    };
    if (!privileges || privileges.length === 0) {return initializes}
    const pagePrivilege = privileges.find((p: PrivilegeModel) => p.id === formId);
    return (pagePrivilege || initializes);
};
export const getUserToken = (): string | null => {
    const token = secureLocalStorageGet(SystemConfiguration.miscellaneous_Configurations.token);
    return token !== null ? token.toString() : null;
};
