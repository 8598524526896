//#region state
import React, {Dispatch, SetStateAction} from "react";
import {ItemModel, ItemModifierModel} from "../../../models";
import {ColumnsType} from "antd/es/table";
import {generateGuid, getLabelName} from "../../../utils";
import {TextBox,ButtonBox} from "../..";
import {handleChangeItemModifierValues, handleDeleteItemModifierRow} from "../businessLogic/itemModifiersBl";
export const handleGenerateAntdItemModifierColumns = (
    _isArabic: boolean,
    setState: Dispatch<SetStateAction<ItemModel>>,
): ColumnsType<ItemModifierModel> => {
    return [
        {
            title: "#",
            key: "index_"+generateGuid(),
            render: (value,_row, index) => Number(index || 0) + 1,
            width: "5%",
        },  
        {
            title: getLabelName("Arabic Name"),
            key: "NameArabic_", //+generateGuid(),
            render: (row: ItemModifierModel) => (
                <TextBox
                    labelName={""} //{t("lookup.nameAr")}
                    inputName={"NameArabic"}
                    type="text"
                    inputValue={row.NameArabic}
                    onChange={(e: any) => {
                        handleChangeItemModifierValues(
                            setState,
                            row.rowKey || `${row.ID}`,
                            [{key: "NameArabic", value: e ?  e.target.value : null}]
                        );
                    }}
                />
            ),
            width: "20%",
        },
        {
            title: getLabelName("English Name"),
            key: "NameEnglish_", //+generateGuid(),
            render: (row: ItemModifierModel) => (
                <TextBox
                    labelName={""} //{t("lookup.nameAr")}
                    inputName={"NameEnglish"}
                    type="text"
                    inputValue={row.NameEnglish}
                    onChange={(e: any) => {
                        handleChangeItemModifierValues(
                            setState,
                            row.rowKey || `${row.ID}`,
                            [{key: "NameEnglish", value: e ?  e.target.value : null}]
                        );
                    }}
                />
            ),
            width: "20%",
        },

        {
            title: getLabelName("Price"),
            key: "Price_", //+generateGuid(),
            render: (row: ItemModifierModel) => (
                <TextBox
                    labelName={""}
                    inputName={"Price"}
                    type="number"
                    inputValue={row.Price}
                    onChange={(e: any) => {
                        handleChangeItemModifierValues(
                            setState,
                            row.rowKey || `${row.ID}`,
                            [{key: "Price", value: e ?  e.target.value : null}]
                        );
                    }}
                />
            ),
            width: "10%",
        },
        {
            title: "",
            key: "action_", //+generateGuid(),
            render: (row: ItemModifierModel) => (
                <>
                    <ButtonBox
                        variant="outline-danger"
                        onClick={() => {
                            handleDeleteItemModifierRow(
                                setState,
                                row.rowKey || `${row.ID}`,
                                row.rowKey !== undefined
                            );
                        }}
                    >
                        {getLabelName("Delete")}
                    </ButtonBox>
                </>
            ),
        },
    ];
};
//#endregion