import { configureStore, type StoreEnhancer } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { reduxBatch } from "@manaflair/redux-batch";
import userAuthenticate from "../slice/userAuthincateSlice";
import theme from "../slice/themeSlice";
import languageState from "../slice/languageSlice";
import { cookieGet, getUser, isUserAuthenticated, secureLocalStorageGet } from "../utils";
import Cookies from "js-cookie";
import { SystemConfiguration } from "../configuration";

const reducer = {
    user: userAuthenticate,
    lang: languageState,
    theme: theme,
};

const authenticatedTokenStorageKey: string = SystemConfiguration.miscellaneous_Configurations.token;
const cultureKey: string = SystemConfiguration.culture_Configurations.currentCulture;
const userObject = getUser();
const preloadedState = {
    user: {
        userAccount: userObject ?? null,
        userToken: Cookies.get(authenticatedTokenStorageKey)
            ? cookieGet(authenticatedTokenStorageKey)
            : null,
        isLoading: false,
        isAuthenticated: isUserAuthenticated(),
        Errors: [],
    },
    lang: {
        language: "ar-AE",
    },
    theme: {
        isRtl: secureLocalStorageGet(cultureKey) === SystemConfiguration.culture_Configurations.arabicCulture
    },
};

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Optional: if you have non-serializable data
        }).concat(logger),
    devTools: process.env.NODE_ENV !== "production",
    preloadedState,
    enhancers: (getDefaultEnhancers) =>
        getDefaultEnhancers().concat(reduxBatch as StoreEnhancer),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;