import { FC, useEffect, useState } from "react";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  CustomerResponseModel,
  RequestActionModel,
  RowStateEnum,
  SearchCustomerRequestModel,
  SearchCustomerResponseModel,
  SearchCustomersTypeEnum,
  SupplierCustomerTypeEnum,
  ToastModel,
} from "../../models";
import {
  getLabelName,
  getPageNameByUrl,
  isArabicCurrentLanguage,
} from "../../utils";
import {
  deleteCustomer,
  searchCustomers,
  getCustomerById,
} from "../../serviceBroker/customerApiServiceBroker";
import {
  LoadingBox,
  ToastBox,
  AddCustomer,
  ConfirmModelDialogBox,
  CustomerList,
} from "../";
import { Accordion, Card } from "react-bootstrap";

interface CustomerMainComponentProps {
  type: SupplierCustomerTypeEnum;
  formId: number;
}

export const CustomerMainComponent: FC<CustomerMainComponentProps> = ({
  type,
  formId,
}) => {
  //#region variables
  const initialValues: CustomerResponseModel = {
    Code: 0,
    IDNumber: "",
    City_ID: 0,
    Address: "",
    Mobile: "",
    Phone: "",
    Mail: "",
    Notes: "",
    Cust_Type: Number(type),
    Beg_AC_Bal: 0,
    AreaLand: "",
    User_ID: 0,
    Account_ID: 0,
    ISCustomerAndSupplier: false,
    PaymentTypeID: 0,
    Currency_ID: 0,
    ValueCurrency: 0,
    BalanceOfPoint: 0,
    IsDefault: false,
    MaxDebit: 0,
    Customer_UniqueID: 0,
    Name_En: "",
    TaxNumber: "",
    IsCopyToServer: true,
    Device_ID: 0,
    IsSponsor: false,
    Sponsor_Id: 0,
    IsActive: true,
    ProfitPercentage: 0,
    CustomerType: 0,
    address: {
      CountryCode: "",
      FlatNumber: "",
      Governate: "",
      CreatedBy: 0,
      CreationDate: new Date(),
      ModificationDate: new Date(),
      CustomerId: 0,
      Address_GUID: "",
      ID: 0,
      ModifiedBy: 0,
      Name: "",
      RegionCity: "",
      Remarks: "",
      Row_State: 1,
      Street: "",
      VerifyOnUpdate: false,
      buildingNumber: "",
      rowState: 1,
    },
    IsCreateAccountOnSaveNewOne: false,
    IsSkipSaveAccount: false,
    ID: 0,
    CreatedBy: 0,
    ModifiedBy: 0,
    Name: "",
    ModificationDate: new Date(),
    VerifyOnUpdate: false,
    rowState: 1,
  };
  const deleteActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        await handleAction({
          id: object?.ID || 0,
          request: object,
          action: ActionTypeEnum.DeleteOperationStart,
        });
      },
    },
    {
      text: getLabelName("no"),
      onClick: () => {
        setObject(initialValues);
        setShowDeleteModel(false);
      },
    },
  ];
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [object, setObject] = useState<CustomerResponseModel>(initialValues);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [objects, setObjects] = useState<SearchCustomerResponseModel[]>([]);
  const [pageName, setPageName] = useState<string | null>(null);
  const [searchCustomerRequest] = useState<SearchCustomerRequestModel>({
    pageNumber: null,
    pageSize: null,
    type: type,
    searchType: SearchCustomersTypeEnum.CustomerName,
  });
  const [toastModel, setToastModel] = useState<ToastModel>({
    show: false,
    variant: "success",
  });
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      await getAllObjects(searchCustomerRequest);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    setPageName(getPageNameByUrl());
  }, [isArabicCurrentLanguage()]);
  //#endregion
  //#region function
  const getAllObjects = async (searchModel: SearchCustomerRequestModel) => {
    setLoading(true);
    const result = await searchCustomers(searchModel);
    setObjects(result?.Result || []);
    setTotalRows(result?.TotalRecoredCount || 0);
    setLoading(false);
  };
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
        setLoading(true);
        setObject(initialValues);
        await getAllObjects(searchCustomerRequest);
        setToastModel({ ...toastModel, show: true });
        setIsRefresh(true);
        setLoading(false);
        break;
      case ActionTypeEnum.Clear:
        setLoading(true);
        setObject(initialValues);
        await getAllObjects(searchCustomerRequest);
        setLoading(false);
        break;
      case ActionTypeEnum.Update:
        // window.scrollTo(0, 0);
        const customer = await getCustomerById(request.id!);
        //@ts-ignore
        customer.rowState = Number(RowStateEnum.Update);
        customer.Cust_Type = Number(type);
        if (customer.address != null)
          customer.address.rowState = Number(RowStateEnum.Update);
        setObject(customer);
        break;
      case ActionTypeEnum.Delete:
        setObject(request.request);
        setShowDeleteModel(true);
        break;
      case ActionTypeEnum.DeleteOperationStart:
        setLoading(true);
        setShowDeleteModel(false);
        const result = await deleteCustomer(request.id!);
        setLoading(false);
        await getAllObjects(searchCustomerRequest);
        await handleAction({
          id: 0,
          action: result.Result.Result
            ? ActionTypeEnum.Success
            : ActionTypeEnum.Failed,
        });
        break;
      case ActionTypeEnum.DeleteSuccess:
      case ActionTypeEnum.Success:
        setToastModel({ ...toastModel, show: true });
        setIsRefresh(true);
        break;
      case ActionTypeEnum.Failed:
        setToastModel({
          ...toastModel,
          body: request.request,
          variant: "danger",
          show: true,
        });
        setIsRefresh(true);
        break;
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}

      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{pageName}</Accordion.Header>
          <Accordion.Body>
            <Card>
              <Card.Body>
                <AddCustomer
                  request={object}
                  type={type}
                  onActionEvent={async (o: RequestActionModel) => {
                    await handleAction(o);
                  }}
                  formId={formId}
                />
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body>
            <Card className="card-custom">
              {/* <Card.Header></Card.Header> */}
              <Card.Body>
                {/* delete AccrualSubtractRule  */}
                <ConfirmModelDialogBox
                  isModelVisible={showDeleteModel}
                  onCloseEvent={() => {
                    setShowDeleteModel(false);
                  }}
                  actions={deleteActions}
                ></ConfirmModelDialogBox>

                {/* Estehkak list */}
                {objects && (
                  // && objects.length !== 0
                  <CustomerList
                    type={type}
                    getCustomerList={async (
                      searchModel: SearchCustomerRequestModel
                    ) => {
                      await getAllObjects(searchModel);
                    }}
                    onActionEvent={async (o: RequestActionModel) => {
                      await handleAction(o);
                    }}
                    setIsRefresh={setIsRefresh}
                    isRefresh={isRefresh}
                    request={objects}
                    totalRows={totalRows}
                    formId={formId}
                  />
                )}
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
