import { Dispatch, SetStateAction } from "react";
import {ItemModel, ItemModifierModel, KeyValueStateModel, RowStateEnum} from "../../../models";
import {generateGuid} from "../../../utils";
//#region variables
export const itemModifierInitialValues:Readonly<ItemModifierModel>  = {
    NameArabic: "",
    NameEnglish: "",
    Item_ID: 0,
    Price: 0,
    ID: 0,
    CreatedBy: 0,
    ModifiedBy: 0,
    rowState: Number(RowStateEnum.Add),
    rowKey:  generateGuid(),
};
//#endregion
//#region function
export const handleAddModifierRow = (
    setState: Dispatch<SetStateAction<ItemModel>>
) => {
    setState((prevState) => {
        const updatedModifiers = [...(prevState.Item_Modifiers || []), // Preserve existing modifiers
            {
                ...itemModifierInitialValues, // Clone the initial values
                rowKey: generateGuid(), // Generate a new unique rowKey
            },
        ];
        return {
            ...prevState,
            Item_Modifiers: updatedModifiers,
        };
    });
};
export const handleDeleteItemModifierRow = (
    setState: Dispatch<SetStateAction<ItemModel>>,
    rowKey: string | null,
    isNew: boolean
) => {
    setState((prevState) => {
        const updatedModifiers = prevState.Item_Modifiers?.map((modifier) => {
            if (!isNew && `${modifier.ID}` === rowKey) {
                return { ...modifier, rowState: RowStateEnum.Delete };
            }
            return modifier;
        })?.filter((modifier) => isNew || modifier.rowState !== RowStateEnum.Delete);

        return {
            ...prevState,
            Item_Modifiers: updatedModifiers ?? [],
        };
    });
};
export const getItemModifiers=(request:ItemModel):ItemModifierModel[]=>{
    return request?.Item_Modifiers ?? [];
}
export const generateItemModifier = (): ItemModifierModel[] => {
    return [
        {
            NameArabic: "",
            NameEnglish: "",
            Item_ID: 0,
            Price: 0,
            ID: 0,
            CreatedBy: 0,
            ModifiedBy: 0,
            rowState: Number(RowStateEnum.Add),
            rowKey: "",
        },
    ];
};
export const handleChangeItemModifierValues = (
    setState: Dispatch<SetStateAction<ItemModel>>,
    rowKey: string,
    keyValues: KeyValueStateModel[]
) => {
    setState((prevState) => {
        const updatedModifiers = prevState.Item_Modifiers?.map((modifier) => {
            if ((modifier.rowKey || `${modifier.ID}`) === rowKey) {
                let updatedModifier = { ...modifier };
                keyValues.forEach(({ key, value }) => {
                    updatedModifier = { ...updatedModifier, [key]: value };
                });
                return updatedModifier;
            }
            return modifier;
        });

        return {
            ...prevState,
            Item_Modifiers: updatedModifiers ?? [],
        };
    });
};
//#endregion