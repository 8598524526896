import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from "react";
import {ItemConfigurationsResponseModel, ItemModel, LookupItemModel, LookupTypeEnum} from "../../models";
import {FormikProps} from "formik";
import {CheckBox,SelectBox} from "..";
import { getLabelName} from "../../utils";
import {useSupplierAccounts} from "../../hooks";
export const ItemOptionsManager: FC<{formik:FormikProps<ItemModel>,
    itemConfigs:ItemConfigurationsResponseModel,  setIsRefresh: Dispatch<SetStateAction<boolean>>,
    isRefresh: boolean,
}> = ({formik,itemConfigs,setIsRefresh,isRefresh}) => {
    //#region state
    const { suppliersLoading, storedSuppliers } = useSupplierAccounts();
    const supplierSelectBoxMultiselectRef = useRef<any>();
    //#endregion
    //#region useEffect
    useEffect(() => {
        if (isRefresh) {
            supplierSelectBoxMultiselectRef.current && supplierSelectBoxMultiselectRef.current.clearValue();
            setIsRefresh(false);
        }
    }, [isRefresh]);
    //#endregion
    //#region html
    return (
        <div
            className="row row-cols-1 row-cols-xxl-5 row-cols-xl-5 row-cols-lg-5 row-cols-md-5 row-cols-sm-1 g-sm-2 g-md-4 align-items-center">
            {itemConfigs.ShowItemSuppleer && (
                <SelectBox
                    labelName={getLabelName("Supplier")}
                    source={storedSuppliers}
                    isSingleSelect={true}
                    onStatusChange={(e: any) => {
                        formik.values.Item_Supplier_ID = e?.value??0;
                    }}
                    selectedValues={  formik.values.Item_Supplier_ID !=null  &&   formik.values.Item_Supplier_ID !=0? [formik.values.Item_Supplier_ID.toString()]:null}
                    multiselectRef={supplierSelectBoxMultiselectRef}
                    isDataLoading={suppliersLoading}
                />
            )}
            <CheckBox
                labelName={getLabelName("Has Modifiers")}
                checked={formik.values.HasModifiers}
                inputName="HasModifiers"
                onChange={() => {
                    formik.setFieldValue(
                        "HasModifiers",
                        !formik.values.HasModifiers
                    );
                }}
                id="HasModifiers"
            />

            <CheckBox
                labelName={getLabelName("Is Production Item")}
                checked={formik.values.IsProduction}
                inputName="IsProduction"
                onChange={() => {
                    formik.setFieldValue(
                        "IsProduction",
                        !formik.values.IsProduction
                    );
                }}
                id="IsProduction"
            />
            <CheckBox
                labelName={getLabelName("Gather Item")}
                checked={formik.values.GatherItem}
                inputName="GatherItem"
                onChange={() => {
                    formik.setFieldValue(
                        "GatherItem",
                        !formik.values.GatherItem
                    );
                }}
                id="GatherItem"
            />
            <CheckBox
                labelName={getLabelName("Is Item Vanish Consign")}
                checked={formik.values.IsItemVanishConsign}
                inputName="IsItemVanishConsign"
                onChange={() => {
                    formik.setFieldValue(
                        "IsItemVanishConsign",
                        !formik.values.IsItemVanishConsign
                    );
                }}
                id="IsItemVanishConsign"
            />
            <CheckBox
                labelName={getLabelName("Is Unis Separate In Balance")}
                checked={formik.values.IsUnisSeparateInBalance}
                inputName="IsUnisSeparateInBalance"
                onChange={() => {
                    formik.setFieldValue(
                        "IsUnisSeparateInBalance",
                        !formik.values.IsUnisSeparateInBalance
                    );
                }}
                id="IsUnisSeparateInBalance"
            />
            <CheckBox
                labelName={getLabelName("Item Is Different Factor")}
                checked={formik.values.IsDifferentFactor}
                inputName="IsDifferentFactor"
                onChange={() => {
                    formik.setFieldValue(
                        "IsDifferentFactor",
                        !formik.values.IsDifferentFactor
                    );
                }}
                id="IsDifferentFactor"
            />
            {itemConfigs.ShowFilterOilOption && (
                <CheckBox
                    labelName={getLabelName("Is Oil Service Item")}
                    checked={formik.values.Is_Oil_Service}
                    inputName="Is_Oil_Service"
                    onChange={() => {
                        formik.setFieldValue(
                            "Is_Oil_Service",
                            !formik.values.Is_Oil_Service
                        );
                    }}
                    id="Is_Oil_Service"
                />
            )}
            <CheckBox
                labelName={getLabelName("Is Filter Service Item")}
                checked={formik.values.Is_Filter_Service}
                inputName="Is_Filter_Service"
                onChange={() => {
                    formik.setFieldValue(
                        "Is_Filter_Service",
                        !formik.values.Is_Filter_Service
                    );
                }}
                id="Is_Filter_Service"
            />
            {itemConfigs.CanAddAddationlCost && (
                <CheckBox
                    labelName={getLabelName("Can Add Aadational Cost")}
                    checked={formik.values.CanAddAddationalCost}
                    inputName="CanAddAddationalCost"
                    onChange={() => {
                        formik.setFieldValue(
                            "CanAddAddationalCost",
                            !formik.values.CanAddAddationalCost
                        );
                    }}
                    id="CanAddAddationalCost"
                />
            )}
        </div>
    )
    //#endregion
}