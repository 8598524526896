import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {
    DailyTransactionTypeEnum,
    LookupItemFilterModel,
    LookupTypeEnum, UserTransactionsSettingResponseModel,
    ValidationErrorModel
} from "../../../models";
import {getLookupByType} from "../../../serviceBroker/lookupApiServiceBroker";
import {getUserTransactionsSettings} from "../../../serviceBroker/userApiServiceBroker";
import {SystemConfiguration} from "../../../configuration";

export const useStoreTransferLookUp=(setLoading:Dispatch<SetStateAction<boolean>>,setValidationErrors:Dispatch<SetStateAction<ValidationErrorModel[]>>)=>{
    //#region state
    // const {loadingData, hideLoader, showLoader} = useLoading({
    //     [LoadingObjectsEnum.FORM]: false,
    // });
    const [lookupFilters, setLookupFilters] = useState<LookupItemFilterModel[]>([]);
    const [settings,setSettings]=useState<UserTransactionsSettingResponseModel[]>([])
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            //showLoader(LoadingObjectsEnum.FORM);
            setLoading(true );
            try {
                await fillDataPromiseAll();
            } catch (error) {
                setValidationErrors([{MessageAr:`Failed to load lookup data: ${error}`,MessageEn:`Failed to load lookup data: ${error}`}]);
            }
            //  setTimeout(() => {
            //     setLoading(false);
            // }, 5000);
            setLoading(false); // Hide loader after the timeout
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region function
    const fillDataPromiseAll = async () => {

            const [storeList, employeeList, categoryList , calcTypeList,settingsList] = await Promise.all([
                getLookupByType(LookupTypeEnum.Stores, true, false),
                getLookupByType(LookupTypeEnum.Employee, true, false),
                getLookupByType(LookupTypeEnum.CategoryType, true, false),
                getLookupByType(LookupTypeEnum.CalcType, true, false),
                //getLookupByType(LookupTypeEnum.ItemsStoreTransfer, true, false),
                getUserTransactionsSettings([DailyTransactionTypeEnum.MoveToStore, DailyTransactionTypeEnum.ReceiveFromStore], true, SystemConfiguration.cacheKey_Configurations.setting.storeTransferSettingList)
            ]);
            const lookups: LookupItemFilterModel[] = [
                { key: LookupTypeEnum.Stores, value: storeList },
                { key: LookupTypeEnum.Employee, value: employeeList },
                { key: LookupTypeEnum.CategoryType, value: categoryList },
                { key: LookupTypeEnum.CalcType, value: calcTypeList },
                // { key: LookupTypeEnum.Items, value: itemList },
                // { key: LookupTypeEnum.ItemsPerCategory, value: itemList },
                { key: LookupTypeEnum.Items, value: [] },
                { key: LookupTypeEnum.ItemsPerCategory, value: [] },
            ];
            setLookupFilters(lookups);
            setSettings(settingsList);
            //return lookups;
            // const lookups = [
            //     { setter: setFromStoreList, type: LookupTypeEnum.Stores,isSpecific: false },
            //     { setter: setToStoreList, type: LookupTypeEnum.Stores,isSpecific: false  },
            //     { setter: setEmployeeList, type: LookupTypeEnum.Employee,isSpecific: false  },
            //     { setter: setCategoryList, type: LookupTypeEnum.CategoryType ,isSpecific: true },
            //     { setter: setItemList, type: LookupTypeEnum.Items,isSpecific: true  },
            // ];
            // const promises = lookups
            //     .filter(lookup => lookup.setter !== null && lookup.setter !== undefined)
            //     .map(lookup => getLookupByType(lookup.type, true, false));
            // const results = await Promise.all(promises);
            // results.forEach((result, index) => {
            //     const lookup = lookups[index];
            //     if (lookup.setter) {
            //         lookup.setter(result);
            //     }
            // });
    }
    //#endregion
    //return {lookupList: lookupFilters,isLoading:loadingData.FORM};
    return {lookupList: lookupFilters,setLookupList:setLookupFilters, settings:settings};
};