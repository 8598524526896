import React, {FC, useEffect, useState} from "react";
import {DashboardSummaryResponseModel} from "../../models";
import circleSVG from "../../assets/images/dashboard/circle.svg";

export const DashBoardStatisticsSummary: FC<{ request: DashboardSummaryResponseModel | null }> = ({request}) => {
    //#region state
    const [data, setData] = useState<[string, string | null][]>([]);
    //#endregion
    //#region functions
    const getCardClassBasedOnKey = () => {
        const classesList: string[] = [
            "bg-gradient-primary",
            "bg-gradient-secondary",
            "bg-gradient-success",
            "bg-gradient-danger",
            "bg-gradient-warning",
            "bg-gradient-info",
            "bg-gradient-dark",
            //"bg-gradient-light",
            //"bg-gradient-body",
            //"bg-gradient-white",
            //"bg-gradient-transparent",
        ];
        const randomIndex = Math.floor(Math.random() * classesList.length);
        return classesList[randomIndex];
    };
    const shouldIncludeKey = (keysToInclude: string[], key: string) => {
        return keysToInclude.includes(key.toLowerCase());
    };
    //#endregion
    //#region useEffect
    useEffect(() => {
        const keysToInclude = [
            "netsales", "countsales", "profitsales", "netpurchases",
            "netreturnsales", "countreturnsales", "netreturnpurchases", "countreturnpurchases",
            "countpurchases"
        ];
        const fillData = async () => {
            setData(request !== null ? Object.entries(request).filter(([key, _]) => shouldIncludeKey(keysToInclude, key)).map(([key, value]) => [key.toLowerCase(), value ?? 0]) : []);
        };
        fillData().then(() => {
        });
    }, [request])
    //#endregion
    //#region html
    return data !== null && data.length !== 0 ? (
        <div className="row">
            {
                data.map(([key, value]) => (
                    <div className="col-md-3 stretch-card grid-margin" key={key}>
                        <div className={`card ${getCardClassBasedOnKey()}  card-img-holder text-white`}>
                            <div className="card-body">
                                <img
                                    src={circleSVG}
                                    className="card-img-absolute"
                                    alt="circle-image"
                                />
                                <h4 className="font-weight-normal mb-3">
                                    {key} <i className="mdi mdi-chart-line mdi-24px float-right"></i>
                                </h4>
                                <h2 className="mb-5">{value}</h2>
                                {/*<h6 className="card-text">Increased by 60%</h6>*/}
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    ) : null;
    //#endregion
};