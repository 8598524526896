import * as Yup from "yup";
import {getLabelName} from "../common/commonManager";
import {FromValidationResponseModel} from "../../models";

//#region new implementation
/**
 * Validates a required field using Yup but returns a custom ValidationResult.
 * @param propertyName - The name of the property for constructing the error message.
 * @param errorMessage - Custom error message to display.
 * @returns A validation function that returns a ValidationResult.
 */
export const validateRequired = (
    propertyName?: string | null,
    errorMessage?: string | null
): ((value: any) => FromValidationResponseModel) => {
    const schema = Yup.string().required(
        errorMessage || `${propertyName ? getLabelName(propertyName) + " " : ""}${getLabelName("required")}`
    );
    return (value: any): FromValidationResponseModel => {
        try {
            schema.validateSync(value); // Let Yup handle the validation
            return { valid: true }; // Validation succeeded
        } catch (err: any) {
            return { valid: false, message: err.message }; // Validation failed
        }
    };
};

export const validateNoSpaces = (
    propertyName?: string | null,
    errorMessage?: string | null
): ((value: any) => FromValidationResponseModel) => {
    const defaultMessage = `${propertyName ? getLabelName(propertyName) + " " : ""}${getLabelName("cannot be spaces only")}`;
    return (value: any): FromValidationResponseModel => {
        const valid = value === undefined || value === null || value.trim().length > 0;
        return {
            valid,
            message: valid ? undefined : errorMessage || defaultMessage,
        };
    };
};
//#endregion



// //#region old implementation
// export const validateNoSpaces = (errorMessage: string): Yup.TestOptions<string | null | undefined> => ({
//     name: "no-spaces",
//     test: (value) => value === undefined || value === null || value.trim().length > 0,
//     message: errorMessage,
// });
// const validateRequired1 = (propertyName: string,errorMessage?: string|null): { test: (value: any) => boolean; message: string } => ({
//     test: (value) => !!value, // Ensures value is not null, undefined, or empty
//     message: errorMessage||getLabelName("required"), // Custom error message
// });
// const validateNoSpaces1 = (errorMessage?: string|null): { name: string; test: (value:any) => boolean; message: string } => ({
//     name: "no-spaces",
//     test: (value) => value === undefined || value === null || value.trim().length > 0,
//     message: errorMessage || getLabelName("cannot be empty or spaces only")
// });
// export const validateRequired2 = (propertyName?: string|null,errorMessage?: string|null): Yup.StringSchema<string | undefined, object> => {
//     errorMessage=errorMessage||`${propertyName ? getLabelName(propertyName) + " " : ""}${getLabelName("required")}`;
//     return Yup.string().required(errorMessage);
// };
// export const validateNoSpaces2 = (propertyName?: string|null, errorMessage?: string|null): Yup.StringSchema => {
//     return Yup.string()
//         .test(
//             `${propertyName}-no-spaces`,
//             errorMessage || `${propertyName ? getLabelName(propertyName) + " " : ""}${getLabelName("cannot be empty or spaces only")}`,
//             (value) => {
//                 return !!value && value.trim().length > 0;
//             }
//         )
// };
// export const validateRequiredxxx = (propertyName?: string|null,errorMessage?: string|null): Yup.StringSchema<string | undefined, object> => {
//     errorMessage=errorMessage||`${propertyName ? getLabelName(propertyName) + " " : ""}${getLabelName("required")}`;
//     return Yup.string().required(errorMessage);
// };
// //#endregion



