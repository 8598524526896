import {ColumnsType} from "antd/es/table";
import {generateGuid, getLabelName} from "../../../utils";
import {GatherItemModel, ItemModel} from "../../../models";
import {ButtonBox, TextBox} from "../../";
import React, {Dispatch, SetStateAction} from "react";
import {
    handleChangeItemGathersValues,
    handleDeleteItemGathersRow,
} from "../businessLogic/itemStoresBl";

export const handleGenerateGatheredItemsColumns = (
    setState: Dispatch<SetStateAction<ItemModel>>,isArabic:boolean
): ColumnsType<GatherItemModel>=> {
 return [
      {
          title: "#",
          key: "index_" + generateGuid(),
          render(value, record, index) {
              return index + 1;
          },
          width: "5%",
      },
      isArabic? {
            title: getLabelName("NameAr"),
            key: "itemName_", //+generateGuid(),
            render: (row: GatherItemModel) => (
                <TextBox
                    labelName=""
                    isReadOnly={true}
                    inputName={"ItemNameAr"}
                    type="text"
                    inputValue={row.ItemNameAr}
                />
            ),
            width: "20%",
        }:
      {
          title: getLabelName("NameEm"),
          key: "itemName_", //+generateGuid(),
          render: (row: GatherItemModel) => (
              <TextBox
                  labelName=""
                  isReadOnly={true}
                  inputName={"ItemNameEn"}
                  type="text"
                  inputValue={row.ItemNameEn}
              />
          ),
          width: "20%",
      },
        {
            title: getLabelName("quantity"),
            key: "quantity_", //+generateGuid(),
            render: (row: GatherItemModel) => (
                <TextBox
                    labelName=""
                    inputName="Quantity"
                    inputValue={row.Quantity}
                    onChange={(e: any) => {
                        handleChangeItemGathersValues(
                            setState,
                            row.rowKey || `${row.ID}`,
                            [{key: "Quantity", value: e.target.value}],true
                        );
                    }}
                />
            ),
            width: "10%",
        },
        {
            title: getLabelName("price"),
            key: "price_", //+generateGuid(),
            render: (row: GatherItemModel) => (
                <TextBox
                    labelName=""
                    isReadOnly={true}
                    inputName="PriceSale"
                    type="text"
                    inputValue={row.PriceSale}
                    />
            ),
            width: "10%",
        },
        {
            title: getLabelName("cost"),
            key: "cost_", //+generateGuid(),
            render: (row: GatherItemModel) => (
                <TextBox
                    labelName=""
                    isReadOnly={true}
                    inputName="PriceCost"
                    type="text"
                    inputValue={row.PriceCost}
                />
            ),
            width: "10%",
        },
        {
            title: getLabelName("total"),
            key: "total", //+generateGuid(),
            render: (row: GatherItemModel) => (
                <TextBox
                    labelName=""
                    isReadOnly={true}
                    inputName="Total"
                    type="text"
                    inputValue={row.PriceSale * row.Quantity}
                />
            ),
            width: "10%",
        },
        {
            title: getLabelName("delete"),
            key: "action_", //+generateGuid(),
            render: (row: GatherItemModel) => (
                <ButtonBox  variant="outline-danger"
                            onClick={() => {
                                handleDeleteItemGathersRow(
                                    setState,
                                    row.rowKey || `${row.ID}`,
                                    row.ID !=null && row.ID!=0
                                );
                            }}
                            iconType="delete">
                    {getLabelName("delete")}
                </ButtonBox>
            ),
            width: "10%",
        },
    ];
};
