import React from "react";
import {
  DataTablePropsModel,
  GridColumnsHookParamsModel,
  TableActionColumnsStyleEnum,
  RequestActionModel, PageEnum, FlattenMenuModel,
} from "../../../../models";
import { ServerGridComponent } from "./gridTableExtensions/serverGridComponent";
import { ClientGridComponent } from "./gridTableExtensions/clientGridComponent";
import {getLabelName, getMenuItemByFormId} from "../../../../utils";

//#region interface
interface GridTableProps<T> extends Omit<DataTablePropsModel<T>, "columns"> {
  columnsProps?: GridColumnsHookParamsModel<T> | null;
  columns?: any[] | null;
  style?: TableActionColumnsStyleEnum | null;
  formId?: number | null;
}
//#endregion
//#region function
const getGridStyle = (
    formId: number,
    style: TableActionColumnsStyleEnum
): TableActionColumnsStyleEnum => {
  if (formId !== PageEnum.none) {
    const menuItem: FlattenMenuModel | null = getMenuItemByFormId(formId);
    return menuItem !== null && menuItem.ActionColumnsStyle != null
        ? menuItem.ActionColumnsStyle
        : style;
  }
  return style;
};
//#endregion
//#region html
export const GridTable = <T extends { onActionEvent: (o: RequestActionModel) => void }>({
                                                                                          columnsProps,
                                                                                          data,
                                                                                          columns,
                                                                                          style,
                                                                                          formId,
                                                                                          ...tableProps
                                                                                        }: GridTableProps<T>): React.ReactElement => {
  if (data.length === 0) {
    return <p className="text-center">{getLabelName("No previous data to show")}</p>;
  } else if (!columns && columnsProps) {
    return (
        <ServerGridComponent
            columnsProps={columnsProps}
            data={data}
            tableProps={tableProps}
            style={getGridStyle(formId ?? 0, style ?? TableActionColumnsStyleEnum.DropDownText)}
            formId={formId}
        />
    );
  } else if (columns?.length !== 0) {
    return (
        <ClientGridComponent
            columns={columns || []}
            data={data}
            tableProps={tableProps}
            style={getGridStyle(formId ?? 0, style ?? TableActionColumnsStyleEnum.DropDownText)}
            formId={formId}
            onActionEvent={columnsProps?.onActionEvent}
        />
    );
  }
  return <p className="text-center">Not able to generate columns</p>;
};
//#endregion