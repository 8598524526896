//https://codesandbox.io/s/ccyuu?file=/src/movies.js  -->basic
//https://codesandbox.io/s/xmdju?file=/src/index.js:1950-1966  -->server side pagination
//https://codesandbox.io/s/fz295?file=/src/DataTable.js:1750-1763 -->filter
//https://react-data-table-component.netlify.app/
import React, { FC, useEffect, useState } from "react";
import DataTable, { Direction,ConditionalStyles } from "react-data-table-component";
import { generateGuid, isArabicCurrentLanguage } from "../../../utils";
import { DataTablePropsModel } from "../../../models";
import { DataTablePager } from "../../pagination/dataTablePager";

//type PaginationType = "none" | "client" | "server";

export interface SelectedRow<T> {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: T[];
}

export const TableComponent: FC<DataTablePropsModel<any>> = ({
  title,
  columns = [],
  data = [],
  totalRows,
  currentPage = 1,
  pageSize,
  paginationType = "none",
  subHeaderComponent,
  onPageSizeChange = () => {},
  onCurrentPageChange = () => {},
  onRowClicked = () => {},
  onRowDoubleClicked = () => {},
  onRowMouseEnter = () => {},
  onRowMouseLeave = () => {},
  conditionalRowStyles,
  selectableRows = false,
  onSelectedRowChange,
}) => {
  //#region state
  const [activeRowId, setActiveRowId] = useState<any>(null);
  const [internalPageSize, setInternalPageSize] = useState(pageSize); // Internal state for page size
  const [internalCurrentPage, setInternalCurrentPage] = useState(currentPage); // Internal state for current page
  const [displayedData, setDisplayedData] = useState(data); // Data to be displayed in client pagination
  const isPaginationRequired = paginationType !== "none"; //&& totalRows > pageSize;
  // const [hoveredRowId, setHoveredRowId] = useState<any>(null);
  // const [clickedRowId, setClickedRowId] = useState<any>(null);
  //#endregion
  //#region effects
  // Effect to update displayed data for client-side pagination
  useEffect(() => {
    const addUniqueIdToData = (data: any) => {
      return data.map((row: any) => ({
        ...row,
        uniqueId: row.uniqueId || generateGuid(), // Preserve existing uniqueId or generate a new one
      }));
    };

    if (paginationType === "client") {
      const startIdx = (internalCurrentPage - 1) * internalPageSize;
      const endIdx = startIdx + internalPageSize;
      const newDisplayedData = addUniqueIdToData(data.slice(startIdx, endIdx));
      setDisplayedData([...newDisplayedData]); // Creating a new reference
    } else {
      const newDisplayedData = addUniqueIdToData(data);
      setDisplayedData(newDisplayedData);
    }
  }, [internalCurrentPage, internalPageSize, paginationType, data]);

  //#endregion
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  let isPaginationServer: boolean = paginationType === "server";
  const paginationComponentOptions = {
    // selectAllRowsItem: true,
    // selectAllRowsItemText: t("selectAllRowsFromTable"),
    // rowsPerPageText: t("rowsPerPageText"),
    //rangeSeparatorText: t("rangeSeparatorText"),
    rowsPerPageText: "",
    rangeSeparatorText: "/", //`Page ${currentPage} of ${10}`, // Show Page X of Y
  };
  const rowsPerPage = [5, 10, 25, 50, 100, 200, 300, 500];
  //const totalPages = Math.ceil(totalRows / pageSize);
  //#endregion
  //#region function
  const hoverStyle: ConditionalStyles<any>[] = [
    {
      when: (row: any) => {
        const rowId = row.uniqueId; // row.id || row.ID || row.rowKey;
        return rowId === activeRowId;
      },
      style: {
        backgroundColor: "#e0f7fa", // Change this to your desired hover/click color
      },
    },
    // {
    //     when: (row: any) => row.id === hoveredRowId,
    //     style: {
    //         backgroundColor: '#f1f1f1', // Change this to your desired hover color
    //     },
    // },
    // {
    //     when: (row: any) => row.id === clickedRowId,
    //     style: {
    //         backgroundColor: '#d1d1d1', // Change this to your desired click color
    //     },
    // },
  ];
  const handleRowMouseEnter = (row: any, event: React.MouseEvent) => {
    //setHoveredRowId(row.id);
    const rowId = row.uniqueId; // row.id || row.ID || row.rowKey;
    setActiveRowId(rowId);
    if (onRowMouseEnter) {
      onRowMouseEnter(row, event);
    }
  };
  const handleRowMouseLeave = (row: any, event: React.MouseEvent) => {
    //setHoveredRowId(null);
    setActiveRowId(null);
    if (onRowMouseLeave) {
      onRowMouseLeave(row, event);
    }
  };
  const handleRowClick = (row: any, event: React.MouseEvent) => {
    const rowId = row.uniqueId; // row.id || row.ID || row.rowKey;
    setActiveRowId(rowId);
    if (onRowClicked) {
      onRowClicked(row, event);
    }
  };
  const handleRowDoubleClick = (row: any, event: React.MouseEvent) => {
    const rowId = row.uniqueId; // row.id || row.ID || row.rowKey;
    setActiveRowId(rowId);
    if (onRowDoubleClicked) {
      onRowDoubleClicked(row, event);
    }
  };
  const handleChangePage = (newPage: number) => {
    if (internalCurrentPage !== newPage) {
      setInternalCurrentPage(newPage);
      if (paginationType === "server") {
        onCurrentPageChange(newPage); // Delegate to external handler for server-side
      }
    }
  };
  const handlePageSizeChange = (newPageSize: number) => {
    if (internalPageSize !== newPageSize) {
      setInternalPageSize(newPageSize); // Handle client-side page size change
      setInternalCurrentPage(1);
      if (paginationType === "server") {
        onPageSizeChange(newPageSize); // Delegate to external handler for server-side
      }
    }
  };
  // const CustomPagination = (): any => {
  //   return (
  //     <>
  //       <Pager
  //         currentPage={currentPage}
  //         pageSize={pageSize}
  //         rowsCount={totalRows}
  //         onCurrentPageChange={onCurrentPageChange}
  //         onPageSizeChange={onPageSizeChange}
  //       />
  //     </>
  //   );
  // };
  //#endregion
  //#region html
  return (
    <>
      <DataTable
        key={internalCurrentPage + "-" + internalPageSize} // Force re-render by changing the key
        title={title}
        columns={columns}
        data={displayedData} //data
        paginationPerPage={internalPageSize} //pageSize
        progressComponent={<>loading........</>}
        //progressPending={loading} // to be checked
        pagination={isPaginationRequired}
        paginationServer={isPaginationServer}
        paginationTotalRows={totalRows}
        paginationDefaultPage={internalCurrentPage} //currentPage
        direction={isArabic ? Direction.RTL : Direction.LTR}
        // onChangePage={(e) => {onCurrentPageChange(e);}}
        // //@ts-ignore
        // onChangeRowsPerPage={(e) => {onPageSizeChange(e);}}
        onChangePage={handleChangePage} // Call the appropriate handler based on pagination type
        onChangeRowsPerPage={handlePageSizeChange} // Handle page size changes
        onRowClicked={handleRowClick}
        onRowDoubleClicked={handleRowDoubleClick}
        onRowMouseEnter={handleRowMouseEnter}
        onRowMouseLeave={handleRowMouseLeave}
        selectableRowsHighlight
        selectableRowsSingle
        selectableRows={selectableRows}
        paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={rowsPerPage}
        onSelectedRowsChange={onSelectedRowChange}
        striped
        responsive
        fixedHeader
        fixedHeaderScrollHeight="60vh"
        //subHeader
        subHeaderComponent={subHeaderComponent}
        conditionalRowStyles={
          [...(conditionalRowStyles || []), ...hoverStyle] || []
        }
        //conditionalRowStyles={conditionalRowStyles || []}
        noDataComponent={<div></div>}
        //customStyles={{backgroundColor: 'yellow'}}
        //paginationComponent={isCustomPagination ? CustomPagination : undefined}
        paginationComponent={
          isPaginationRequired
            ? () => (
                <DataTablePager
                  currentPage={internalCurrentPage}
                  rowsPerPage={internalPageSize}
                  totalRows={totalRows}
                  rowsPerPageOptions={rowsPerPage}
                  onPageChange={(page) => {
                    handleChangePage(page);
                  }}
                  onRowsPerPageChange={handlePageSizeChange}
                />
              )
            : undefined
        }
      />
    </>
  );
  //#endregion
};
