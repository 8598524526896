import React, {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {LangSwitcherReactI18, LoginForm} from "../../components";
import logo from "../../assets/images/logo.svg";
import {SystemConfiguration} from "../../configuration";
import {secureLocalStorageGet, secureLocalStorageSet} from "../../utils";
import {useTranslation} from "react-i18next";
// import {StoreTransferMainComponent} from "../../components/transactions/storeTransfer/storeTransferMainComponent";

export const HomePage: FC = () => {
    //#region state
    const navigate = useNavigate();
    // const [searchParams] = useSearchParams();
    const {t} = useTranslation();
    //#endregion
    //#region function
    function refreshPage() {
        window.location.reload();
        navigate("/");
    }

    //#endregion
    //#region use-effect
    useEffect(() => {
        if (
            secureLocalStorageGet(SystemConfiguration.miscellaneous_Configurations.homePageReloaded) == "0"
        ) {
            secureLocalStorageSet(SystemConfiguration.miscellaneous_Configurations.homePageReloaded, "1");
            window.location.reload();
        }
    }, []);
    //#endregion
    //#region html
    return (
        <div className="auth mx-auto row g-0">
            {/*<StoreTransferMainComponent formId={111}/>*/}
            <LangSwitcherReactI18/>
            <div className="col-md-5">
                <div className="auth-form-light text-left p-4">
                    <div className="brand-logo">
                        <img alt="no-image" src={logo}/>
                    </div>
                    {/*<h4>{t("Let's get started")}</h4>*/}
                    <h6 className="font-weight-light">{t("Sign in to continue")}</h6>
                    <LoginForm/>
                </div>
            </div>
            <div className="col-md-7 alyusr-description">
                <div>
                    <span>{t("Alyusr System")}</span>{t("Alyusr.System.login.description")}
                </div>
            </div>
        </div>
    );
    //#endregion
};
