import {FlattenMenuModel, CachingData, MenuModel} from "../../models";
import {
    secureLocalStorageGet,
    isArabicCurrentLanguage,
    registerLurCaching,
    fetchLurCachingByKey,
} from "..";
import {SystemConfiguration} from "../../configuration";
//#region public
export const getPageNameByPageId = (id: number): string => {
    const menuItems = JSON.parse(
        <string>secureLocalStorageGet(SystemConfiguration.cacheKey_Configurations.menu.menu || "")
    ) as MenuModel[];
    const menuItem: MenuModel | null = searchDeeplyInMenuItemById(id, menuItems);
    return menuItem !== null && menuItem !== undefined ? isArabicCurrentLanguage() ? menuItem.ArabicName : menuItem.Name : "";
}
export const getPageNameByUrl = (url?: string | null): string => {
    const pageUrl: string = url === null || url === undefined || url === "" ? window.location.pathname.toLowerCase() : url;
    const menuItems = JSON.parse(
        <string>secureLocalStorageGet(SystemConfiguration.cacheKey_Configurations.menu.menu || "")
    ) as MenuModel[];
    const menuItem: MenuModel | null = searchDeeplyInMenuItemByUrl(pageUrl, menuItems);
    return menuItem !== null && menuItem !== undefined ? isArabicCurrentLanguage() ? menuItem.ArabicName : menuItem.Name : "";
}
export const searchDeeplyInMenuItemById = (id: number, menuItems: MenuModel[]): MenuModel | null => {
    for (const obj of menuItems) {
        if (obj.ID === id) {
            return obj;
        }
        if (obj.ChildBusinessObject !== null) {
            const result = searchDeeplyInMenuItemById(id, obj.ChildBusinessObject);
            if (result) {
                return result;
            }
        }
    }
    return null;
};
export const searchDeeplyInMenuItemByUrl = (url: string, menuItems: MenuModel[]): MenuModel | null => {
    for (const obj of menuItems) {
        if (obj.WebUrl !== null && obj.WebUrl !== undefined && obj.WebUrl !== "" && obj.WebUrl.toLowerCase() === url.toLowerCase()) {
            return obj;
        }
        if (obj.ChildBusinessObject !== null) {
            const result = searchDeeplyInMenuItemByUrl(url, obj.ChildBusinessObject);
            if (result) {
                return result;
            }
        }
    }
    return null;
};
export const getMenuItemByFormId=(formId:number):FlattenMenuModel|null=>{
    // Check if result is already memoized
    if (memoizedMenuData.results[formId] !== undefined) {
        return memoizedMenuData.results[formId];
    }
    const menuData = getMenuFlattenData();
    if(menuData!==null && menuData.length!==0) {
        const matchedRecord: FlattenMenuModel | null = menuData.find(item => item.ID === formId) || null;
        memoizedMenuData.results[formId] = matchedRecord; // Memoize the result
        return matchedRecord||null;
    }
    return null;
}
export const getFlattenMenuData = (menuData: MenuModel[]): FlattenMenuModel[] => {
    const result: FlattenMenuModel[] = [];
    const flatten = (items: MenuModel[]) => {
        items.forEach(item => {
            const { ArabicName, Name, ActionColumnsStyle, ID, WebUrl } = item;
            result.push({ ArabicName, Name, ActionColumnsStyle, ID, WebUrl });

            if (item.ChildBusinessObject && item.ChildBusinessObject.length > 0) {
                flatten(item.ChildBusinessObject);
            }
        });
    };

    flatten(menuData);
    return result;
};
//#endregion
//#region private
// Memoization object to store the parsed menu data and results
const memoizedMenuData: CachingData<FlattenMenuModel> = {
    data: null,
    results: {}
};
const getMenuFlattenData = (): FlattenMenuModel[] => {
    const cacheRegisterKey=SystemConfiguration.cacheKey_Configurations.lruCache.menuFlatData;
    //// Register the memoized data with the registry
    //registerMemoizedData('menu', memoizedMenuData);
    const cachedData = fetchLurCachingByKey<FlattenMenuModel>(cacheRegisterKey);
    if (cachedData && cachedData.data !== null) {
        return cachedData.data;
    }
    try {
        const menuData = secureLocalStorageGet(SystemConfiguration.cacheKey_Configurations.menu.flattenMenuItem || "");
        // @ts-ignore
        memoizedMenuData.data = JSON.parse(menuData) as MenuFlatModel[];
    } catch (error) {
        memoizedMenuData.data = [];
    }
    registerLurCaching(cacheRegisterKey, memoizedMenuData);
    return memoizedMenuData.data!;
};
//#endregion


