import { FC, useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import {
  AddStore,
  ConfirmModelDialogBox,
  ErrorValidationBox,
  LoadingBox, StoresList,
  ToastBox,
} from "../../components";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  PageEnum,
  RequestActionModel,
  RowStateEnum,
  StoreResponseModel,
  ToastModel,
  ValidationErrorModel,
} from "../../models";
import {
  deleteStore
} from "../../serviceBroker/storeApiServiceBroker";

import {
  getActionMessage,
  getLabelName,
  getPageNameByPageId,
  isArabicCurrentLanguage,
} from "../../utils";

export const StoresPage: FC<{ formId: number }> = ({
  formId = PageEnum.Stores,
}) => {
  //#region variables
  const initialValues: StoreResponseModel = {
    Address: "",
    Branch_ID: 0,
    Code: "",
    Phone: "",
    CreationDate: new Date(),
    ModificationDate: new Date(),
    PrinterName: "",
    VerifyOnUpdate: false,
    Name_En: "",
    Name: "",
    ID: 0,
    CreatedBy: 0,
    ModifiedBy: 0,
    Errors: [],
    IsDefault: false,
    rowState: Number(RowStateEnum.Add),
  };
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [validationErrors,setValidationErrors] = useState<ValidationErrorModel[]>([]);
  const [store, setStore] = useState<StoreResponseModel>(initialValues);
  const [toastModel, setToastModel] = useState<ToastModel>({show: false,});
  const [pageName, setPageName] = useState<string | null>(null);
  const [showDeleteStoreModel, setShowDeleteStoreModel] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  //#endregion
  //#region variables
  const deleteStoreActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        await handleStoreAction({
          id: store.ID,
          action: ActionTypeEnum.DeleteOperationStart,
        });
      },
    },
    {
      text: getLabelName("no"),
      onClick: () => {
        setStore(initialValues);
        setShowDeleteStoreModel(false);
      },
    },
  ];
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (formId !== 0 && formId !== null && formId !== undefined) {
      setPageName(getPageNameByPageId(formId));
    }
  }, [isArabicCurrentLanguage()]);
  //#endregion
  //#region function
  const handleStoreAction = async (request: RequestActionModel) => {
    setValidationErrors([]);
    switch (request.action) {
      case ActionTypeEnum.CloseModelPopup:
        setValidationErrors([]);
        setShowDeleteStoreModel(false);
        setIsRefresh(false);
        break;
      case ActionTypeEnum.AddSuccess:
      case ActionTypeEnum.Success:
        setLoading(true);
        setStore(initialValues);
        setIsRefresh(true);
        setToastModel({
          ...toastModel,
          variant: "success",
          body:getActionMessage(request.id as ActionTypeEnum),
          show: true,
        });
        setLoading(false);
        break;
      case ActionTypeEnum.Clear:
        setLoading(true);
        setStore(initialValues);
        setLoading(false);
        break;
      case ActionTypeEnum.Update:
        window.scrollTo(0, 0);
        setStore(request.request);
        break;
      case ActionTypeEnum.DeleteOperationStart:
        setLoading(true);
        setShowDeleteStoreModel(false);
        const result = await deleteStore(request.id!);
        const isSuccess: boolean = !(
            result.Errors != null &&
            result.Errors.length !== 0
        );
        setLoading(false);
        if (isSuccess) {
          await handleStoreAction({
            id: ActionTypeEnum.Delete,
            action: ActionTypeEnum.Success
          });
        } else {
          setValidationErrors(result?.Errors || []);
        }
        break;
      case ActionTypeEnum.Delete:
        setStore(request.request);
        setShowDeleteStoreModel(true);
        break;
      case ActionTypeEnum.Failed:
        setValidationErrors(request.request??[])
        setStore(request.requestBeforeError);
        break;
      case ActionTypeEnum.Refresh:
        setIsRefresh(request.request);
        break;
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{pageName}</Accordion.Header>
          <Accordion.Body>
            <Card>
              <Card.Body>
                <AddStore
                  request={store}
                  onActionEvent={async (o: RequestActionModel) => {
                    await handleStoreAction(o);
                  }}
                  formId={formId}
                />
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body>
            <Card className="card-custom">
              {/* <Card.Header></Card.Header> */}
              <Card.Body>
                <ConfirmModelDialogBox
                  isModelVisible={showDeleteStoreModel}
                  onCloseEvent={() => {
                    setShowDeleteStoreModel(false);
                  }}
                  actions={deleteStoreActions}
                ></ConfirmModelDialogBox>
                {/* Store list */}
                <StoresList
                    isRefresh={isRefresh}
                    onActionEvent={async (o: RequestActionModel) => {
                      await handleStoreAction(o);
                    }}
                    formId={formId}
                />
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
