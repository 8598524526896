  import { t } from "i18next";
  import {
  Dispatch,
  FC,
  SetStateAction, useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
  import {
    LoadingBox,
    PrivilegesChecker,
    SearchBoxGeneric,
    GridTable,
  } from "..";
  import {
    ActionTypeEnum,
    LookupItemModel,
    LookupTypeEnum,
    PageEnum,
    RequestActionModel,
    SearchItemApiResponseModel,
    SearchItemRequestModel,
    SearchItemsTypeEnum,
    SearchFieldRequestModel,
  } from "../../models";
  import { searchItems } from "../../serviceBroker/itemApiServiceBroker";
  import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";
  import { isArabicCurrentLanguage } from "../../utils";
  import { handleGenerateSubHeaderComponent } from "./uiHelper/itemUnitDataTableColumns";
  import { useSearchBoxGeneric } from "../../hooks";
  import {debounce} from "lodash";

  export const ItemListManager: FC<{
    onActionEvent: (o: RequestActionModel) => void;
    setIsRefresh: Dispatch<SetStateAction<boolean>>;
    isRefresh: boolean;
    isTableLoading: boolean;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
  }> = ({
          onActionEvent = () => {},
          setIsRefresh,
          isRefresh = false,
          isTableLoading,
          setTableLoading,
        }) => {
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    const defaultPageSize: number = 10;

    const initialSearchFields: SearchFieldRequestModel[] = [
      { key: "name", value: "", label: "Name", placeholder: "Enter Name",triggerSearchOnChange: true },
      { key: "code", value: "", label: "Code", placeholder: "Enter Code" },
    ];
    //#endregion
    //#region state
    const {
      fields: searchFields,
      onChange: handleSearchFieldChange,
      onSearch: handleSearch,
      onReset: handleResetSearch,
      searchedFields,
    } = useSearchBoxGeneric(initialSearchFields);

    const [data, setData] = useState<SearchItemApiResponseModel[]>([]);
    const [categoryList, setCategoryList] = useState<LookupItemModel[]>([]);
    const [searchItemRequest, setSearchItemRequest] =
        useState<SearchItemRequestModel>({
          pageNumber: 1,
          pageSize: defaultPageSize,
          searchType: SearchItemsTypeEnum.ItemName,
          smallestUnit: true,
          searchValue: "",
          code:"",
          name:""
        });
    const [totalRows, setTotalRows] = useState(0);
    const filterSelectBoxMultiselectRef = useRef<any>();
    const filterGroupSelectBoxMultiselectRef = useRef<any>();
    //#endregion
    //#region functions
    const debounceSearchRequestUpdate = useCallback(
        debounce((updatedFields: Partial<SearchItemRequestModel>) => {
          setSearchItemRequest((prev) => ({
            ...prev,
            ...updatedFields,
            pageNumber: 1, // Reset page number for new search
          }));
        }, 300),
        []
    );
    const getItems = async () => {
      const result = await searchItems(searchItemRequest);
      setData(result?.Result || []);
      setTotalRows(result?.TotalRecoredCount || 0);
    };

    const onCurrentPageChange = async (pageNumber: number) => {
      if (pageNumber !== searchItemRequest.pageNumber) {
        const obj = { ...searchItemRequest };
        obj.pageNumber = pageNumber;
        setSearchItemRequest(obj);
      }
    };

    const onPageSizeChange = async (pageSize: number) => {
      if (pageSize !== searchItemRequest.pageSize) {
        const obj = { ...searchItemRequest };
        obj.pageSize = pageSize;
        setSearchItemRequest(obj);
      }
    };
    //#endregion
    //#region useEffect
    useEffect(() => {
      const fillData = async () => {
        setTableLoading(true);
        await getItems();
        setTableLoading(false);
      };
      fillData().then(() => {});
    }, []);
    useEffect(() => {
      const fillData = async () => {
        setTableLoading(true);
        await getItems();
        setTableLoading(false);
      };
      fillData().then(() => {});
    }, [
      searchItemRequest.pageNumber,
      searchItemRequest.pageSize,
      searchItemRequest.searchGuid,
      searchItemRequest.searchValue,
      searchItemRequest.name,
      searchItemRequest.code,
    ]);
    useEffect(() => {
      if (isRefresh) {
        const fillData = async () => {
          setTableLoading(true);
          await getItems();
          setTableLoading(false);
        };
        fillData().then(() => {});
        setIsRefresh(false);
      }
    }, [isRefresh]);
    useEffect(() => {
      // const searchValue = searchedFields
      //     .map((field) => `${field.label}: ${field.value}`)
      //     .filter((value) => value.trim() !== "")
      //     .join("; ");
      const updatedSearchRequest = searchFields.reduce<Partial<SearchItemRequestModel>>(
          (acc, field) => {
            if (field.key === "name") acc.name = field.value;
            if (field.key === "code") acc.code = field.value;
            return acc;
          },
          {}
      );
      //debounceSearchRequestUpdate(updatedSearchRequest);
       setSearchItemRequest((prev) => ({ ...prev,...updatedSearchRequest }));
    }, [searchedFields]);
    //#endregion
    //#region component
    const generateSubHeaderComponent = useMemo(() => {
      return handleGenerateSubHeaderComponent(
          categoryList,
          searchItemRequest,
          setSearchItemRequest,
          filterSelectBoxMultiselectRef,
          filterGroupSelectBoxMultiselectRef,
          t
      );
    }, [searchItemRequest, categoryList]);
    //#endregion
    //#region html
    return (
        <>
          <LoadingBox isLoading={isTableLoading}>
            <PrivilegesChecker formId={PageEnum.Items} action="EnableSearch">
              <SearchBoxGeneric
                  searchFields={searchFields}
                  onChangeHandler={handleSearchFieldChange}
                  onSearchHandler={handleSearch}
                  onResetHandler={handleResetSearch}
              />
            </PrivilegesChecker>
            <GridTable
                data={data}
                totalRows={totalRows}
                currentPage={searchItemRequest.pageNumber || 1}
                pageSize={searchItemRequest.pageSize || defaultPageSize}
                onCurrentPageChange={onCurrentPageChange}
                onPageSizeChange={onPageSizeChange}
                paginationType="server"
               // subHeaderComponent={generateSubHeaderComponent}
                columnsProps={{
                  actions: [
                    ActionTypeEnum.Update,
                    ActionTypeEnum.Delete,
                    ActionTypeEnum.Print,
                  ],
                  onActionEvent,
                  isArabic,
                }}
                formId={PageEnum.Items}
            />
          </LoadingBox>
        </>
    );
    //#endregion
  };
