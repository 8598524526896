import React, { useMemo } from "react";
import {
    TableActionColumnsStyleEnum,
    RequestActionModel,
    PageEnum, FlattenMenuModel, ActionTypeEnum,
} from "../../../../../models";
import { TableComponentV2 } from "../../../../index";
import {getLabelName, getMenuItemByFormId} from "../../../../../utils";
import {getClientActionsColumns} from "../rendering/renderGridActionsColumns";

//#region interface
interface ManualColumnsComponentProps {
    columns: any[];
    data: any[];
    tableProps: any;
    style?: TableActionColumnsStyleEnum | null;
    formId?: number | null;
    onActionEvent?: (o: RequestActionModel) => void;
}
//#endregion
//#region function
const getGridStyle = (
    formId: number,
    style: TableActionColumnsStyleEnum
): TableActionColumnsStyleEnum => {
    if (formId !== PageEnum.none) {
        const menuItem: FlattenMenuModel | null = getMenuItemByFormId(formId);
        return menuItem !== null && menuItem.ActionColumnsStyle != null
            ? menuItem.ActionColumnsStyle
            : style;
    }
    return style;
};

//#endregion
//#region html
export const ClientGridComponent: React.FC<ManualColumnsComponentProps> = ({
                                                                               columns,
                                                                               data,
                                                                               tableProps,
                                                                               style,
                                                                               formId,
                                                                               onActionEvent,
                                                                           }) => {
    style = getGridStyle(formId ?? PageEnum.none, style ?? TableActionColumnsStyleEnum.DropDownText);

    if (style !== TableActionColumnsStyleEnum.Button) {
        const buttonColumns = columns.filter((p) => p.button === true);
        const gridColumns = columns.filter((p) => p.button !== true);
        if (buttonColumns?.length) {
            const renderButtonColumns = getClientActionsColumns(
                buttonColumns,
                style || TableActionColumnsStyleEnum.DropDownText
            );
            columns = [...gridColumns, renderButtonColumns];
        }
    }

    const columnsWithRowIndex = useMemo(
        () => [
            {
                name: getLabelName("#"),
                selector: (_row: any, index: number) => index + 1,
                width: "60px",
            },
            ...columns,
        ],
        [columns]
    );

    return (
        <TableComponentV2
            {...tableProps}
            data={data}
            columns={columnsWithRowIndex}
            onRowDoubleClicked={(row: any) => {
                onActionEvent?.({
                    action: ActionTypeEnum.Update,
                    request: row,
                    id: row.ID,
                });
            }}
        />
    );
};
//#endregion
