import React, { FC, useState, ReactElement } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { isArabicCurrentLanguage } from "../../../utils";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const PdfViewerV2: FC<{
    content: string;
}> = ({ content }) => {
    // State management
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [error, setError] = useState<string>("");

    // Prepare the PDF source
    const getPdfSource = (base64String: string): string => {
        try {
            // Check if it already has the data URI prefix
            if (base64String.startsWith('data:application/pdf;base64,')) {
                return base64String;
            }
            // Add the prefix if it's missing
            return `data:application/pdf;base64,${base64String}`;
        } catch (err) {
            console.error('Error preparing PDF source:', err);
            setError('Failed to prepare PDF source');
            return '';
        }
    };

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        setNumPages(numPages);
        setError("");
    };

    return (
        <div className="pdf-container">
            {/* Navigation Controls */}
            <div className="pdf-navigation">
                <button
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
                    className={`nav-button ${pageNumber <= 1 ? 'disabled' : ''}`}
                >
                    Previous
                </button>

                <span className="page-info">
          Page {pageNumber} of {numPages}
        </span>

                <button
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={() => setPageNumber(prev => Math.min(prev + 1, numPages))}
                    className={`nav-button ${pageNumber >= numPages ? 'disabled' : ''}`}
                >
                    Next
                </button>
            </div>

            {/* PDF Viewer */}
            <div className="pdf-viewer">
                {error ? (
                    <div className="error-message">
                        {error}
                    </div>
                ) : (
                    <Document
                        file={getPdfSource(content)}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={(error) => {
                            console.error("Error loading PDF:", error);
                            setError("Failed to load PDF file. Please check if the file is valid.");
                        }}
                        loading={
                            <div className="loading-message">
                                Loading PDF...
                            </div>
                        }
                    >
                        <Page
                            pageNumber={pageNumber}
                            loading={
                                <div className="loading-message">
                                    Loading page...
                                </div>
                            }
                            renderTextLayer={true}
                            renderAnnotationLayer={true}
                        />
                    </Document>
                )}
            </div>

            <style>
                {`
          .pdf-container {
            width: 100%;
            height: 100vh;
            overflow: hidden;
            position: relative;
          }

          .pdf-navigation {
            background-color: #fff;
            padding: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            border-top: 1px solid #e0e0e0;
            z-index: 100;
          }

          .nav-button {
            background-color: #fff;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            padding: 5px 15px;
            margin: 0 5px;
            cursor: pointer;
          }

          .nav-button.disabled {
            background-color: #e0e0e0;
            cursor: not-allowed;
          }

          .page-info {
            margin: 0 10px;
          }

          .pdf-viewer {
            max-width: 100%;
            height: calc(100vh - 60px);
            overflow: auto;
            display: flex;
            justify-content: center;
            background-color: #525659;
          }

          .loading-message {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            color: #fff;
          }

          .error-message {
            color: #fff;
            padding: 20px;
            text-align: center;
          }

          .react-pdf__Document {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .react-pdf__Page {
            max-width: 100%;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
            margin: 1em;
            background-color: white;
          }

          .react-pdf__Page canvas {
            max-width: 100%;
            height: auto !important;
          }
        `}
            </style>
        </div>
    );
};
