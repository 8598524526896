import {useEffect, useState} from "react";
import {SystemConfiguration} from "../../configuration";
import {AppConfigurationModel} from "../../models";
import {secureLocalStorageGet, secureLocalStorageSet} from "../../utils";

export default function useFetchAppConfiguration() {
    const [data, setData] = useState<AppConfigurationModel>({
        apiEndPoint_Configurations: { alyusrApiEndpoint: "" },
        image_Configurations: { defaultHeight: 0, defaultWidth: 0 },
    });

    useEffect(() => {
        const obj = secureLocalStorageGet(SystemConfiguration.miscellaneous_Configurations.appConfig || "");
        if (obj == null) {
            fetch("config/appConfig.json", {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (myJson) {
                    secureLocalStorageSet(
                        SystemConfiguration.miscellaneous_Configurations.appConfig || "",
                        JSON.stringify(myJson)
                    );
                    setData(myJson);
                });
        }
    }, []);

    return { data };
}
