import React, { FC } from "react";
import {
    CustomersSimpleReportSearchParamsModel,
    LookupItemModel,
    LookupTypeEnum,
    SearchPanelPropsModel,
    SelectItemModel
} from "../../../models";
import { Form } from "react-bootstrap";
import { getLabelName } from "../../../utils";
import { SelectBox, TextBox, ButtonBox, PrivilegesChecker } from "../..";
import { useLookups } from "../../../hooks";

export const ReportCustomersSimpleSearchPanel: FC<
    SearchPanelPropsModel<CustomersSimpleReportSearchParamsModel>
> = ({
    setLoading,
    handelSearch,
    searchParams,
    setSearchParams, formId,
}) => {
        //#region state
        const { getLookupItems } = useLookups(setLoading, [
            LookupTypeEnum.Customers,
            LookupTypeEnum.Cities,
        ]);
        //#endregion
        //#region html
        return (
            <>
                <Form>
                    <div
                        className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
                        <SelectBox
                            labelName={getLabelName("Customer")}
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.customerId
                                    ? [searchParams.customerId.toString()]
                                    : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.Customers, true)}
                            onStatusChange={(e: SelectItemModel) => {
                                const obj = { ...searchParams };
                                if (e == null) {
                                    obj.customerId = undefined;
                                    //obj.customerName = undefined;
                                } else {
                                    obj.customerId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                    // obj.customerName =
                                    //     e.value === "" || e.value === null
                                    //         ? undefined
                                    //         : e.label;
                                }
                                setSearchParams(obj);
                            }}
                        />
                        <SelectBox
                            labelName={getLabelName("City")}
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.cityId ? [searchParams.cityId.toString()] : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.Cities, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = { ...searchParams };
                                if (e == null) {
                                    obj.cityId = undefined;
                                } else {
                                    obj.cityId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                        <TextBox
                            labelName={getLabelName("Account Balance")}
                            inputName="customerBalanceCriteria"
                            inputValue={searchParams.customerBalanceCriteria}
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const obj = { ...searchParams };
                                if (
                                    e.target.value === "" ||
                                    e.target.value === null ||
                                    e.target.value === undefined
                                ) {
                                    obj.customerBalanceCriteria = undefined;
                                } else {
                                    obj.customerBalanceCriteria = e.target.value;
                                }
                                setSearchParams(obj);
                            }}
                        />
                    </div>
                    <PrivilegesChecker formId={formId} action="EnableSearch">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                            <ButtonBox
                                iconType="magnify"
                                className="btn-gradient-primary mx-3 btn-fw"
                                variant="outline-primary"
                                onClick={handelSearch}
                            >
                                {getLabelName("Search")}
                            </ButtonBox>
                        </div>
                    </PrivilegesChecker>
                </Form>
            </>
        );
        //#endregion
    };
