import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  isArabicCurrentLanguage,
} from "../../utils";
import {  LoadingBox, GridTable } from "..";
import {
  GenericSearchRequestModel,
  RequestActionModel,
  ActionTypeEnum,
  paymentTypesApiResponseModel,
  PageEnum,
} from "../../models";
import { getAllPaymentTypes } from "../../serviceBroker/paymentTypeApiServiceBroker";

export const PaymentTypeList: FC<{
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  isRefresh: boolean;
  onActionEvent: (o: RequestActionModel) => void;
}> = ({ setIsRefresh, isRefresh, onActionEvent }) => {
  //#region state
  const [searchParams] = useState<GenericSearchRequestModel>({
    pageSize: 10,
    pageNumber: 1,
  });
  const [data, setData] = useState<paymentTypesApiResponseModel[]>([]);
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      setData(await getList());
      setLoading(false);
      setIsRefresh(false);
    };
    fillData().then(() => { });
  }, []);
  useEffect(() => {
    const fillData = async () => {
      if (isRefresh) {
        setLoading(true);
        setData(await getList());
        setLoading(false);
        setIsRefresh(false);
      }
    };
    fillData().then(() => { });
  }, [isRefresh]);
  //#endregion
  //#region functions
  const getList = async (): Promise<paymentTypesApiResponseModel[]> => {
    const paymentTypesList = await getAllPaymentTypes();
    return paymentTypesList !== null &&
      paymentTypesList !== undefined &&
      paymentTypesList.Result !== null &&
      paymentTypesList.Result !== undefined &&
      paymentTypesList.Result?.length !== 0
      ? paymentTypesList.Result
      : [];
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <GridTable
        //columns={columns}
        data={data}
        totalRows={data.length}
        currentPage={searchParams.pageNumber}
        pageSize={searchParams.pageSize}
        // onCurrentPageChange={onCurrentPageChange}
        // onPageSizeChange={onPageSizeChange}
        paginationType="client"
        columnsProps={{
          actions: [ActionTypeEnum.Update, ActionTypeEnum.Delete],
          isArabic: isArabicCurrentLanguage(),
          onActionEvent,
        }}
        formId={PageEnum.PaymentType}
      />
    </>
  );
  //#endregion
};
