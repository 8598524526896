import _ from "lodash";
import {Dispatch, SetStateAction} from "react";
import {
    GatherItemModel,
    ItemInStoreModel,
    ItemModel,
    ItemUnitModel,
    KeyValueStateModel,
    RowStateEnum
} from "../../../models";
import {generateGuid} from "../../../utils";
//#region variables
export const itemUnitInitialValues = (
    defaultUnitID: number
): Readonly<ItemUnitModel> => {
    return {
        ID: 0,
        Name: "",
        ItemInstore_ID: 0,
        Unit_ID: defaultUnitID,
        Price: 0,
        PriceSale: 0,
        PriceSaleWithTax: 0,
        PriceSaleInOtherCurency: 0,
        OtherCurrencyValue: 0,
        QutyBegBal: 0,
        Factor: 1,
        UnitBalance: 0,
        PriceQutyBegBal: 0,
        PriceQutyBegBalWithTax: 0,
        PriceInOtherCurency: 0,
        LastPriceBuy: 0,
        MinimumPrice: 0,
        MaximumPrice: 0,
        AddationalPrice: 0,
        AddationalPriceWithTax: 0,
        WholeSalePrice: 0,
        WholeSalePriceWithTax: 0,
        PriceCost: 0,
        Balance: 0,
        Tax: 0,
        PriceLastBuy: 0,
        ProfitPercentage: 0,
        Transporttion_Cost: 0,
        IsSmallestUnit: false,
        IsDefault: false,
        IsMultiUnit: false,
        HasItemUnitBarcode: false,
        UsedInTransaction: false,
        Barcode: "",
        ScaleBarcode: "",
        ItemType: "",
        EinvoiceItemCode: "",
        //ItemGathers: null,
        rowState: RowStateEnum.Add,
        rowKey:  generateGuid(),
    };
};
//#endregion
//#region itemUnits
export const handleAddItemUnitRow = (
    setState: Dispatch<SetStateAction<ItemModel>>,
    defaultUnitID: number
) => {
    setState((prevState) => {
        const updatedItemsInStore = prevState.ItemsInstores.map((store, storeIndex) => {
            if (storeIndex === 0) {
                return {
                    ...store,
                    Item_unit: [
                        ...store.Item_unit,
                        itemUnitInitialValues(defaultUnitID),
                    ],
                };
            }
            return store;
        });

        return {
            ...prevState,
            ItemsInstores: updatedItemsInStore,
        };
    });
};

export const handleDeleteItemUnitRow = (
    setState: Dispatch<SetStateAction<ItemModel>>,
    rowKey: string,
    isNew: boolean
) => {
    setState((prevState) => {
        const updatedItemsInStore = prevState.ItemsInstores.map((store, storeIndex) => {
            if (storeIndex === 0) {
                const updatedItemUnits = isNew
                    ? store.Item_unit.filter((row) => row.rowKey !== rowKey) // Remove new rows
                    : store.Item_unit.map((row) => {
                        if (`${row.ID}` === rowKey) {
                            return { ...row, rowState: RowStateEnum.Delete }; // Mark existing rows as deleted
                        }
                        return row;
                    });

                return {
                    ...store,
                    Item_unit: updatedItemUnits,
                };
            }
            return store;
        });

        return {
            ...prevState,
            ItemsInstores: updatedItemsInStore,
        };
    });
};
export const generateItemStore = (): ItemInStoreModel[] => {
    return [
        {
            ID: 0,
            Item_ID: 0,
            Name: "",
            Store_ID: 1,
            LimitOrder: 0,
            DefaultExpiryDays: 0,
            Status: true,
            UseExpiryDate: false,
            ItemGathers: [],
            Item_unit: [
                {
                    AddationalPriceWithTax: 0,
                    Balance: 0,
                    EinvoiceItemCode: "",
                    IsMultiUnit: false,
                    AddationalPrice: 0,
                    Factor: 1,
                    HasItemUnitBarcode: false,
                    ID: 0,
                    IsDefault: false,
                    IsSmallestUnit: false,
                    // ItemGathers: [],
                    ItemInstore_ID: 0,
                    ItemType: "",
                    LastPriceBuy: 0,
                    MaximumPrice: 0,
                    MinimumPrice: 0,
                    Name: "",
                    OtherCurrencyValue: 0,
                    Price: 0,PriceCost: 0,
                    PriceInOtherCurency: 0,
                    PriceLastBuy: 0,
                    PriceQutyBegBal: 0,
                    PriceQutyBegBalWithTax: 0,
                    PriceSale: 0,
                    PriceSaleInOtherCurency: 0,
                    PriceSaleWithTax: 0,
                    ProfitPercentage: 0,
                    QutyBegBal: 0,
                    rowKey: generateGuid(),
                    rowState: 1,
                    ScaleBarcode: "",
                    Tax: 0,
                    Transporttion_Cost: 0,
                    Unit_ID: 0,
                    UnitBalance:0,
                    UsedInTransaction: false,
                    WholeSalePrice: 0,
                    WholeSalePriceWithTax: 0,
                    Barcode: "",
                }
            ],
            rowState: Number(RowStateEnum.Add),
        },
    ];
};
export const handleCalculatePriceWithTax = (price: number, tax: number): number => {
    return parseFloat((+price + (tax / 100) * +price).toFixed(2));};
export const handleCalculatePrice = (price: number, tax: number): number => { return parseFloat( (price / (1 + tax / 100)).toFixed(2));};

export const handleChangeItemUnitValues = (
    setState: Dispatch<SetStateAction<ItemModel>>,
    rowKey: string,
    keyValues: KeyValueStateModel[]
) => {
    setState((prevState) => {
        const updatedItemsInStore = prevState.ItemsInstores.map((store, storeIndex) => {
            if (storeIndex === 0) {
                return {
                    ...store,
                    Item_unit: store.Item_unit.map((row) => {
                        if ((row.rowKey || `${row.ID}`) === rowKey) {
                            let updatedRow = { ...row };
                            keyValues.forEach((row) => {
                                updatedRow = {...updatedRow, [row.key]: row.value}
                                //updatedRow[row.key] = row.value;
                            });
                            return updatedRow;
                            //return { ...row, [key]: value };
                        }
                        return row;
                    }),
                };
            }
            return store;
        });
        return {
            ...prevState,
            ItemsInstores: updatedItemsInStore,
        };
    });
    // setState((prev) => {
    //   return prev.map((row) => {
    //     if ((row.rowKey || `${row.ID}`) === rowKey) {
    //       return { ...row, [key]: value };
    //     } else {
    //       return row;
    //     }
    //   });
    // });
};
export const handleChangeItemUnitRow = (
    setState: Dispatch<SetStateAction<ItemUnitModel[]>>,
    stateValue: ItemUnitModel[],
    request: ItemUnitModel
) => {
    stateValue = _.map(stateValue, (row) => {
        if (row.rowKey === request.rowKey) {
            return { ...request };
        } else {
            return row;
        }
    });
    setState(stateValue);
};
export const getItemUnits=(request:ItemModel):ItemUnitModel[]=> {
    const firstItemInStores = request?.ItemsInstores?.find(() => true);
    return firstItemInStores?.Item_unit ?? [];
}
//#endregion
//#region itemGathers
export const handleChangeItemGathersValues = (
    setState: Dispatch<SetStateAction<ItemModel>>,
    rowKey: string,
    keyValues: KeyValueStateModel[],
    isHandleCalculateBeginPriceQyt:Boolean=false,
) => {
    setState((prevState) => {
        prevState.TaxValue
        const updatedItemsInStore = prevState.ItemsInstores.map((store, storeIndex) => {
            if (storeIndex === 0) {
                return {
                    ...store,
                    ItemGathers: store.ItemGathers.map((row) => {
                        if ((row.rowKey || `${row.ID}`) === rowKey) {
                            let updatedRow = { ...row };
                            keyValues.forEach((row) => {
                                updatedRow = {...updatedRow, [row.key]: row.value}
                                //updatedRow[row.key] = row.value;
                            });
                            return updatedRow;
                            //return { ...row, [key]: value };
                        }
                        return row;
                    }),
                };
            }
            return store;
        });
        const finalUpdatedItemsInStore =isHandleCalculateBeginPriceQyt
            ? handleCalculateBeginPriceQyt(updatedItemsInStore)
            : updatedItemsInStore;
        return {
            ...prevState,
            ItemsInstores: finalUpdatedItemsInStore,
        };
    });
};
export const handleDeleteItemGathersRow = (
    setState: Dispatch<SetStateAction<ItemModel>>,
    rowKey: string,
    isNew: boolean,
) => {
    setState((prevState) => {
        const updatedItemsInStore = prevState.ItemsInstores.map((store, storeIndex) => {
            if (storeIndex === 0) {
                const updatedItemUnits = isNew
                    ? store.ItemGathers.filter((row) => row.rowKey !== rowKey) // Remove new rows
                    : store.ItemGathers.map((row) => {
                        if (`${row.ID}` === rowKey) {
                            return { ...row, rowState: RowStateEnum.Delete }; // Mark existing rows as deleted
                        }
                        return row;
                    });

                return {
                    ...store,
                    ItemGathers: updatedItemUnits,
                };
            }
            return store;
        });
        const finalUpdatedItemsInStore =!isNew
            ? handleCalculateBeginPriceQyt(updatedItemsInStore)
            : updatedItemsInStore;
        return {
            ...prevState,
            ItemsInstores: finalUpdatedItemsInStore,
        };
    });
};
const handleCalculateBeginPriceQyt = ( store: ItemInStoreModel[],taxValue?:number): ItemInStoreModel[] => {
    return store.map((item, index) => {
        if (index === 0 && item.ItemGathers) {
            const sum = item.ItemGathers
                .filter(({ rowState }) => rowState !== RowStateEnum.Delete)
                .reduce((acc, cur) => cur.PriceCost * cur.Quantity + acc, 0);

            return {
                ...item,
                PriceQutyBegBal: sum,
                PriceQutyBegBalWithTax: +(
                    sum +
                    (sum * (taxValue ?? 0)) / 100
                ).toFixed(2),
            };
        }
        return item;
    });
};
export const getItemGathers=(request:ItemModel):GatherItemModel[]=>{
    const firstItemInStores = request?.ItemsInstores?.find(() => true);
    return firstItemInStores?.ItemGathers ?? [];
}
//#endregion