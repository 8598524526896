import {FC, ReactNode} from "react";
import {Modal} from "react-bootstrap";
import {getLabelName, isArabicCurrentLanguage} from "../../../../utils";
import {ButtonBox} from "../../../index";
import './modelDialogBox.css';
type ModelSize = "sm" | "lg" | "xl" | "xxl" | "xxxl" | "xxxxl" | undefined;
export const ModelDialogBox: FC<{
    isModelVisible: boolean;
    isCloseButtonVisible?: boolean;
    isXCloseButtonVisible?: boolean;
    isEscapeCloseEnabled?: boolean;
    title?: string;
    size?: ModelSize | undefined;
    fullScreen?: boolean | undefined;
    onCloseEvent?: any;
    children?: ReactNode | undefined;
}> = ({
          isModelVisible = false,
          isCloseButtonVisible = false,
          isXCloseButtonVisible = false,
          isEscapeCloseEnabled = false,
          title,
          size = undefined,
          fullScreen = false,
          onCloseEvent,
          children,
      }) => {
    //#region functions
    const getModalMaxWidth = (size: ModelSize): string | undefined => {
        switch (size) {
            case "xxl": return "1400px";
            case "xxxl": return "1800px";
            case "xxxxl": return "2200px";
            default: return undefined;
        }
    };
    const getModalSizeClass = (size: ModelSize) => {
        return size ? `modal-${size}` : "";
    };
    const customSizeClass = size
        ? {
            sm: "",
            lg: "",
            xl: "",
            xxl: "modal-xxl",
            xxxl: "modal-xxxl",
            xxxxl: "modal-xxxxl",
        }[size]
        : "";
    //#endregion
    //#region variables
    const isArabic: boolean = isArabicCurrentLanguage();
    const direction: string = isArabic ? "rtl" : "ltr";
    const maxWidth = getModalMaxWidth(size);
    //#endregion
    //#region html
    return (
        <>
            <Modal
                show={isModelVisible}
                onHide={() => {
                    isEscapeCloseEnabled && onCloseEvent();
                }}
                centered
                size={["sm", "lg", "xl"].includes(size as string) ? size as "sm" | "lg" | "xl" : undefined} // Correctly type and filter size prop
                //size={size}
                fullscreen={typeof fullScreen === 'boolean' ? fullScreen ? true : undefined : fullScreen}
                dialogClassName={customSizeClass}
                //dialogClassName={maxWidth ? "modal-custom-width" : undefined}
                //style={maxWidth ? { maxWidth, width: '90%' } : undefined}
            >
                {/*<Modal.Header
          dir={direction}
          closeButton={isCloseButtonVisible}
        ></Modal.Header>
         {title && <Modal.Title>{title}</Modal.Title>}
        <Modal.Body dir={direction}></Modal.Body> 
        {isCloseButtonVisible && (
          <Modal.Footer dir={direction}>
            <ButtonBox variant="primary" onClick={() => onCloseEvent()}>
              {t("close")}
            </ButtonBox>
          </Modal.Footer>
        )} */}
                <Modal.Header dir={direction} closeButton={isCloseButtonVisible || isXCloseButtonVisible}>
                    {title && <Modal.Title>{title}</Modal.Title>}
                </Modal.Header>
                <Modal.Body dir={direction}>{children}</Modal.Body>
                {isCloseButtonVisible && (
                    <Modal.Footer dir={direction}>
                        <ButtonBox variant="danger" size="sm" iconType="close-circle" onClick={() => onCloseEvent()}>
                            {getLabelName("close")}
                        </ButtonBox>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
    //*#endregion
};
