import secureLocalStorage from "react-secure-storage";
import { SystemConfiguration } from "../../configuration";
import { KeyValueStateModel, LookupTypeEnum } from "../../models";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";
//#region localStorages
const localStorageSet = (name: string, value: string) => {
    localStorage.setItem(name, value);
};
const localStorageGet = (name: string): string | null => {
    return localStorage.getItem(name);
};
const localStorageRemove = (name: string) => {
    localStorage.removeItem(name);
};
//#endregion
//#region secureLocalStorages
const secureLocalStorageSet = (name: string, value: string) => {
    secureLocalStorage.setItem(name, value);
};
const secureLocalStorageGet = (
    name: string
): string | object | number | boolean | null => {
    return secureLocalStorage.getItem(name);
};
const secureLocalStorageRemove = (name: string) => {
    secureLocalStorage.removeItem(name);
    //secureLocalStorage.clear();
};

const secureLocalStorageClear = () => {
    secureLocalStorage.clear();
    secureLocalStorage.setItem(SystemConfiguration.miscellaneous_Configurations.homePageReloaded, "0");
};
const secureLocalStorageClearLookupCache = async () => {
    let kvList: KeyValueStateModel[] = [];
    for (const key in LookupTypeEnum) {
        if (LookupTypeEnum.hasOwnProperty(key)) {
            const value = LookupTypeEnum[key];
            if (!isNaN(parseFloat(value))) {
                if (secureLocalStorageGet(key)) {
                    secureLocalStorageRemove(key);
                    kvList.push({ key: key, value: value });
                }
            }
        }
    }

    if (kvList.length !== 0) {
        const lookupPromises = kvList.map(async (row) => {
            await getLookupByType(row.value, true, false);
        });
        // Wait for all promises to resolve before proceeding
        await Promise.all(lookupPromises);
    }
};
const parseSecureLocalStorage = <T>(key: string, fallback: T): T => {
    const data = secureLocalStorageGet(key);
    if (typeof data === "string") {
        try {
            return JSON.parse(data) as T;
        } catch (e) {
            console.error("Failed to parse local storage data:", e);
        }
    }
    return fallback;
};

function parseSecureLocalStorage1<T>(key: string, fallback: T): T {
    const data = secureLocalStorageGet(key);
    if (typeof data === "string") {
        try {
            return JSON.parse(data) as T;
        } catch (e) {
            console.error("Failed to parse local storage data:", e);
        }
    }
    return fallback;
}
//#endregion
export {
    localStorageSet,
    localStorageGet,
    localStorageRemove,
    secureLocalStorageSet,
    secureLocalStorageGet,
    secureLocalStorageRemove,
    secureLocalStorageClear,
    secureLocalStorageClearLookupCache,
    parseSecureLocalStorage
};
