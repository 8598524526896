import React, {FC, MutableRefObject} from "react";
import {getLabelName} from "../../utils";
import {TextBox,SelectBox,CheckBox,InputDatePicker} from "..";
import {
    InputDatePickerTimeDisplayModeEnum,
    ItemConfigurationsResponseModel,
    ItemModel,
    LookupItemModel
} from "../../models";
import {FormikProps} from "formik";

export const  ItemDetailsManager:FC<{formik:FormikProps<ItemModel>,
    item:ItemModel,
    categoryList:LookupItemModel[],
    itemConfigs:ItemConfigurationsResponseModel,
    categorySelectBoxMultiselectRef:any
    }> = ({formik,item, categoryList,itemConfigs,categorySelectBoxMultiselectRef}) => {
    return (
        <>
            <div
                className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
                <SelectBox
                    labelName={getLabelName("Categorys")}
                    source={categoryList}
                    isSingleSelect={true}
                    onStatusChange={(e: any) => {
                            formik.values.Cat_ID =e === null? 0: e.value;
                    }}
                    selectedValues={
                        item == null ? null : [item.Cat_ID.toString()]
                    }
                    errorText={formik.errors.Cat_ID}
                    //@ts-ignore
                    multiselectRef={(node: MutableRefObject<any>) =>
                        node ? (categorySelectBoxMultiselectRef.current = node) : null
                    }
                />
                <TextBox
                    key="Name"
                    labelName={getLabelName("Item Name")} //{t("lookup.nameAr")}
                    inputName="Name"
                    errorText={formik.errors.Name}
                    inputValue={formik.values.Name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <TextBox
                    labelName={getLabelName("English Name")} //{t("lookup.nameAr")}
                    inputName="Name_En"
                    errorText={formik.errors.Name_En}
                    inputValue={formik.values.Name_En}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <TextBox
                    labelName={getLabelName("ItemCode")} //{t("lookup.nameAr")}
                    inputName="Code"
                    errorText={formik.errors.Code}
                    inputValue={formik.values.Code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <TextBox
                    labelName={getLabelName("txtTaxPercentage")} //{t("lookup.nameAr")}
                    inputName="TaxValue"
                    type="number"
                    errorText={formik.errors.TaxValue}
                    inputValue={formik.values.TaxValue}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <TextBox
                    labelName={getLabelName("Limit Order")}
                    inputName="OrderLimit"
                    type="number"
                    inputValue={formik.values.OrderLimit}
                    onChange={(e: any) => {
                        formik.values.OrderLimit =e.target.value;
                        formik.setFieldValue("OrderLimit", e.target.value);
                    }}
                />
                <TextBox
                    labelName={getLabelName("Stagnant Period")} //{t("lookup.nameAr")}
                    inputName="StagnantPeriod"
                    type="number"
                    errorText={formik.errors.StagnantPeriod}
                    inputValue={formik.values.StagnantPeriod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />

                <TextBox
                    labelName={getLabelName("Display Index")} //{t("lookup.nameAr")}
                    inputName="DisplySequence"
                    type="number"
                    errorText={formik.errors.DisplySequence}
                    inputValue={formik.values.DisplySequence}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />

                <TextBox
                    labelName={getLabelName("Total Tobaco Value")} //{t("lookup.nameAr")}
                    inputName="TobaccoTaxPercentage"
                    type="number"
                    errorText={formik.errors.TobaccoTaxPercentage}
                    inputValue={formik.values.TobaccoTaxPercentage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isReadOnly={!formik.values.IsIncludeTobaccoTax}
                />
                {itemConfigs.ShowExpireDate && (
                    <CheckBox
                        labelName={getLabelName("Use Expiry Date")}
                        checked={formik.values.UseExpiryDate}
                        inputName="UseExpiryDate"
                        onChange={(e: any) => {
                            formik.values.UseExpiryDate =
                                e.target.checked;
                            formik.setFieldValue("UseExpiryDate", e.target.checked);
                        }}
                        id="UseExpiryDate"
                    />
                )}
                {formik.values.UseExpiryDate && (
                    <InputDatePicker
                        id="datePicker"
                        timeMode={InputDatePickerTimeDisplayModeEnum.None}
                        selectedDate={formik.values.DateExpiry}
                        className="form-control"
                        InputLabel={getLabelName("Expiry Date")}
                        name={getLabelName("Expiry Date")}
                        onChange={(date: Date) => {
                            formik.values.DateExpiry = date;
                            formik.setFieldValue("DateExpiry", date);
                        }}
                    />
                )}
                {formik.values.UseExpiryDate && (
                <TextBox
                    labelName={getLabelName("Expire Day")} //{t("lookup.nameAr")}
                    inputName="ExpireDay"
                    type="number"
                    errorText={formik.errors.ExpireDay}
                    inputValue={
                        formik.values.ExpireDay
                    }
                    onChange={(e: any) => {
                        formik.values.ItemsInstores[0].DefaultExpiryDays =
                            e.target.value;
                        formik.setFieldValue("ExpireDay", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    isReadOnly={!formik.values.UseExpiryDate}
                />
                )}
                <CheckBox
                    labelName={getLabelName("Tobacco Tax")}
                    checked={formik.values.IsIncludeTobaccoTax}
                    inputName="IsIncludeTobaccoTax"
                    onChange={() => {
                        formik.setFieldValue(
                            "IsIncludeTobaccoTax",
                            !formik.values.IsIncludeTobaccoTax
                        );
                    }}
                    id="IsIncludeTobaccoTax"
                />
                <CheckBox
                    labelName={getLabelName("Open Price")}
                    checked={formik.values.OpenItem}
                    inputName="OpenItem"
                    onChange={() => {
                        formik.setFieldValue("OpenItem", !formik.values.OpenItem);
                    }}
                    id="OpenItem"
                />
                <CheckBox
                    labelName={getLabelName("ShowInPOS")}
                    checked={formik.values.ShowInPOS}
                    inputName="ShowInPOS"
                    onChange={() => {
                        formik.setFieldValue(
                            "ShowInPOS",
                            !formik.values.ShowInPOS
                        );
                    }}
                    id="ShowInPOS"
                />
                <CheckBox
                    labelName={getLabelName("Is Active")}
                    checked={formik.values.Status}
                    inputName="Status"
                    onChange={() => {
                        formik.setFieldValue(
                            "Status",
                            !formik.values.Status
                        );
                    }}
                    id="Status"
                />
                <CheckBox
                    labelName={getLabelName("chkWithoutBalance")}
                    checked={formik.values.WithoutBalance}
                    inputName="WithoutBalance"
                    onChange={() => {
                        formik.setFieldValue(
                            "WithoutBalance",
                            !formik.values.WithoutBalance
                        );
                    }}
                    id="WithoutBalance"
                />
            </div>
            <div
                className="row mt-4 row-cols-1 row-cols-xxl-12 row-cols-xl-12 row-cols-lg-12 row-cols-md-12 row-cols-sm-12 g-md-12 g-sm-12">
                <TextBox
                    labelName={getLabelName("Notes")} //{t("lookup.nameAr")}
                    inputName="Notes"
                    type="textarea"
                    errorText={formik.errors.Notes}
                    inputValue={formik.values.Notes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
            </div>
        </>
    );
}