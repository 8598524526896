import {LookupItemModel, LookupTypeEnum} from "../../models";
import {getUserId, secureLocalStorageGet, secureLocalStorageRemove, secureLocalStorageSet} from "..";
import {getLookupByType} from "../../serviceBroker/lookupApiServiceBroker";
import {SystemConfiguration} from "../../configuration";
import {getUserPrivileges, getUserSetting} from "../../serviceBroker/userApiServiceBroker";
export const reValidateLocalStorageCachedData = async (
    type: LookupTypeEnum,userId?:number|null
) => {
    let  cacheKey: string ="";
    const currentUserId=getUserId();
    userId=userId||currentUserId;
    if(userId==currentUserId) {
        switch (type) {
            case LookupTypeEnum.UserSettingCacheKey:
                cacheKey = SystemConfiguration.cacheKey_Configurations.setting.currentUserSettings;
                break;
            case LookupTypeEnum.UserPosSettingCacheKey:
                cacheKey = SystemConfiguration.cacheKey_Configurations.miscellaneous.privileges;
                break;
            default:
                cacheKey = Object.keys(LookupTypeEnum)[Object.values(LookupTypeEnum).indexOf(type)];
        }
        const data = secureLocalStorageGet(cacheKey);
        console.log(data, "cached data")
        if (data === null) return;
        secureLocalStorageRemove(cacheKey);
        switch (type) {
            case LookupTypeEnum.UserSettingCacheKey:
                await fillLocalStorageCachedData(await getUserSetting(currentUserId, false), cacheKey);
                break;
                case LookupTypeEnum.UserPosSettingCacheKey:
                    await fillLocalStorageCachedData(await getUserPrivileges(currentUserId), cacheKey);
                    break;
            default:
                await fillLookUpLocalStorageCachedData(type);
                break;
        }
    }
};

// const data = await getCachedDataOrFetch<LookupItemModel[]>(
//     cacheKey,
//     () => getAcTransactionsAccountsLookupListByTransactionType(type)
// );
export const getCachedDataOrFetch = async <T>(
    cacheKey: string,
    fetchFunction: () => Promise<T>
): Promise<T> => {
    const cachedData = getLocalStorageCachedData<T>(cacheKey);
    if (cachedData) {
        return cachedData;
    }
    const fetchedData = await fetchFunction();
    secureLocalStorageSet(cacheKey, JSON.stringify(fetchedData));
    return fetchedData;
};
//#region private
export const getLocalStorageCachedData = <T>(key: string): T | null => {
    const data = secureLocalStorageGet(key);
    if (!data) return null;
    try {
        return JSON.parse(data as string) as T;
    } catch (error) {
        return null;
    }
};
const getLookupLocalStorageCachedData = async (
    type: LookupTypeEnum
): Promise<LookupItemModel[]> => {
    let response: LookupItemModel[];
    const cacheKey: string =
        Object.keys(LookupTypeEnum)[Object.values(LookupTypeEnum).indexOf(type)];
    const data = secureLocalStorageGet(cacheKey);
    if (data !== null && data !== undefined) {
        response = JSON.parse(data as string) as LookupItemModel[];
    } else {
        response = await getLookupByType(type, false, false);
        if (response != null && response.length != 0) {
            secureLocalStorageSet(cacheKey, JSON.stringify(response));
        }
    }
    return response;
};
const fillLookUpLocalStorageCachedData = async (
    type: LookupTypeEnum
) => {
    const cacheKey: string =
        Object.keys(LookupTypeEnum)[Object.values(LookupTypeEnum).indexOf(type)];
    const  response = await getLookupByType(type, false, false);
    console.log(response)
    if (response != null && response.length != 0) {
        secureLocalStorageSet(cacheKey, JSON.stringify(response));
    }
};
const fillLocalStorageCachedData = async (data:any,cacheKey:string)=>{
    if(data===null||data===undefined)return;
    secureLocalStorageSet(cacheKey,JSON.stringify(data));
}
//#endregion