import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import {SystemConfiguration} from "../../configuration";

const cookieSet = (
    name: string,
    value: string,
    expiryMinutes: number = Number(SystemConfiguration.cookie_Configurations.expiryMinutes) ||
    240
) => {
    Cookies.set(name, value, {
        expires: new Date(new Date().getTime() + expiryMinutes * 60 * 1000),
    });
};
const cookieEncryptedSet = (
    name: string,
    value: string,
    expiryMinutes: number = Number(SystemConfiguration.cookie_Configurations.expiryMinutes) ||
    240,
    encryptKey: string = SystemConfiguration.cookie_Configurations.encryptKey.toString()
) => {
    Cookies.set(name, CryptoJS.AES.encrypt(value, encryptKey).toString(), {
        expires: new Date(new Date().getTime() + expiryMinutes * 60 * 1000),
    });
};
const cookieGet = (name: string): string | undefined => {
    return Cookies.get(name);
};
const cookieEncryptedGet = (
    name: string,
    encryptKey: string = SystemConfiguration.cookie_Configurations.encryptKey.toString()
): string | undefined => {
    const cookieValue: string | undefined = Cookies.get(name);
    return cookieValue !== undefined && cookieValue !== null
        ? CryptoJS.AES.decrypt(cookieValue.toString(), encryptKey).toString(
            CryptoJS.enc.Utf8
        )
        : undefined;
};
const clearCookie = (name: string, domainName?: string | null) => {
    const domain = domainName != null ? `domain=${domainName};` : "";
    document.cookie =
        name + `=; Path=/; ${domain} Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
const clearAllCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
};
export {
    cookieSet,
    cookieEncryptedSet,
    cookieGet,
    cookieEncryptedGet,
    clearAllCookies,
    clearCookie,
};
