import {getUserId} from "../secure/secureManager";
import {getUserSetting} from "../../serviceBroker/userApiServiceBroker";
import {SystemConfiguration} from "../../configuration";
import i18n from "../../components/languageSwitcher/react-i18/i18n";
import {
    secureLocalStorageGet,
    secureLocalStorageSet
} from "../localStorage/localStorageManager";


export   const handleLanguageChange = async (userId?:number|null,isFetchFromUserSetting?:boolean|null) => {
   const currentUserId=getUserId();
    userId=userId||currentUserId;
    if(userId==currentUserId&& isFetchFromUserSetting){
        const userSetting = await getUserSetting(userId, true);
        const language:string=userSetting?.Lang==1?SystemConfiguration.culture_Configurations.arabicCulture:SystemConfiguration.culture_Configurations.englishCulture
        i18n.changeLanguage(language).then(() => {});
        secureLocalStorageSet(SystemConfiguration.culture_Configurations.currentCulture || "", language);
    }else{
        const language:string=(secureLocalStorageGet(SystemConfiguration.culture_Configurations.currentCulture) as string) ??
            SystemConfiguration.culture_Configurations.defaultUiLanguage
        i18n.changeLanguage(language).then(() => {});
        secureLocalStorageSet(SystemConfiguration.culture_Configurations.currentCulture || "", language);
    }
};