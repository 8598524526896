import React, {useState} from "react";
import {ConfirmModelDialogBox, GridTable, LoadingBox, PrivilegesChecker, SearchBox,} from "..";
import {BranchesChildComponent} from "./branchesMainComponent";
import {ActionButtonsModel, ActionTypeEnum, BranchResponseModel, PageEnum, RequestActionModel} from "../../models";
import {getLabelName, isArabicCurrentLanguage} from "../../utils";
import {deleteBranch} from "../../serviceBroker/branchesServiceBroker";
import {useSearchBox} from "../../hooks";

export interface BranchesListProps extends BranchesChildComponent {
  isTableLoading: boolean;
  branchesLoading: boolean;
  branches: BranchResponseModel[];
  setBranchesFormLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setBranches: React.Dispatch<React.SetStateAction<BranchResponseModel[]>>;
  onActionEvent: (o: RequestActionModel) => void;
}

export const BranchList: React.FC<BranchesListProps> = ({
  isTableLoading,
  setCurrentBranchID,
  branches,
  branchesLoading,
  fetchBranches,
  onActionEvent
}) => {
  //#region state
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [deletedID, setDeletedID] = useState<null | number>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { onChange, searchedWord, onSearch, value } = useSearchBox();
  const handleRowSelect = (id: number) => {
    setCurrentBranchID(id);
  };
  const handleRowDelete = (id: number) => {
    setDeletedID(id);
    setShowDeleteModel(true);
  };
  const isArabic = isArabicCurrentLanguage();
  //#endregion
  //#region functions
  const handleActions = (o: RequestActionModel) => {
    switch (o.action) {
      case ActionTypeEnum.Update:
        handleRowSelect(o.request.ID);
        break;
      case ActionTypeEnum.Delete:
        handleRowDelete(o.request.ID);
        break;
    }
  };
  //#endregion
  //#region variables
  const deleteActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        setIsDeleting(true);
      const deleteResponse=  await deleteBranch(deletedID as number);
        const isSuccess: boolean = !(
            deleteResponse.Errors != null &&
            deleteResponse.Errors.length !== 0
        );
        setIsDeleting(false);
        setShowDeleteModel(false);
        if (isSuccess) {
          await fetchBranches();
        }else{
          onActionEvent({action:ActionTypeEnum.Failed,request:deleteResponse.Errors});
        }
      },
    },
    {
      text: getLabelName("no"),
      onClick: () => {
        setShowDeleteModel(false);
      },
    },
  ];
  //#endregion
  //#region html
  return (
    <>
      <ConfirmModelDialogBox
        isModelVisible={showDeleteModel}
        onCloseEvent={() => {
          setShowDeleteModel(false);
          setDeletedID(null);
        }}
        actions={deleteActions}
        isLoading={isDeleting}
      >
        {" "}
        <div className="alert alert-warning">
          {getLabelName("Are You Sure You Want Delete")}
        </div>
      </ConfirmModelDialogBox>
      <LoadingBox isLoading={branchesLoading || isTableLoading}>
        <PrivilegesChecker formId={PageEnum.Branches} action="EnableSearch">
          <SearchBox
            onChangeHandler={onChange}
            onSearchHandler={onSearch}
            searchedWord={value}
            name="branch"
          />
        </PrivilegesChecker>
        <GridTable
          columnsProps={{
            actions: [ActionTypeEnum.Update, ActionTypeEnum.Delete],
            onActionEvent:handleActions,
            isArabic,
          }}
          data={branches.filter(
            ({ ArabicName, EnglishName }) =>
              ArabicName.includes(searchedWord) ||
              EnglishName?.includes(searchedWord)
          )}
          formId={PageEnum.Branches}
          currentPage={1}
          pageSize={10}
          paginationType="client"
          totalRows={branches.length}
        />
      </LoadingBox>
    </>
  );
  //#endregion
};
