import React from 'react';
import PropTypes from 'prop-types';
import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from 'react-bootstrap';
import { generateGuid } from '../../../utils';
import { ReactI18NextChild } from 'react-i18next';

const ICON_TYPES = [
    'account-plus',
    'account-search',
    'brightness-5',
    'cash-multiple',
    'check-circle',
    'close-circle',
    'content-save',
    'credit-card',
    'dots-horizontal',
    'delete',
    'home-variant',
    'magnify',
    'pencil-box',
    'percent',
    'plus-circle',
    'plus',
    'printer',
    'receipt',
    'refresh',
    'replay',
    'send',
    'custom',
] as const;

type IconType = typeof ICON_TYPES[number];

interface CustomButtonProps extends BootstrapButtonProps {
    children?: ReactI18NextChild | Iterable<ReactI18NextChild>;
    className?: string;
    iconType?: IconType;
    extraIconClass?: string;
    id?: string;
}

export const ButtonBox = React.forwardRef<HTMLButtonElement, CustomButtonProps>(
    ({ children, className = '', iconType, extraIconClass, id, ...rest }, ref) => {
        const buttonId = id || `button_id_${generateGuid()}`;

        return (
            <BootstrapButton {...rest} id={buttonId} className={className} ref={ref}>
                {iconType && (
                    <i className={`mdi mdi-${iconType} ${extraIconClass ? extraIconClass : ''}`} />
                )}
                {children}
            </BootstrapButton>
        );
    }
);

// Custom PropTypes validator for ReactI18NextChild
const ReactI18NextChildPropType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.element,
    PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.element])
    ),
]);

ButtonBox.propTypes = {
    children: ReactI18NextChildPropType.isRequired,
    className: PropTypes.string,
    iconType: PropTypes.oneOf(ICON_TYPES),
    extraIconClass: PropTypes.string,
    id: PropTypes.string,
    variant: PropTypes.string, // PropType for the BootstrapButton variant
};
