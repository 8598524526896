import { Dropdown } from "react-bootstrap";
import React, {useEffect, useRef} from "react";
import { FaEllipsisV } from "react-icons/fa";
import { HasChildren } from "../box/loadingBox";
import {isArabicCurrentLanguage} from "../../../utils";

const CustomToggle = React.forwardRef(
  (
    {
      children,
      onClick,
    }: {
      children: React.ReactNode;
      onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    },
    ref: any
  ) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick?.(e);
      }}
    >
      {children}
    </a>
  )
);

interface CustomDropdownProps extends HasChildren {
  dropdownIcon: {
    color: string;
    size: number;
  };
  style?: React.CSSProperties;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  children,
  style,
  dropdownIcon: { color, size },
}) => {
    const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
    //region useEffect
    useEffect(() => {
        // Dynamically adjust dropdown position for RTL/LTR
        const updateDropdownPosition = () => {
            const dropdownMenu = dropdownMenuRef.current;
            if (dropdownMenu) {
                const isRTL = isArabicCurrentLanguage();
                dropdownMenu.style.left = isRTL ? "unset" : "20px";
                dropdownMenu.style.right = isRTL ? "20px" : "unset";
            }
        };

        updateDropdownPosition();

        // Listen to culture/language changes
        window.addEventListener("resize", updateDropdownPosition);
        return () => window.removeEventListener("resize", updateDropdownPosition);
    }, []);
    //#endregion
    //#region html
    return (
        <Dropdown style={style} drop="up">
            <Dropdown.Toggle as={CustomToggle}>
                <FaEllipsisV size={size} fill={color}/>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{zIndex: 1000}} className="position-fixed">
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
    //#endregion
};

export default CustomDropdown;
