import React, { FC } from "react";
import {
    LookupItemModel,
    LookupTypeEnum,
    SearchPanelPropsModel, CustomersTransactionAndAcTransactionSearchParamsModel
} from "../../../models";
import { Form } from "react-bootstrap";
import { getLabelName } from "../../../utils";
import { InputDatePicker, SelectBox, ButtonBox, PrivilegesChecker } from "../..";
import { useLookups } from "../../../hooks";

export const ReportCustomersTransactionAndAcTransactionSearchPanel: FC<
    SearchPanelPropsModel<CustomersTransactionAndAcTransactionSearchParamsModel>
> = ({
    setLoading, formId,
    handelSearch,
    searchParams,
    setSearchParams,
}) => {
        //#region state
        const { getLookupItems } = useLookups(setLoading, [
            LookupTypeEnum.Customers,
            LookupTypeEnum.Currency,
            LookupTypeEnum.CostCenters,
            LookupTypeEnum.Users,
            LookupTypeEnum.Employee,
            LookupTypeEnum.Cities,
        ]);
        //#endregion
        //#region html
        return (
            <>
                <Form>
                    <div
                        className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
                        <InputDatePicker
                            selectedDate={searchParams.fromDate}
                            isClearable
                            className="form-control"
                            InputLabel={"From Date"}
                            selectsStart
                            startDate={searchParams.fromDate}
                            endDate={searchParams.toDate}
                            onChange={(date) => {
                                const obj = { ...searchParams };
                                obj.fromDate = date === null ? undefined : date;
                                setSearchParams(obj);
                            }}
                        />
                        <InputDatePicker
                            selectedDate={searchParams.toDate}
                            isClearable
                            InputLabel={"To Date"}
                            className="form-control"
                            selectsEnd
                            startDate={searchParams.fromDate}
                            endDate={searchParams.toDate}
                            minDate={searchParams.fromDate}
                            onChange={(date) => {
                                const obj = { ...searchParams };
                                obj.toDate = date === null ? undefined : date;
                                setSearchParams(obj);
                            }}
                        />
                        <SelectBox
                            labelName="Customer"
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.customerId
                                    ? [searchParams.customerId.toString()]
                                    : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.Customers, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = { ...searchParams };
                                if (e == null) {
                                    obj.customerId = undefined;
                                } else {
                                    obj.customerId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                        <SelectBox
                            labelName="User"
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.userId ? [searchParams.userId.toString()] : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.Users, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = { ...searchParams };
                                if (e == null) {
                                    obj.userId = undefined;
                                } else {
                                    obj.userId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                        <SelectBox
                            labelName={getLabelName("Currency")}
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.currencyId
                                    ? [searchParams.currencyId.toString()]
                                    : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.Currency, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = { ...searchParams };
                                if (e == null) {
                                    obj.currencyId = undefined;
                                } else {
                                    obj.currencyId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                        <SelectBox
                            labelName={getLabelName("Cost Centers")}
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.costCenterId
                                    ? [searchParams.costCenterId.toString()]
                                    : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.CostCenters, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = { ...searchParams };
                                if (e == null) {
                                    obj.costCenterId = undefined;
                                } else {
                                    obj.costCenterId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                        <SelectBox
                            labelName="Employee"
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.employeeId
                                    ? [searchParams.employeeId.toString()]
                                    : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.Employee, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = { ...searchParams };
                                if (e == null) {
                                    obj.employeeId = undefined;
                                } else {
                                    obj.employeeId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                        <SelectBox
                            labelName="City"
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.cityId ? [searchParams.cityId.toString()] : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.Cities, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = { ...searchParams };
                                if (e == null) {
                                    obj.cityId = undefined;
                                } else {
                                    obj.cityId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                    </div>
                    <PrivilegesChecker formId={formId} action="EnableSearch">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                            <ButtonBox
                                iconType="magnify"
                                className="btn-gradient-primary mx-3 btn-fw"
                                variant="outline-primary"
                                onClick={handelSearch}
                            >
                                {getLabelName("Search")}
                            </ButtonBox>
                        </div>
                    </PrivilegesChecker>
                </Form>
            </>
        );
        //#endregion
    };
