import React, {
  FC,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import { useFormik } from "formik";
import {
  ActionTypeEnum,
  ItemModel,
  RequestActionModel,
  LookupItemModel,
  RowStateEnum,
  PageEnum, CompanySettingModel,
} from "../../models";
import {
  LoadingBox,
  ButtonBox,
  ItemImageManager,
  ItemGatherManager,
  PrivilegesChecker, ItemUnitManager, ItemModifierManager, ItemOptionsManager, ItemDetailsManager,
} from "..";
import {
  getLabelName,
  validateNoSpaces,
  validateRequired,
  yubCombineValidationsManager
} from "../../utils";
import { Accordion } from "react-bootstrap";
import {
  itemInitialValues,
  handleSubmit,
  handleInitializeComponent,
  handleRefreshItemComponent,
  setCompanySettingsInitialValues,
} from "./businessLogic/itemMiscellaneousBl";
import {
  useItemConfigs,
} from "../../hooks";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import { getItemImageById } from "../../serviceBroker/itemApiServiceBroker";
import * as Yup from "yup";
import {generateItemStore} from "./businessLogic/itemStoresBl";

export const ItemRegistrationManager: FC<{
  request?: ItemModel | null;
  isRefresh: boolean;
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  onActionEvent: (o: RequestActionModel) => void;
  isFormLoading: boolean;
  setFormLoading: Dispatch<SetStateAction<boolean>>;
}> = ({
  request,
  isRefresh = false,
  setIsRefresh,
  onActionEvent = () => {},
  isFormLoading,
  setFormLoading,
}) => {
  //#region state
  const [isImageLoading, setIsImageLoading] = useState(false);
  const { itemConfigs } = useItemConfigs();
  const [item, setItem] = useState<ItemModel>(request ?? itemInitialValues);
  const [isImageAccordionExpanded, setIsImageAccordionExpanded] =
    useState(false);
  const isItemImageLoadedBefore = useRef(false);
  const [unitList, setUnitList] = useState<LookupItemModel[]>([]);
  const [_, setDefaultUnitId] = useState(0);
  const [categoryList, setCategoryList] = useState<LookupItemModel[]>([]);
  const [companySettings,setCompanySettings]=useState<CompanySettingModel>(setCompanySettingsInitialValues);
  const[isRefreshUnitManager,setIsRefreshUnitManager]=useState<boolean>(false);
  const[isRefreshOptionsManager,setIsRefreshOptionsManager]=useState<boolean>(false);
  const[isRefreshGatherManager,setIsRefreshGatherManager]=useState<boolean>(false);
  const categorySelectBoxMultiselectRef = useRef<any>();
  const [validationSchema] = useState(
      Yup.object({
        Name: yubCombineValidationsManager([
          validateRequired(),
          validateNoSpaces(),
        ]),
        Cat_ID: Yup.number()
            .test('cat-id', '', function (value) {
              const { createError } = this;
              if (value === undefined || value === null  || value <= 0) {
                return createError({
                  message: getLabelName("categoryId.missing"),
                });
              }
              return true;
            })
      })
  );
  //#endregion
  //#region formik
  const formik = useFormik<ItemModel>({
    initialValues: {... request ?? itemInitialValues,isNewItem:!(request !== null && request !== undefined)} , //{ ...setItemInitialValues, TaxValue: 0  },//companySettings?.DefaultTaxPercentage??0
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
   // validationSchema: validationSchema,
    // validate,
    onSubmit: async (values,{ setSubmitting }) => {
      setFormLoading(true);
      const updatedValues = {
        ...values,
        ItemsInstores: item.ItemsInstores,
        HasImage: values.ItemImages !== null,
      };
      const itemResponse = await handleSubmit(updatedValues);
      setFormLoading(false);
      if (itemResponse.Errors===null || itemResponse.Errors===undefined||itemResponse.Errors.length===0) {
        await handleReset();
        onActionEvent({
          request: itemResponse.Result,
          action: ActionTypeEnum.Success,
        });
      } else {

        onActionEvent({
          id: 0,
          request: itemResponse.Errors,
          action: ActionTypeEnum.RaiseError,
        });
      }
    },
  });
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setFormLoading(true);
      await handleInitializeComponent(formik,
          setFormLoading,
          setUnitList,
          setCategoryList,
          setCompanySettings,
          setDefaultUnitId
      );
      setFormLoading(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    if (isRefresh) {
      const fillData = async () => {
        setFormLoading(true);
        await  handleRefreshItemComponent(
            formik,
            setItem,
            setIsRefresh,
            request??itemInitialValues,
        )
        setIsRefresh(false);
        setIsImageAccordionExpanded(false);
        isItemImageLoadedBefore.current = false;
        setFormLoading(false);
      }
      fillData().then(() => {});
    }
  }, [isRefresh]);
  useEffect(() => {
    const fillData = async () => {
      if (
          isItemImageLoadedBefore.current ||
          !isImageAccordionExpanded ||
          !request?.ID
      )
        return;
      setFormLoading(false);
      await handleGetImage();
      isItemImageLoadedBefore.current = true;
      setFormLoading(false);
    }
    fillData().then(() => {});
  }, [isImageAccordionExpanded]);
  //#endregion
  //#region function
  const handleSetRefreshStates=async (isRefreshed:boolean)=>{
    setIsRefreshOptionsManager(isRefreshed);
    setIsRefreshGatherManager(isRefreshed);
    setIsRefreshUnitManager(isRefreshed);
  }
  const handleRequestSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
    setFormLoading(true);
    e.preventDefault(); // Prevent default form submission
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      await formik.setTouched(
          Object.keys(formik.initialValues).reduce(
              (acc, field) => ({...acc, [field]: true}),
              {}
          )
      ); // Mark all fields as touched
      // const validationErrors: ValidationErrorModel[] = Object.entries(errors).map(([key, message]) => {
      //   return {
      //     MessageAr: message as string, // Assert message as a string
      //     MessageEn: message as string
      //   };
      // });
      // onActionEvent({
      //   id: 0,
      //   request: validationErrors,
      //   action: ActionTypeEnum.RaiseError,
      // });
      onActionEvent({action: ActionTypeEnum.ScrollToTop})
      setFormLoading(false)
      return;
    }
    setFormLoading(false)
    formik.handleSubmit(e);
  };
  const handleClearControls=()=>{
    categorySelectBoxMultiselectRef.current && categorySelectBoxMultiselectRef.current.clearValue();
  };
  const handleReset = async () => {
    setFormLoading(true);
    const _item:ItemModel={
      ...itemInitialValues,
      ItemsInstores:generateItemStore(),
      TaxValue: companySettings?.DefaultTaxPercentage ?? 0,
      isNewItem: true
    }
    await formik.setValues(_item);
    setItem(_item);
    handleClearControls();
    formik.resetForm();
    await handleSetRefreshStates(true);
    setFormLoading(false);
  };
  const handleGetImage = async () => {
    setIsImageLoading(true);
    const imageData = await getItemImageById(request?.ID!);
    if (!imageData.Result?.FileBinary) return;
    await formik.setFieldValue("ItemImages", {
      File64Bit: imageData.Result.FileBinary,
      ID: imageData.Result.ID,
      ImageDescription: imageData.Result.FileDescription,
      ImageName: imageData.Result.Name,
      Name: imageData.Result.Name,
      rowState: RowStateEnum.Update,
    });
    setIsImageLoading(false);
  };
  //#endregion
  //#region html
  return (
    <>
      <LoadingBox isLoading={isFormLoading}>
        <form className="forms-sample" onSubmit={handleRequestSubmit}>
          <div
              className="col-12 col-md-2 gap-3 d-flex justify-content-end sticky-buttons"
              style={{
                position: "sticky",
                top: 0, // Sticks to the top of the viewport
                zIndex: 1000, // Ensures it appears above other elements
                //background: "white", // Optional: Ensure visibility
                padding: "10px", // Optional: Add padding for spacing
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Optional: Add shadow for separation
              }}
          >
            <PrivilegesChecker
                formId={PageEnum.Items}
                action={request?.ID ? "EnableUpdate" : "EnableSave"}
            >
              <ButtonBox
                  iconType="content-save"
                  type="submit"
                  className="btn  btn-sm mx-2"
                  variant="primary"
              >
                {getLabelName(request?.ID ? "Update" : "Save")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
                iconType="receipt"
                variant="danger"
                type="button"
                className="btn btn-sm"
                onClick={() => {
                  handleReset();
                  onActionEvent({
                    action: ActionTypeEnum.Clear,
                  });
                }}
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{getLabelName("Items")}</Accordion.Header>
              <Accordion.Body>
                <ItemDetailsManager formik={formik} item={item} categoryList={categoryList} itemConfigs={itemConfigs}
                                    categorySelectBoxMultiselectRef={categorySelectBoxMultiselectRef}/>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{getLabelName("Units")}</Accordion.Header>
              <Accordion.Body className="overflow-visible">
                <ItemUnitManager setState={setItem} state={item} taxValue={formik.values.TaxValue ?? 0} unitList={unitList}
                                 onActionEvent={() => {}} setIsRefresh={setIsRefreshUnitManager} isRefresh={isRefreshUnitManager}/>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {itemConfigs.ShowModifiers && (
              <Accordion>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>{getLabelName("Modifiers")}</Accordion.Header>
                  <Accordion.Body>
                    <ItemModifierManager setState={setItem} state={item} onActionEvent={() => {
                    }}/>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
          )}
          <Accordion>
            <Accordion.Item eventKey="3">
              <Accordion.Header>{getLabelName("Item Option")}</Accordion.Header>
              <Accordion.Body className="overflow-visible">
                <ItemOptionsManager formik={formik}
                                    itemConfigs={itemConfigs}
                                    isRefresh={isRefreshOptionsManager}
                                    setIsRefresh={setIsRefreshOptionsManager}/>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {(itemConfigs.ShowGatherItem || itemConfigs.ShowproductionItem) && (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    {getLabelName("Gather Item")}
                  </Accordion.Header>
                  <Accordion.Body className="overflow-visible">
                    <ItemGatherManager
                        categoryList={categoryList}
                        setState={setItem}
                        state={item}
                        taxValue={formik.values.TaxValue ?? 0}
                        setIsRefresh={setIsRefreshGatherManager}
                        isRefresh={isRefreshGatherManager}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
          )}
          {
            <Accordion
                onSelect={(activeKey: AccordionEventKey) =>
                    setIsImageAccordionExpanded(activeKey !== null)
                }
                activeKey={isImageAccordionExpanded ? "3" : null}
            >
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  {getLabelName("Image Item")}
                </Accordion.Header>
                <Accordion.Body
                    className="overflow-visible position-relative"
                    style={{minHeight: 200}}
                >
                  <ItemImageManager
                      isImageLoading={isImageLoading}
                      image={formik.values.ItemImages}
                      deleteImage={() => {
                        if (!request?.ID)
                          return formik.setFieldValue("ItemImages", null);
                        formik.setFieldValue("ItemImages", {
                          ...formik.values.ItemImages,
                          rowState: RowStateEnum.Delete,
                        });
                      }}
                      setFileBase64={(base64: string | null) => {
                        if (base64 === null)
                          return formik.setFieldValue("ItemImages", null);
                        formik.setFieldValue("ItemImages", {
                          File64Bit: base64,
                          ID: request?.ItemImages?.ID
                              ? request?.ItemImages?.ID
                              : 0,
                          ImageDescription: `${formik.values.Name} item`,
                          ImageName: `${formik.values.Name} item`,
                          Name: formik.values.Name,
                          rowState: request?.ID
                              ? RowStateEnum.Update
                              : RowStateEnum.Add,
                        });
                      }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          }
        </form>
      </LoadingBox>
    </>
  );
//#endregion
};
