import React, {FormEvent} from "react";
import {SearchBoxProps} from "../../../models";
import {getLabelName} from "../../../utils";
import {TextBox} from "../textBox/textBox";
import {ButtonBox} from "../button/button";

export const SearchBoxGeneric: React.FC<SearchBoxProps> = ({
                                                        searchFields,
                                                        onChangeHandler,
                                                        onSearchHandler,
                                                        onResetHandler
                                                    }) => {
    return (
        <div className="d-flex mb-4 flex-wrap align-items-center">
            {searchFields.map((field) => (
                <div key={field.key} className="me-3 d-flex align-items-center" style={{width: 290}}>
                    <TextBox
                        inputName={field.key}
                        inputValue={field.value}
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            onChangeHandler(field.key, e.currentTarget.value)
                        }
                        labelName={getLabelName(field.label)}
                        placeHolder={getLabelName(field.placeholder || field.key)}
                        controlSize="w-75"
                    />
                </div>
            ))}
            <div className="d-flex">
                <ButtonBox iconType="magnify"
                           onClick={onSearchHandler}
                           size="sm" // Reduced button size
                >
                    {getLabelName("search")}
                </ButtonBox>
                <ButtonBox
                    iconType="close-circle"
                    onClick={onResetHandler}
                    size="sm" // Reduced button size
                >
                    {getLabelName("clear")}
                </ButtonBox>
            </div>
        </div>
    );
};