import { FC, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import { ActionButtonsModel } from "../../../models";
import { getLabelName, isArabicCurrentLanguage } from "../../../utils";
import { ButtonBox, LoadingBox } from "../..";

export const ConfirmModelDialogBox: FC<{
  isModelVisible: boolean;
  title?: string;
  //body?: string;
  onCloseEvent?: any;
  actions?: ActionButtonsModel[] | null;
  children?: ReactNode | undefined;
  isLoading?: boolean;
}> = ({
  isModelVisible,
  title,
  //body,
  onCloseEvent,
  actions,
  children,
  isLoading,
}) => {
  //#region variables
  const isArabic: boolean = isArabicCurrentLanguage();
  const direction: string = isArabic ? "rtl" : "ltr";
  const isHiddenEnabled: boolean =
    onCloseEvent !== null && onCloseEvent !== undefined;
  //#endregion
  //#region html
  return (
    <>
      <Modal
        show={isModelVisible}
        onHide={() => {
          isHiddenEnabled && onCloseEvent();
        }}
      >
        <LoadingBox isLoading={isLoading}>
          <Modal.Header dir={direction}>
            {title && <Modal.Title>{title}</Modal.Title>}
          </Modal.Header>
          {/*<Modal.Body dir={direction}>{children}</Modal.Body>*/}
          <Modal.Body dir={direction}>
            {children ? (
              children
            ) : (
              <div className="alert alert-warning">
                {getLabelName("Are You Sure You Want Delete ")}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer dir={direction}>
            {
              // @ts-ignore
              actions !== null &&
                actions !== undefined &&
                actions.length > 0 &&
                actions.map((row, index) => {
                  return (
                    <ButtonBox
                      id={`button_action_` + index}
                      key={index}
                      size="sm"
                      variant={(row.variant = row.variant ?? "primary")}
                      onClick={() => {
                        row.onClick();
                      }}
                    >
                      {row.text}
                    </ButtonBox>
                  );
                })
            }
          </Modal.Footer>
        </LoadingBox>
      </Modal>
    </>
  );
  //#endregion
};
