import {FC, useEffect, useState} from "react";
import {
  isArabicCurrentLanguage,
} from "../../utils";
import {
    ActionTypeEnum,
    HasFormIdModel, PageEnum,
    StoreResponseModel,
} from "../../models";
import {GridTable, LoadingBox, PrivilegesChecker, SearchBox} from "..";
import {useSearchBox} from "../../hooks";
import {getStores} from "../../serviceBroker/storeApiServiceBroker";

interface StoreListProps extends HasFormIdModel {
    isRefresh: boolean;
  onActionEvent?: any | null;
}

export const StoresList: FC<StoreListProps> = ({isRefresh=false,
  onActionEvent,
  formId,
}) => {
    //#region variable
    const defaultPageSize: number = 50;
    const isArabic = isArabicCurrentLanguage();
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<StoreResponseModel[]>([]);
    const [filteredData, setFilteredData] = useState<StoreResponseModel[]>([]);
    const {onChange, onSearch, searchedWord, value} = useSearchBox();
    //#endregion
    //#region function
    const getAllStores = async () => {
        setLoading(true);
        const result = await getStores();
        setData(result == null ? data : result);
        setFilteredData(result == null ? data : result);
        setLoading(false);
    };
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            await getAllStores();
        };
        fillData().then(() => {});
    }, []);
    useEffect(() => {
        if (isRefresh) {
            const fillData = async () => {
                await getAllStores();
            };
            fillData().then(() => {});
            onActionEvent({action:ActionTypeEnum.Refresh,request:false});
        }
    }, [isRefresh]);
    useEffect(() => {
        const searchValue = searchedWord && searchedWord.trim() !== "" ? searchedWord : value;
        if (!searchValue.trim()) {
            setFilteredData(data);
        } else {
            // Filter based on Arabic or English name
            setFilteredData(
                data.filter(
                    (p) =>
                        p.Name.toLowerCase().trim().includes(searchValue.toLowerCase().trim()) || // Match Arabic name
                        p.Name_En.toLowerCase().trim().includes(searchValue.toLowerCase().trim()) // Match English name
                )
            );
        }
    }, [searchedWord,value]);
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            <PrivilegesChecker formId={PageEnum.Stores} action="EnableSearch">
                <SearchBox
                    onChangeHandler={onChange}
                    onSearchHandler={onSearch}
                    searchedWord={value}
                    name="name"
                />
            </PrivilegesChecker>
            <GridTable
                data={filteredData}
                totalRows={data.length}
                paginationType={"client"}
                currentPage={1}
                pageSize={defaultPageSize}
                columnsProps={{
                    actions: [
                        ActionTypeEnum.Update,
                        ActionTypeEnum.Delete,
                    ],
                    isArabic: isArabic,
                    onActionEvent: onActionEvent,
                }}
                formId={formId}
            />
        </>
    );
    //#endregion
};
