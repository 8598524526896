import * as Yup from "yup";
import {FromValidationResponseModel} from "../../models";
/**
 * Combines multiple validation functions into a single Yup.StringSchema.
 * @param validations - Array of validation functions.
 * @returns A Yup schema that applies the validations in sequence.
 */
export const yubCombineValidationsManager = (
    validations: Array<(value: any) => FromValidationResponseModel>
): Yup.StringSchema<string | undefined, Record<string, any>> => {
    return Yup.string().test({
        name: "combined-validations",
        test: function (value) {
            for (const validation of validations) {
                const result = validation(value); // Call the validation function
                if (!result.valid) {
                    // Return the error message of the first failed validation
                    return this.createError({ message: result.message });
                }
            }
            return true; // All validations passed
        },
    });
};
