import { FC } from "react";
import { LookupFormTypeEnum, LookupTypeEnum, PageEnum } from "../../models";
import { LookupPage } from "./lookupPage";

export const CitiesLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.Cities}
      formId={PageEnum.Cities}
      lookupCacheEnum={LookupTypeEnum.Cities}
    />
  );
};
export const CountriesLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.Country}
      formId={PageEnum.Countries}
      lookupCacheEnum={LookupTypeEnum.Country}
    />
  );
};
export const CostCenterLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.CostCenter}
      formId={PageEnum.CostCenters}
      lookupCacheEnum={LookupTypeEnum.CostCenter}
    />
  );
};
export const AdditionAndRecommendationRestaurantLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.AdditionAndRecommendationRestaurant}
      formId={PageEnum.AdditionalRecommendOfItemsInRestaurant}
    />
  );
};
export const GradesLookupPage: FC = () => {
  return (
    <LookupPage lookupType={LookupFormTypeEnum.Grade} formId={PageEnum.Grade} />
  );
};
export const NationalitiesLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.Nationalities}
      formId={PageEnum.Nationalities}
    />
  );
};
export const ReligionsLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.Religions}
      formId={PageEnum.Religions}
    />
  );
};
export const SocialStatusLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.SocialStatus}
      formId={PageEnum.SocialStatus}
    />
  );
};
export const EmployeesStatusLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.Employee_Status}
      formId={PageEnum.EmployeeStatus}
    />
  );
};
export const OrdersStatusLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.OrderStatus}
      formId={PageEnum.ConsignOut}
      lookupCacheEnum={LookupTypeEnum.OrderStatus}
    />
  );
};
export const CarsTypesLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.Cars_Type}
      formId={PageEnum.CarsType}
      lookupCacheEnum={LookupTypeEnum.Cars_Type}
    />
  );
};
export const CarsColorsLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.Cars_Color}
      formId={PageEnum.CarsColors}
      lookupCacheEnum={LookupTypeEnum.Cars_Color}
    />
  );
};
export const CarsServicesLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.Cars_Service}
      formId={PageEnum.CarService}
      lookupCacheEnum={LookupTypeEnum.Cars_Service}
    />
  );
};
export const AdditionCostsLookupPage: FC = () => {
  return (
    <LookupPage
      lookupType={LookupFormTypeEnum.AdditionCosts}
      formId={PageEnum.AdditionCosts}
    />
  );
};
