import React, { Dispatch, SetStateAction } from "react";
import { TableColumn } from "react-data-table-component";
import {
  ActionTypeEnum,
  ChangeItemValueTypeEnum,
  DailyTransactionTypeEnum,
  RequestActionModel,
  TransactionDetailResponseModel,
  TransactionItemResponseModel,
  UserTransactionPermissionRequestModel,
  RowStateEnum,
} from "../../../../models";
import { getLabelName, roundNumber } from "../../../../utils";
import { smallBtnStyle } from "../css/posCss";
import { handleChangeQuantityButton } from "../businessLogic/posTransactionBl";
import {
  handleCalculateExistItemValuesChange,
  UpdateTransactionItemStateOnChange,
} from "../businessLogic/commonBl";
import { ConditionalStyles } from "react-data-table-component";
import { ButtonBox } from "../../..";
import { handleTransactionTotalValues } from "../businessLogic/transactionBl";
//#region pos columns
export const getPosTransactionItemsColumns = (
  isArabic: boolean,
  transactionType: DailyTransactionTypeEnum,
  setState: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  stateValue: TransactionDetailResponseModel,
  setTransactionPermissionState: Dispatch<
    SetStateAction<UserTransactionPermissionRequestModel>
  >,
  transactionPermissionValue: UserTransactionPermissionRequestModel,
  onActionEvent: (o: RequestActionModel) => void
): TableColumn<TransactionItemResponseModel>[] => {
  const isRefund = isRefundTransaction(transactionType);
  let columns: TableColumn<TransactionItemResponseModel>[] = [
    {
      name: (
        <label style={{ width: "fit-content" }}>{getLabelName("item")}</label>
      ),
      selector: (row) => (isArabic ? row.ItemName : row.ItemName_En),
      sortable: true,
    },
    {
      name: getLabelName("Quantity"),
      cell: (row: TransactionItemResponseModel, index) => (
        <>
          <ButtonBox
            onClick={async () => {
              const isReserved =
                stateValue.TransactionType ===
                DailyTransactionTypeEnum.Reserve_Restaurant_Bill;
              const isAddingOnPreviousOrder =
                isReserved && row.rowState !== RowStateEnum.Add;
              const newlyAddedSibling = stateValue.TransactionDetaillist.find(
                (item) =>
                  item.rowState === RowStateEnum.Add &&
                  item.ItemUnit_ID === row.ItemUnit_ID
              );
              if (isAddingOnPreviousOrder && newlyAddedSibling === undefined) {
                const addedList = [
                  ...stateValue.TransactionDetaillist,
                  {
                    ...row,
                    rowState: RowStateEnum.Add,
                    Quantity: 1,
                    TotalInCurrency: row.Unit_PriceInCurrencyCalculated,
                  },
                ];
                const stateObject = handleTransactionTotalValues({
                  ...stateValue,
                  TransactionDetaillist: addedList,
                });
                return setState(stateObject);
              }
              if (newlyAddedSibling !== undefined) row = newlyAddedSibling;
              const qty =
                row.Quantity + 1 > row.defaultQuantity
                  ? row.defaultQuantity
                  : row.Quantity + 1;
              await handleChangeQuantityButton(setState, stateValue, {
                ...row,
                changeValueType: ChangeItemValueTypeEnum.None,
                Quantity: qty,
              });
            }}
            className="ms-2"
            value="increase"
            size="sm"
            id={`increase_btn_${index}`}
            variant="success"
            style={smallBtnStyle}
          >
            +
          </ButtonBox>{" "}
          <input
            type="number"
            className="form-control"
            key={`quantity_key_${index}`}
            value={row.Quantity}
            name="quantity"
            onWheel={(event) => {
              event.currentTarget.blur();
            }}
            onChange={async (e) => {
              let val = e.target.value === null ? 0 : Number(e.target.value);
              val = val > row.defaultQuantity ? row.defaultQuantity : val;
              await UpdateTransactionItemStateOnChange(setState, stateValue, {
                ...row,
                changeValueType: ChangeItemValueTypeEnum.None,
                Quantity: val,
              });
            }}
            onBlur={async () => {
              await handleCalculateExistItemValuesChange(
                setState,
                stateValue,
                row
              );
            }}
          />
          <ButtonBox
            onClick={async () => {
              //handleInputQuantityChangePos(e,setTransactionData,transactionData, row)}
              await handleChangeQuantityButton(setState, stateValue, {
                ...row,
                changeValueType: ChangeItemValueTypeEnum.None,
                Quantity: row.Quantity === 0 ? 0 : row.Quantity - 1,
              });
            }}
            className="me-2"
            value="decrease"
            id={`decrease_btn_${index}`}
            size="sm"
            variant="danger"
            style={smallBtnStyle}
          >
            -
          </ButtonBox>
        </>
      ),
      width: "200px",
    },
    {
      name: getLabelName("Price"),
      id: "price_column",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`price_key_${index}`}
          value={row.Unit_Price}
          name="priceSale"
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e) => {
            //handleInputQuantityPriceChangePos(e,setTransactionData,transactionData, row)
            const val = e.target.value === null ? 0 : Number(e.target.value);
            await UpdateTransactionItemStateOnChange(setState, stateValue, {
              ...row,
              changeValueType: ChangeItemValueTypeEnum.ChangePrice,
              Unit_Price: val,
            });
          }}
          onBlur={async () => {
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Price"),
      id: "price_inCurrency_column",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`price_in_currency_key_${index}`}
          readOnly={isRefund}
          value={row.Unit_PriceInCurrencyCalculated}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            if (
              transactionPermissionValue.isPosPriceEditable ||
              transactionPermissionValue.isTransactionPriceEditable
            ) {
              await UpdateTransactionItemStateOnChange(setState, stateValue, {
                ...row,
                changeValueType: ChangeItemValueTypeEnum.ChangePriceInCurrency,
                Unit_PriceInCurrencyCalculated: val,
              });
            } else {
              const rowObj: TransactionItemResponseModel = {
                ...row,
                changeValueType: ChangeItemValueTypeEnum.ChangePriceInCurrency,
              };
              onActionEvent({
                request: rowObj,
                action: ActionTypeEnum.EditTransactionItemPrice,
              });
            }
          }}
          onBlur={async () => {
            if (transactionPermissionValue.isTransactionPriceEditable) {
              setTransactionPermissionState({
                ...transactionPermissionValue,
                isTransactionPriceEditable: false,
              });
            }
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Price_With_Tax"),
      id: "price_with_tax_column",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`price_with_tax_key_${index}`}
          readOnly={isRefund}
          value={row.UnitPriceWithTaxCalculated}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            await UpdateTransactionItemStateOnChange(setState, stateValue, {
              ...row,
              changeValueType: ChangeItemValueTypeEnum.ChangePriceWithTax,
              UnitPriceWithTaxCalculated: val,
            });
          }}
          onBlur={async () => {
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Price_With_Tax"),
      id: "price_with_tax_inCurrency_column",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`price_with_tax_inCurrency_key_${index}`}
          readOnly={isRefund}
          value={row.UnitPriceWithTaxInCurrency}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            await UpdateTransactionItemStateOnChange(setState, stateValue, {
              ...row,
              changeValueType:
                ChangeItemValueTypeEnum.ChangePriceWithTaxInCurrency,
              UnitPriceWithTaxInCurrency: val,
            });
          }}
          onBlur={async () => {
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Discount"),
      id: "discount_colum",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`discount_key_${index}`}
          readOnly={isRefund}
          value={row.ItemDiscount}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            await UpdateTransactionItemStateOnChange(setState, stateValue, {
              ...row,
              changeValueType: ChangeItemValueTypeEnum.None,
              ItemDiscount: val,
            });
          }}
          onBlur={async () => {
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Discount"),
      id: "discount_inCurrency_colum",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`discount_inCurrency_key_${index}`}
          readOnly={isRefund}
          value={row.ItemDiscountInCurrency}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            if (
              transactionPermissionValue.isPosDiscountEditable ||
              transactionPermissionValue.isTransactionDiscountEditable
            ) {
              await UpdateTransactionItemStateOnChange(setState, stateValue, {
                ...row,
                changeValueType:
                  ChangeItemValueTypeEnum.ChangeDiscountInCurrency,
                ItemDiscountInCurrency: val,
              });
            } else {
              const rowObj: TransactionItemResponseModel = {
                ...row,
                changeValueType:
                  ChangeItemValueTypeEnum.ChangeDiscountInCurrency,
              };
              onActionEvent({
                request: rowObj,
                action: ActionTypeEnum.EditTransactionItemDiscount,
              });
            }
          }}
          onBlur={async () => {
            if (transactionPermissionValue.isTransactionDiscountEditable) {
              setTransactionPermissionState({
                ...transactionPermissionValue,
                isTransactionDiscountEditable: false,
              });
            }
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Total"),
      id: "total_column",
      selector: (row) => row.Total,
      width: "100px",
    },
    {
      name: getLabelName("Total"),
      id: "total_inCurrency_column",
      selector: (row) => roundNumber(row.TotalInCurrency),
      sortable: true,
      width: "100px",
    },
    {
      name: "",
      cell: (row: TransactionItemResponseModel) => (
        <ButtonBox
          iconType="delete"
          variant="danger"
          onClick={() => {
            onActionEvent({
              request: row,
              action: ActionTypeEnum.DeleteTransactionItem,
            });
          }}
        >
          {/* {getLabelName("Delete")} */}
        </ButtonBox>
      ),
      width: "100px",
    },
  ];
  if (isRefund) {
    columns = columns.filter(
      (column) => column.id !== "price_with_tax_inCurrency_column"
    );
    columns = columns.filter(
      (column) => column.id !== "item_tax_inCurrency_column"
    );
    columns = columns.filter(
      (column) => column.id !== "item_total_tax_inCurrency_column"
    );
  }
  columns = columns.filter((colum) => colum.id !== "price_column");
  columns = columns.filter((column) => column.id !== "price_with_tax_column");
  columns = columns.filter((column) => column.id !== "discount_colum");
  columns = columns.filter((column) => column.id !== "item_tax_column");
  columns = columns.filter((column) => column.id !== "item_total_tax_column");
  columns = columns.filter((column) => column.id !== "total_column");
  return columns;
};
//#endregion
//#region Ui
export const handleRowStyle = (
  request: TransactionItemResponseModel[]
): ConditionalStyles<any>[] => {
  return [
    {
      when: (row: TransactionItemResponseModel) =>
        row == request[request.length - 1],
      style: {
        backgroundColor: "lightblue", // Apply your desired highlight style
      },
    },
  ];
};
//#endregion
//#region private
const isRefundTransaction = (
  transactionType: DailyTransactionTypeEnum
): boolean => {
  switch (transactionType) {
    case DailyTransactionTypeEnum.POSSalesRefund:
      return true;
    default:
      return false;
  }
};
//#endregion
