import React, { FC, FormEvent, useEffect, useState } from "react";
import {
  ActionTypeEnum,
  HasFormIdModel,
  CurrencyResponseModel,
  PageEnum,
} from "../../models";
import {
  checkActionPrivilege,
  getLabelName,
  getPagePrivileges,
  isArabicCurrentLanguage,
} from "../../utils";
import {
  ButtonBox,
  GridTable,
  PrivilegesChecker,
  SearchBox,
  SearchBoxGeneric,
} from "..";
import { TableColumn } from "react-data-table-component";
import { useSearchBoxGeneric } from "../../hooks";

interface CurrencyListProps extends HasFormIdModel {
  request: CurrencyResponseModel[];
  isModifyAble?: boolean | null;
  isDeleteAble?: boolean | null;
  isPermissionAble?: boolean | null;
  onActionEvent?: any | null;
  onCompleteEvent?: any | null;
}

const initialSearchFields = [
  {
    key: "name",
    value: "",
    label: "Name",
    placeholder: "Enter Name",
    triggerSearchOnChange: true,
  },
];

export const CurrencyList: FC<CurrencyListProps> = ({
  request,
  onActionEvent,
  formId,
}) => {
  //#region variable
  const isArabic: boolean = isArabicCurrentLanguage();
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [data, setData] = useState(request);
  const handleSearch = (keyword: string) => {
    if (searchedKeyword !== "")
      setData(() =>
        request.filter(
          (item) =>
            item.ArabicName.includes(keyword) ||
            item.EnglishName.includes(keyword)
        )
      );
    else setData(request);
  };

  useEffect(() => {
    setData(request);
  }, [request]);
  //#endregion
  //#region html
  return (
    <>
      <PrivilegesChecker formId={PageEnum.Currency} action="EnableSearch">
        <SearchBox
          onChangeHandler={(e: FormEvent<HTMLInputElement>) =>
            setSearchedKeyword(e.currentTarget.value)
          }
          onSearchHandler={handleSearch.bind(null, searchedKeyword)}
          searchedWord={searchedKeyword}
          name={getLabelName("name")}
        />
      </PrivilegesChecker>
      {request != null && request.length !== 0 && (
        <GridTable
          //columns={columns}
          data={data}
          totalRows={10000}
          currentPage={1}
          pageSize={1000}
          paginationType="none"
          columnsProps={{
            actions: [ActionTypeEnum.Update, ActionTypeEnum.Delete],
            isArabic: isArabicCurrentLanguage(),
            onActionEvent,
          }}
          formId={formId}
          //style={ TableActionColumnsStyleEnum.Dropdown}
          // subHeaderComponent={getPagePrivileges().search && subHeaderComponent}
        />
      )}
    </>
  );
  //#endregion
};
