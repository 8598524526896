import { FC, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  yubCombineValidationsManager,
  getLabelName, validateNoSpaces, validateRequired,
} from "../../utils";
import {
  ButtonBox,
  TextBox,
  LoadingBox,
  PrivilegesChecker,
} from "..";
import { addStore } from "../../serviceBroker/storeApiServiceBroker";
import {
  ActionTypeEnum,
  HasFormIdModel,
  RequestActionModel,
  RowStateEnum,
  StoreResponseModel,
  ValidationErrorModel,
} from "../../models";

interface AddStoreProps extends HasFormIdModel {
  request: StoreResponseModel;
  onActionEvent: (o: RequestActionModel) => void;
}
export const AddStore: FC<AddStoreProps> = ({
  request,
  onActionEvent,
  formId,
}) => {
  //#region variables
  const initialValues: StoreResponseModel = request;
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  // const [branchList, setBranchList] = useState<LookupItemModel[]>([]);
  const validationSchema = Yup.object({
    //Name: Yup.string().required(getLabelName("required")),
    Name: yubCombineValidationsManager([
        validateRequired(),
        validateNoSpaces(),
    ]),
    Name_En:  yubCombineValidationsManager([validateNoSpaces()]),
  });
  //#endregion
  // //#region useEffect
  // useEffect(() => {
  //   const fillData = async () => {
  //     const branchList = await getLookupByType(LookupTypeEnum.Branches);
  //     setBranchList(branchList);
  //   };
  //   fillData().then(() => {});
  // }, []);
  // //#endregion
  //#region function
  const handleSubmit = async (request: StoreResponseModel) => {
    try {
      setLoading(true);
      request.rowState =
        request.ID === 0
          ? Number(RowStateEnum.Add)
          : Number(RowStateEnum.Update);
      const res = await addStore(request);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        onActionEvent({
          action: ActionTypeEnum.Failed,
          requestBeforeError: request,
          request: res.Errors
        });
        setLoading(false);
      } else {
        setLoading(false);
        onActionEvent({ id: request.ID === 0?ActionTypeEnum.Add:ActionTypeEnum.Update, action: ActionTypeEnum.Success });
      }
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      onActionEvent({
        action: ActionTypeEnum.Failed,
        requestBeforeError: request,
        request: errors
      });
    }
  };
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);
      resetForm();
    },
  });
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <form className="forms-sample" onSubmit={formik.handleSubmit}>
        <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
          <TextBox
              labelName={getLabelName("Name")}
              inputName="Name"
              isMandatory={true}
              inputValue={formik.values.Name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorText={formik.errors.Name}
          />

          <TextBox
              labelName={getLabelName("Eng Name")}
              inputName="Name_En"
              inputValue={formik.values.Name_En}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorText={formik.errors.Name_En}
          />
          <TextBox
              labelName={getLabelName("Address")}
              inputName={"Address"}
              errorText={formik.errors.Address}
              inputValue={formik.values.Address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
          />
          <TextBox
              labelName={getLabelName("Phone")}
              inputName={"Phone"}
              errorText={formik.errors.Phone}
              inputValue={formik.values.Phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
          />

          {/*<SelectBox*/}
          {/*  labelName={getLabelName("Branch")}*/}
          {/*  source={branchList}*/}
          {/*  isSingleSelect={true}*/}
          {/*  errorText={formik.errors.Branch_ID}*/}
          {/*  onStatusChange={(e: any) => {*/}
          {/*    formik.values.Branch_ID = e.value;*/}
          {/*    setFormikFieldValueByName(formik, "Branch_ID", 0);*/}
          {/*    request.Branch_ID=e.value*/}
          {/*    setBranchId(e.value);*/}
          {/*  }}*/}
          {/*  selectedValues={[request.Branch_ID.toString()]}*/}
          {/*  multiselectRef={undefined}*/}
          {/*/>*/}
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-end mb-3">
            <PrivilegesChecker
              formId={formId}
              action={request.ID ? "EnableUpdate" : "EnableSave"}
            >
              <ButtonBox type="submit" className="btn-primary btn-sm mx-3">
                {getLabelName(request.ID ? "Update" : "Save")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
              variant="danger"
              iconType="receipt"
              type="button"
              className="btn-sm"
              onClick={() => {
                onActionEvent({ id: 0, action: ActionTypeEnum.Clear });
              }}
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
        </div>
      </form>
    </>
  );
  //#endregion
};
