import {secureLocalStorageGet} from "..";
import {SystemConfiguration} from "../../configuration";
import {AppConfigurationModel} from "../../models";

// In-memory cache for app configuration
let cachedConfig: AppConfigurationModel | null = null;

const defaultConfig: AppConfigurationModel = {
    apiEndPoint_Configurations: {alyusrApiEndpoint:"" },
    image_Configurations: {defaultWidth: 0, defaultHeight: 0},
};

export const getAppConfig = (): AppConfigurationModel => {
    if (cachedConfig) {
        return cachedConfig;
    }
    try {
        return JSON.parse(<string>secureLocalStorageGet(
            SystemConfiguration.miscellaneous_Configurations.appConfig || ""
        )) as AppConfigurationModel;
    } catch (err) {
        console.log("res_x_999_Error parsing AppConfig, using default:", err);
        return defaultConfig;
    }
};
export const clearAppConfigCache = (): void => {
    cachedConfig = null;
    console.log("App configuration cache cleared.");
};

// export const getAppConfig = (): AppConfigurationModel => {
//     let result: AppConfigurationModel = {
//         apiEndPoint_Configurations: {alyusrApiEndpoint: ""},
//         image_Configurations: {defaultHeight: 0, defaultWidth: 0}
//     };
//     try {
//         result = JSON.parse(
//             <string>secureLocalStorageGet(SystemConfiguration.miscellaneous_Configurations.appConfig || "")
//         ) as AppConfigurationModel;
//     } catch (err: any) {
//         console.log('res_x_99',JSON.stringify(err));
//     }
//     return result;
// };
// export const SetAppConfiguration = (): AppConfiguration => {
//   let result: AppConfiguration = {
//     alyusrApiEndpoint: "",
//   };
//   fetch("config/appConfig.json", {
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//   })
//     .then(function (response) {
//       console.log(response);
//       return response.json();
//     })
//     .then(function (myJson) {
//       SecureLocalStorageSet(
//         SystemConfiguration.keys.appConfig || "",
//         JSON.stringify(myJson)
//       );
//       setData(myJson);
//     });
// };
