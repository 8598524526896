import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {ButtonBox, SelectBox, TextBox} from "..";
import { getLabelName, isArabicCurrentLanguage} from "../../utils";
import {AntdTableComponent} from "../common/dataTable/antdDataTable";
import {handleGenerateGatheredItemsColumns} from "./uiHelper/itemGatheredDataTableColumns";
import {
    GatherItemModel,
    ItemModel,
    LookupItemModel,
    RowStateEnum,
    SearchItemApiResponseModel,
    SearchItemsTypeEnum,
} from "../../models";
import {searchItems} from "../../serviceBroker/itemApiServiceBroker";
import {getItemGathers, getItemUnits, handleCalculatePriceWithTax} from "./businessLogic/itemStoresBl"
import {produce} from "immer";
import {uniqueId} from "lodash";
interface GatherItemFormProps {
  setState: Dispatch<SetStateAction<ItemModel>>,
  state: ItemModel,
    setIsRefresh: Dispatch<SetStateAction<boolean>>,
    isRefresh: boolean,
  categoryList: LookupItemModel[];
  taxValue: number;
}
export const ItemGatherManager: React.FC<GatherItemFormProps> = ({
  categoryList,
  setState,state,taxValue,setIsRefresh,isRefresh
}) => {
    //#region variables
    const itemInitialValues: SearchItemApiResponseModel = {
        ItemInstore_ID: 0,
        ItemUnit_ID: 0,
        Cat_ID: 0,
        Code: "",
        Name_En: "",
        StoreName: "",
        CategeoryNameAr: "",
        CategeoryNameEn: "",
        UnitsNameAr: "",
        UnitsNameEn: "",
        PriceQutyBegBal: 0,
        PriceSale: 0,
        QutyBegBal: 0,
        Balance: 0,
        PriceCost: 0,
        WholeSalePrice: 0,
        LimitOrder: 0,
        SmallestUnit: false,
        IsDefault: false,
        Barcode: "",
        TaxValue: 0,
        Tax: 0,
        PriceSaleWithTax: 0,
        Store_ID: 0,
        ScaleBarcode: "",
        ID: 0,
        CreatedBy: 0,
        ModifiedBy: 0,
        Name: "",
        CreationDate: "",
        ModificationDate: null,
        rowState: 0
    };
    const itemUnits=getItemUnits(state);
    const generateItemGatheredAntdColumns = [...handleGenerateGatheredItemsColumns(isArabicCurrentLanguage,isArabicCurrentLanguage())];
    //#endregion
    //#region state
    const [items, setItems] = useState<SearchItemApiResponseModel[]>([]);
    const [selectedItem, setSelectedItem] =
        useState<SearchItemApiResponseModel>(itemInitialValues);
    const [itemsLoading, setItemsLoading] = useState(false);
    const categorySelectBoxMultiselectRef = useRef<any>();
    const itemSelectBoxMultiselectRef = useRef<any>();
    const isClearingRef = useRef(false);
    //#endregion
    //#region useEffect
    useEffect(() => {
        if (isRefresh) {
            handleClearControls();
            setIsRefresh(false);
        }
    }, [isRefresh]);
    //#endregion
    //#region functions
    const handleClearControls = () => {
        isClearingRef.current = true; // Set the flag before clearing
        categorySelectBoxMultiselectRef.current && categorySelectBoxMultiselectRef.current.clearValue();
        itemSelectBoxMultiselectRef.current && itemSelectBoxMultiselectRef.current.clearValue();
        setSelectedItem(itemInitialValues);
        isClearingRef.current = false; // Reset the flag after clearing
    }
    const handelSearchItems = async (categoryId?: number | null) => {
        if (categoryId === null || categoryId === undefined) {
            handleClearControls()
            return;
        }
        try {
            setItemsLoading(true);
            const items = await searchItems({
                categoryId: categoryId,
                searchType: SearchItemsTypeEnum.None,
                pageSize: 10000,
            });
            items.Result?.forEach((row) => {
                row.quantity = 1;
            });
            setItems(items?.Result || []);
        } catch (error) {
        } finally {
            setItemsLoading(false)
        }
        // try {
        //   setItemsLoading(true);
        //   const items = await searchItems({
        //     categoryId: +categorySelected.value!,
        //     searchType: SearchItemsTypeEnum.None,
        //     pageSize: 10000,
        //   });
        //   setItems(
        //       items.Result?.filter(({ ItemUnit_ID }) =>
        //           items.every(
        //               ({ ItemUnit_ID: id }) => id !== ItemUnit_ID
        //           )
        //       ) as SearchItemApiResponseModel[]
        //   );
        // } catch (error) {
        // } finally {
        //   setItemsLoading(false);
        // }
    };
    const handleGatherItemImmer = (id?: number | null, rowState: RowStateEnum = RowStateEnum.Add) => {
        if (selectedItem === null) return;
        // //handleClearControls();
        // setState((prev) => ({
        //     ...prev,
        //     Customer_ID: request.request.value,
        // }));
        setState((prev) =>
            produce(prev, (draft) => {
                let itemGathers = draft.ItemsInstores[0]?.ItemGathers;
                if (!itemGathers) {
                    draft.ItemsInstores[0].ItemGathers = [];
                    itemGathers = draft.ItemsInstores[0].ItemGathers;
                    return;
                }
                switch (rowState) {
                    case RowStateEnum.Add:
                        itemGathers.push({
                            ID: null,
                            Item_ID: selectedItem.ID,
                            ItemInstore_ID: selectedItem.ItemInstore_ID,
                            ItemUnit_ID: selectedItem.ItemUnit_ID,
                            ItemNameAr: selectedItem.Name,
                            ItemNameEn: selectedItem.Name_En,
                            PriceCost: selectedItem.PriceCost,
                            PriceSale: selectedItem.PriceSale,
                            Quantity: selectedItem.quantity ?? 1,
                            rowState: RowStateEnum.Add,
                            rowKey: uniqueId(),
                        } as GatherItemModel);
                        break;
                    default:
                        let existingItem = itemGathers.find(
                            (gather) => gather.ItemUnit_ID === selectedItem.ItemUnit_ID
                        );
                        if (existingItem) {
                            existingItem.Quantity = selectedItem.quantity ?? existingItem.Quantity;
                            existingItem.rowState = rowState;
                        }
                        break;
                }
                if (!draft.ItemsInstores || draft.ItemsInstores.length === 0) return;
                if (!draft.ItemsInstores[0].Item_unit || draft.ItemsInstores[0].Item_unit.length === 0) return;
                // Update the main PriceCost based on ItemGathers (excluding deleted rows)
                const sum = itemGathers
                    .filter(({ rowState }) => rowState !== RowStateEnum.Delete)
                    .reduce((acc, cur) => cur.PriceCost * cur.Quantity + acc, 0);
                draft.ItemsInstores[0].Item_unit[0].PriceQutyBegBal = sum;
                draft.ItemsInstores[0].Item_unit[0].PriceQutyBegBalWithTax= handleCalculatePriceWithTax(sum,draft.TaxValue??0);
            })
        );
        // setTimeout(() => {
        //     console.log("res_x_3_Updated State:", state.ItemsInstores[0]?.Item_unit[0]?.ItemGathers);
        // }, 10);
        // const quantity = selectedItem.quantity;
        //
        // setState((prev) => {
        //   // Find the correct ItemInStore based on selectedItem.ItemInstore_ID
        //   const updatedItemsInstores = prev.ItemsInstores.map((instore) => {
        //     if (instore.ID === selectedItem.ItemInstore_ID) {
        //       // Find the correct ItemUnit_ID inside ItemInstores
        //       const updatedUnits = instore.Item_unit .map((unit) => {
        //         if (unit.ID === selectedItem.ItemUnit_ID) {
        //           // Update the ItemGathers array for this unit
        //           return {
        //             ...unit,
        //             ItemGathers: [
        //               ...(unit.ItemGathers || []), // Keep existing gathers or start with an empty array
        //               {
        //                 ID: null,
        //                 Item_ID: selectedItem.ID,
        //                 ItemInstore_ID: selectedItem.ItemInstore_ID,
        //                 ItemUnit_ID: selectedItem.ItemUnit_ID,
        //                 ItemNameAr: selectedItem.Name,
        //                 ItemNameEn: selectedItem.Name_En,
        //                 PriceCost: selectedItem.PriceCost,
        //                 PriceSale: selectedItem.PriceSale,
        //                 Quantity: quantity,
        //                 rowState: RowStateEnum.Add,
        //                 rowKey: uniqueId(),
        //               },
        //             ],
        //           };
        //         }
        //         return unit;
        //       });
        //
        //       // Return the updated instore with modified ItemUnits
        //       return {
        //         ...instore,
        //         ItemUnits: updatedUnits,
        //       };
        //     }
        //     return instore;
        //   });
        //
        //   // Return the updated ItemModel
        //   return {
        //     ...prev,
        //     ItemsInstores: updatedItemsInstores,
        //   };
        // });
    };
    const handleGatherItem = (id?: number | null, rowState: RowStateEnum = RowStateEnum.Add) => {
        if (!selectedItem) return;

        setState((prev) => {

            // Clone the entire state
            const newState = structuredClone(prev);

            // Access or initialize itemGathers
            let itemGathers = newState.ItemsInstores[0]?.ItemGathers;
            if (!itemGathers) {
                newState.ItemsInstores[0].ItemGathers = [];
                itemGathers = newState.ItemsInstores[0].ItemGathers;
            }
            // Handle operations based on rowState
            switch (rowState) {
                case RowStateEnum.Add:
                    itemGathers.push({
                        ID: null,
                        Item_ID: selectedItem.ID,
                        ItemInstore_ID: selectedItem.ItemInstore_ID,
                        ItemUnit_ID: selectedItem.ItemUnit_ID,
                        ItemNameAr: selectedItem.Name,
                        ItemNameEn: selectedItem.Name_En,
                        PriceCost: selectedItem.PriceCost,
                        PriceSale: selectedItem.PriceSale,
                        Quantity: selectedItem.quantity ?? 1,
                        rowState: RowStateEnum.Add,
                        rowKey: uniqueId(),
                    } as GatherItemModel);
                    break;

                default:
                    const existingItem = itemGathers.find(
                        (gather) => gather.ItemUnit_ID === selectedItem.ItemUnit_ID
                    );
                    if (existingItem) {
                        existingItem.Quantity = selectedItem.quantity ?? existingItem.Quantity;
                        existingItem.rowState = rowState;
                    }
                    break;
            }

            return newState; // Reflect the updated state
        });
    };
    const handleCalculateBeginPriceQyt = (gatherItems: GatherItemModel[]) => {
        if (itemUnits.length === 0) return;
        setState((prevItem) => {
            if (!prevItem) return prevItem; // Handle potential null/undefined state
            return {
                ...prevItem,
                ItemsInstores: prevItem.ItemsInstores.map((store) => {
                    // Assuming you only need to update the first `ItemUnit` of each store
                    const updatedUnits = store.Item_unit.map((unit, index) => {
                        if (index === 0) {
                            const sum = gatherItems
                                .filter(({ rowState }) => rowState !== RowStateEnum.Delete)
                                .reduce((acc, cur) => cur.PriceCost * cur.Quantity + acc, 0);
                            return {
                                ...unit,
                                PriceQutyBegBal: sum,
                                PriceQutyBegBalWithTax: +(
                                    sum +
                                    (sum * (taxValue ?? 0)) / 100
                                ).toFixed(),
                            };
                        }
                        return unit;
                    });

                    return {
                        ...store,
                        Item_unit: updatedUnits,
                    };
                }),
            };
        });
    };
    //#endregion
    //#region html
    return (
        <>
            <div
                className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-center">
                <SelectBox
                    labelName={getLabelName("Categorys")}
                    source={categoryList}
                    isSingleSelect={true}
                    onStatusChange={async (e: any) => {
                        if (isClearingRef.current) return; // Skip the search if we are clearing the controls
                        await handelSearchItems(e?.value);
                    }}
                    selectedValues={
                        selectedItem.Cat_ID === 0 ? null : [selectedItem.Cat_ID.toString()]
                    }
                    multiselectRef={categorySelectBoxMultiselectRef}
                />
                <SelectBox
                    labelName={getLabelName("item")}
                    source={items.map(({ItemUnit_ID, Name, Name_En}) => ({
                        value: `${ItemUnit_ID}`,
                        nameAr: Name,
                        name: Name_En,
                    }))}
                    isSingleSelect={true}
                    onStatusChange={(e: any) => {
                        if (e === null) return setSelectedItem((prev) => ({...prev, ItemUnit_ID: 0}));
                        const item = items.find(({ItemUnit_ID}) => ItemUnit_ID === +e.value) || null;
                        setSelectedItem(item ?? itemInitialValues);
                    }}
                    selectedValues={
                        selectedItem.ItemUnit_ID !== 0 ? [`${selectedItem.ItemUnit_ID}`] : null
                    }
                    multiselectRef={itemSelectBoxMultiselectRef}
                    isDataLoading={itemsLoading}
                />
                <TextBox
                    type={"number"}
                    inputName="quantity"
                    inputValue={selectedItem.quantity}
                    onChange={(e: any) => {
                        setSelectedItem((prev) => ({...prev, quantity: e?.value ?? 0}));
                    }}
                    labelName={getLabelName("quantity")}
                />
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end mb-4 mt-2">
                <ButtonBox
                    iconType="plus"
                    type="button"
                    className="btn  btn-sm mx-2"
                    variant="primary"
                    onClick={() => handleGatherItemImmer()}
                    disabled={!selectedItem}
                >
                    {getLabelName("add")}
                </ButtonBox>
            </div>
            <div className="mt-4">
                <AntdTableComponent
                    data={getItemGathers(state).filter(
                        (row) => row.rowState !== RowStateEnum.Delete
                    )}
                    columns={generateItemGatheredAntdColumns}
                    rowKey="rowKey"
                />
            </div>
        </>
    );
    //#endregion
};
