import {
  FC,
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  ButtonBox,
  LoadingBox,
  SelectBox,
  TextBox,
  PrivilegesChecker,
  GridTable,
} from "../..";
import {
  ActionTypeEnum,
  DailyTransactionTypeEnum,
  HasFormIdModel,
  LookupItemModel,
  LookupTypeEnum,
  RequestActionModel,
  SearchDailyTransactionTypeEnum,
  SearchTransactionRequestModel,
  TableActionColumnsStyleEnum,
  TransactionResponseModel,
} from "../../../models";
import { searchTransactions } from "../../../serviceBroker/transactionApiServiceBroker";
import {
  generateGuid,
  getLabelName,
  getPagePrivileges,
  isArabicCurrentLanguage,
} from "../../../utils";
import { getLookupByType } from "../../../serviceBroker/lookupApiServiceBroker";

interface TransactionListProps extends HasFormIdModel {
  transactionType: DailyTransactionTypeEnum;
  onActionEvent: (o: RequestActionModel) => void;
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  isRefresh: boolean;
}

export const TransactionList: FC<TransactionListProps> = ({
  transactionType,
  onActionEvent = () => {},
  setIsRefresh,
  isRefresh = false,
  formId,
}) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const defaultPageSize: number = 50;
  const initialValues: SearchTransactionRequestModel = {
    pageNumber: 1,
    pageSize: defaultPageSize,
    transactionType: transactionType,
    searchType: SearchDailyTransactionTypeEnum.itemName,
    searchValue: "",
  };
  const searchTypeList: LookupItemModel[] = [
    {
      name: getLabelName("ItemName"),
      nameAr: getLabelName("ItemName"),
      value: SearchDailyTransactionTypeEnum.itemName.toString(),
    },
    {
      name: getLabelName("Code"),
      nameAr: getLabelName("Code"),
      value: SearchDailyTransactionTypeEnum.code.toString(),
    },
    {
      name: getLabelName("Id"),
      nameAr: getLabelName("Id"),
      value: SearchDailyTransactionTypeEnum.id.toString(),
    },
    {
      name: getLabelName("Serial"),
      nameAr: getLabelName("Serial"),
      value: SearchDailyTransactionTypeEnum.serial.toString(),
    },
  ];
  //#endregion
  //#region state
  const customerAccountsSelectBoxMultiselectRef = useRef<any>();
  const [data, setData] = useState<TransactionResponseModel[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [customerList, setCustomerList] = useState<LookupItemModel[]>([]);
  const [cityList, setCityList] = useState<LookupItemModel[]>([]);
  const [searchRequest, setSearchRequest] =
    useState<SearchTransactionRequestModel>(initialValues);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      await getAllLookupsPromiseAll();
      //await getTransactions();
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      await getTransactions();
      setLoading(false);
    };
    fillData().then(() => {});
  }, [
    searchRequest.pageNumber,
    searchRequest.pageSize,
    searchRequest.searchGuid,
  ]);
  useEffect(() => {
    if (isRefresh) {
      const fillData = async () => {
        setLoading(true);
        await getTransactions();
        setLoading(false);
      };
      fillData().then(() => {});
      setIsRefresh(false);
    }
  }, [isRefresh]);
  //#endregion
  //#region component
  const subHeaderComponent = useMemo(() => {
    return (
      <>
        <div
            className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
          <SelectBox
              labelName={getLabelName("Customer")}
              isSingleSelect={true}
              selectedValues={
                searchRequest.customerId !== null &&
                searchRequest.customerId !== undefined
                    ? //@ts-ignore
                    [searchRequest.customerId.toString()]
                    : null
              }
              source={customerList}
              onStatusChange={(e: LookupItemModel) => {
                setSearchRequest({
                  ...searchRequest,
                  customerId:
                      e === null || e.value === null ? null : Number(e.value),
                });
              }}
              multiselectRef={customerAccountsSelectBoxMultiselectRef}
          />
          <TextBox
              labelName={getLabelName("identity")}
              inputName={"filterTextBox"}
              inputValue={searchRequest.searchValue}
              onChange={(e: any) => {
                setSearchRequest({
                  ...searchRequest,
                  searchValue: e.target.value,
                });
              }}
          />
          <div className="row mt-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
          <PrivilegesChecker formId={formId} action="EnableSearch">
            <ButtonBox
                iconType="account-search"
                className="btn-sm mx-2 btn-gradient-primary"
                onClick={() => {
                  setSearchRequest({
                    ...searchRequest,
                    searchGuid: generateGuid(),
                  });
                }}
            >
              {getLabelName("Search")}
            </ButtonBox>
          </PrivilegesChecker>
          <ButtonBox
              iconType="receipt"
              variant="danger"
              className="btn btn-sm"
              onClick={() => {
                customerAccountsSelectBoxMultiselectRef.current.clearValue();
                //citiesSelectBoxMultiselectRef.current.clearValue();
                //searchTypeSelectBoxMultiselectRef.current.clearValue();
                setSearchRequest({
                  ...initialValues,
                  searchGuid: generateGuid(),
                });
              }}
          >
            {getLabelName("Cancel")}
          </ButtonBox>
          </div>
        </div>
        </div>
      </>
    );
  }, [searchRequest, customerList, cityList, searchTypeList]);
  //#endregion
  //#region functions
  const getAllLookupsPromiseAll = async () => {
    const [customerLookupList, cityListLookupList] = await Promise.all([
      getLookupByType(LookupTypeEnum.Customers, true, false),
      getLookupByType(LookupTypeEnum.Cities, true, false),
    ]);
    setCustomerList(customerLookupList);
    setCityList(cityListLookupList);
  };
  const getTransactions = async () => {
    const result = await searchTransactions(searchRequest);
    setData(result?.Result || []);
    setTotalRows(result?.TotalRecoredCount || 0);
  };
  const onCurrentPageChange = async (pageNumber: number) => {
    if (pageNumber !== searchRequest.pageNumber) {
      const obj = {...searchRequest};
      obj.pageNumber = pageNumber;
      setSearchRequest(obj);
    }
  };
  const onPageSizeChange = async (pageSize: number) => {
    if (pageSize !== searchRequest.pageSize) {
      const obj = {...searchRequest};
      obj.pageSize = pageSize;
      setSearchRequest(obj);
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {getPagePrivileges().EnableSearch && (
        <PrivilegesChecker formId={formId} action="EnableSearch">
          {subHeaderComponent}
        </PrivilegesChecker>
      )}
      <GridTable
        data={data}
        totalRows={totalRows}
        currentPage={searchRequest.pageNumber || 1}
        pageSize={searchRequest.pageSize || defaultPageSize}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationType="server"
        columnsProps={{
          actions: [
            ActionTypeEnum.ModifyStart,
            ActionTypeEnum.DeleteStart,
            ActionTypeEnum.PrintPdf,
            ActionTypeEnum.TransactionRefundIInitialization,
          ],
          onActionEvent,
          isArabic,
        }}
        formId={formId}
        style={TableActionColumnsStyleEnum.Dropdown}
        // subHeaderComponent={getPagePrivileges().search && subHeaderComponent}
      />
    </>
  );
  //#endregion
};
