import {FC, useEffect, useState} from "react";
import {Accordion, Card} from "react-bootstrap";
import {
    ConfirmModelDialogBox,
    ErrorValidationBox,
    LoadingBox,
    RegisterUnit,
    ToastBox,
    UnitList,
} from "../../components";
import {
    ActionButtonsModel,
    ActionTypeEnum,
    PageEnum,
    RequestActionModel,
    RowStateEnum,
    ToastModel,
    UnitModel,
    ValidationErrorModel,
} from "../../models";
import {deleteUnit,} from "../../serviceBroker/unitApiServiceBroker";
import {getActionMessage, getLabelName, getPageNameByPageId, isArabicCurrentLanguage,} from "../../utils";

export const UnitPage: FC<{ formId: number }> = ({formId = PageEnum.Units}) => {
    //#region variables
    const initialValues: UnitModel = {
        IsDefault: false,
        Name_En: "",
        Name: "",
        ID: 0,
        CreatedBy: 0,
        ModifiedBy: 0,
        Errors: [],
        rowState: Number(RowStateEnum.Add),
    };
    const deleteUnitActions: ActionButtonsModel[] = [
        {
            text: getLabelName("yes"),
            onClick: async () => {
                setLoading(true);
                setShowDeleteUnitModel(false);
                const result = await deleteUnit(unit.ID!);
                const isSuccess: boolean = !(
                    result.Errors != null &&
                    result.Errors.length !== 0
                );
                setLoading(false);
                if (isSuccess) {
                    await handleActions({
                        id: ActionTypeEnum.Delete,
                        action: ActionTypeEnum.Success
                    });
                } else {
                    setValidationErrors(result?.Errors || []);
                }
            },
        },
        {
            text: getLabelName("no"),
            onClick: () => {
                setUnit(initialValues);
                setShowDeleteUnitModel(false);
            },
        },
    ];
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    const [unit, setUnit] = useState<UnitModel>(initialValues);
    const [toastModel, setToastModel] = useState<ToastModel>({show: false});
    const [pageName, setPageName] = useState<string | null>(null);
    const [showDeleteUnitModel, setShowDeleteUnitModel] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    //#endregion
    //#region useEffect
    useEffect(() => {
        if (formId !== 0 && formId !== null && formId !== undefined) {
            setPageName(getPageNameByPageId(formId));
        }
    }, [isArabicCurrentLanguage()]);
    //#endregion
    //#region function
    const handleActions = async (request: RequestActionModel) => {
        switch (request.action) {
            case ActionTypeEnum.CloseModelPopup:
                setValidationErrors([]);
                setShowDeleteUnitModel(false);
                setIsRefresh(false);
                break;
            case ActionTypeEnum.AddSuccess:
            case ActionTypeEnum.Success:
                setValidationErrors([]);
                setLoading(true);
                setUnit(initialValues);
                setIsRefresh(true);
                setToastModel({
                    ...toastModel,
                    variant: "success",
                    body:getActionMessage(request.id as ActionTypeEnum),
                    show: true,
                });
                setLoading(false);
                break;
            case ActionTypeEnum.Clear:
                setLoading(true);
                setValidationErrors([]);
                setUnit(initialValues);
                setIsRefresh(true);
                setLoading(false);
                break;
            case ActionTypeEnum.Update:
                window.scrollTo(0, 0);
                setValidationErrors([]);
                setUnit(request.request);
                break;
            case ActionTypeEnum.Delete:
                setValidationErrors([]);
                setUnit(request.request);
                setShowDeleteUnitModel(true);
                break;
            case ActionTypeEnum.Failed:
                setValidationErrors(request.request??[]);
                setUnit(request.requestBeforeError);
                break;
            case ActionTypeEnum.Refresh:
                setIsRefresh(request.request);
                break;
        }
    };
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: false});
                    }}
                />
            )}
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{pageName}</Accordion.Header>
                    <Accordion.Body>
                        <Card>
                            <Card.Body>
                                <RegisterUnit
                                    request={unit}
                                    onActionEvent={async (o: RequestActionModel) => {
                                        await handleActions(o);
                                    }}
                                    formId={formId}
                                />
                            </Card.Body>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
                    <Accordion.Body>
                        <Card className="card-custom">
                            {/* <Card.Header></Card.Header> */}
                            <Card.Body>
                                <ConfirmModelDialogBox
                                    isModelVisible={showDeleteUnitModel}
                                    onCloseEvent={() => {
                                        setShowDeleteUnitModel(false);
                                    }}
                                    actions={deleteUnitActions}
                                >
                                </ConfirmModelDialogBox>
                                {/* Unit list */}
                                    <UnitList
                                        isRefresh={isRefresh}
                                        onActionEvent={async (o: RequestActionModel) => {
                                            await handleActions(o);
                                        }}
                                        formId={formId}
                                    />
                            </Card.Body>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
    //#endregion
};
