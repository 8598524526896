import { FC, FormEvent, useEffect, useState } from "react";
import {
  getLabelName,
  isArabicCurrentLanguage,
} from "../../utils";
import {
  ActionTypeEnum,
  HasFormIdModel,
  LookupFormTypeEnum,
  LookupModel,
} from "../../models";
import {
  GridTable,
  SearchBox,
} from "..";
interface LookupListProps extends HasFormIdModel {
  request: LookupModel[];
  lookupType: LookupFormTypeEnum;
  onActionEvent?: any | null;
  onCompleteEvent?: any | null;
}

export const LookupList: FC<LookupListProps> = ({
  request,
  onActionEvent,
  formId,
}) => {
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [data, setData] = useState(request);
  const handleSearch = (keyword: string) => {
    if (searchedKeyword !== "")
      setData(() =>
        request.filter(
          (item) => item.Name.includes(keyword) || item.NameEn.includes(keyword)
        )
      );
    else setData(request);
  };
  useEffect(() => {
    setData(request);
  }, [request]);
  //#region html
  return (
    <>
      <SearchBox
        onChangeHandler={(e: FormEvent<HTMLInputElement>) =>
          setSearchedKeyword(e.currentTarget.value)
        }
        onSearchHandler={handleSearch.bind(null, searchedKeyword)}
        searchedWord={searchedKeyword}
        name={getLabelName("name")}
      />
      <GridTable
        // columns={generateColumnList()}
        data={data}
        totalRows={10000}
        currentPage={1}
        paginationType="none"
        pageSize={10000}
        columnsProps={{
          actions: [ActionTypeEnum.Update, ActionTypeEnum.Delete],
          isArabic: isArabicCurrentLanguage(),
          onActionEvent,
        }}
        formId={formId}
      />
    </>
  );
  //#endregion
};
