import React, {Dispatch, SetStateAction} from "react";
import {
  CompanySettingModel,
  GatherItemModel,
  ItemModel,
  ItemModifierModel,
  ItemUnitModel,
  LookupItemModel,
  LookupTypeEnum,
  ResponseBaseModel,
  RowStateEnum,
  ValidationErrorModel,
} from "../../../models";
import { getCachedDataOrFetch, getLabelName, setFormikFieldValueByName} from "../../../utils";
import {saveItem} from "../../../serviceBroker/itemApiServiceBroker";
import {DataNode} from "rc-tree/lib/interface";
import {getLookupByType} from "../../../serviceBroker/lookupApiServiceBroker";
import {SystemConfiguration} from "../../../configuration";
import {getCompanySetting} from "../../../serviceBroker/companySettingApiServiceBroker";
import {generateItemStore} from "./itemStoresBl";
//#region functions
//#region tree
export const onTreeNodeSelected = (
  e: DataNode[],
  setSelectedCategoryState: Dispatch<SetStateAction<number>>
) => {
  setSelectedCategoryState(Number(e[0].key));
};
//#endregion
//#region validation
export const handleValidation = (
  values: ItemModel,
): ValidationErrorModel[] => {
  let errorMessages: ValidationErrorModel[] = [];
  const itemUnits = values.ItemsInstores[0].Item_unit;

  if (values.Name === null || values.Name === undefined || values.Name === "") {
    errorMessages.push({
      MessageAr: getLabelName("nameAr.missing"),
      MessageEn: getLabelName("nameAr.missing"),
    });
  }
  if (!values.Cat_ID ) {
    errorMessages.push({
      MessageAr: getLabelName("categoryId.missing"),
      MessageEn: getLabelName("categoryId.missing"),
    });
  }

  if (itemUnits === null || itemUnits === undefined || itemUnits.length === 0) {
    errorMessages.push({
      MessageAr: getLabelName("no units added to item"),
      MessageEn: getLabelName("no units added to item"),
    });
  } else if (
    itemUnits[0].Unit_ID === null ||
    itemUnits[0].Unit_ID === undefined ||
    itemUnits[0].Unit_ID === 0
  ) {
    errorMessages.push({
      MessageAr: getLabelName("no units added to item"),
      MessageEn: getLabelName("no units added to item"),
    });
  }

  if (
    errorMessages === null ||
    errorMessages === undefined ||
    errorMessages.length === 0
  ) {
    const activeUnitsCount = itemUnits.filter(
      (p: ItemUnitModel) => p.rowState !== Number(RowStateEnum.Delete)
    ).length;
    const smallestActiveUnitCount = itemUnits.filter(
      (p: ItemUnitModel) => p.rowState !== 3 && p.IsSmallestUnit
    ).length;
    if (activeUnitsCount === 0) {
      errorMessages = errorMessages ?? [];
      errorMessages.push({
        MessageAr: getLabelName("no units added to item"),
        MessageEn: getLabelName("no units added to item"),
      });
    }

    if (activeUnitsCount > 1 && smallestActiveUnitCount === 0) {
      errorMessages = errorMessages ?? [];
      errorMessages.push({
        MessageAr: getLabelName("no smallest unit selected"),
        MessageEn: getLabelName("no smallest unit selected"),
      });
    }
    if (smallestActiveUnitCount > 1) {
      errorMessages = errorMessages ?? [];
      errorMessages.push({
        MessageAr: getLabelName("only one  smalls unit allowed"),
        MessageEn: getLabelName("only one  smalls unit allowed"),
      });
    }
  }
  return errorMessages;
};
//#endregion
//#region actions
export const handleItemReset = (
  setItemState: Dispatch<SetStateAction<ItemModel>>,
  setItemUnitState: Dispatch<SetStateAction<ItemUnitModel[]>>,
  setItemModifierState: Dispatch<SetStateAction<ItemModifierModel[]>>,
  setValidationErrorState: Dispatch<SetStateAction<ValidationErrorModel[]>>,
  setGatherItemState: React.Dispatch<React.SetStateAction<GatherItemModel[]>>
) => {
  setItemState(itemInitialValues);
  setItemUnitState([]);
  setItemModifierState([]);
  setValidationErrorState([]);
  setGatherItemState([]);
};
export const handleSubmit = async (
  requestObj: ItemModel,
): Promise<ResponseBaseModel<ItemModel>> => {
  let response: ResponseBaseModel<ItemModel> = {};
  try {
    const errors = handleValidation(requestObj);
    if (errors !== null && errors !== undefined && errors.length !== 0) {
      response.Errors = errors;
      return response;
    } else {
      if (
          requestObj.ItemImages &&
          requestObj.ItemImages.File64Bit !== null &&
          requestObj.ItemImages.File64Bit !== undefined
      ) {

        requestObj.ItemImages.File64Bit = requestObj.ItemImages.File64Bit.replace(
            /^data:image\/(jpeg|png|jpg);base64,/,
            ""
        );
      }
      response = await saveItem(requestObj);
    }
  } catch (err: any) {
    response.Errors = [{MessageAr: err, MessageEn: err}];
  }
  return response;
};
//#endregion
//#region others
export const handleInitializeComponent = async (formik: any,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setUnitList: Dispatch<SetStateAction<LookupItemModel[]>>,
  setCategoryList: Dispatch<SetStateAction<LookupItemModel[]>>,
  setCompanySetting:Dispatch<SetStateAction<CompanySettingModel>>,
  setDefaultUnitId: Dispatch<SetStateAction<number>>
) => {
  setLoading(true);
  const [units,categories,companySetting]=await Promise.all([
    getLookupByType(LookupTypeEnum.Units),
    getLookupByType(LookupTypeEnum.AllCategories),
    getCachedDataOrFetch<CompanySettingModel>(SystemConfiguration.cacheKey_Configurations.setting.companySettings, async () => {
      const setting = await getCompanySetting();
      return setting!==null ? {...setting,isInitialRequest:false} : setCompanySettingsInitialValues;
    })
  ]);
  setCategoryList(categories);
  setUnitList(units);
  setCompanySetting(companySetting);
  setDefaultUnitId(units[0].value === null ? 0 : +units[0].value);
  setFormikFieldValueByName(formik, "TaxValue",  companySetting?.DefaultTaxPercentage);
  setLoading(false);
};
export const handleRefreshItemComponent = async (
  formik: any,
  setItem: Dispatch<SetStateAction<ItemModel>>,
  setIsRefresh: Dispatch<SetStateAction<boolean>>,
  request?: ItemModel | null
) => {
  //@ts-ignore
  setItem(request);
  if (request !== null && request !== undefined) {
    formik.setValues(request);
  }
  setIsRefresh(false);
};
//#endregion
//#region private
const firstOrDefault = <T>(array: T[] | undefined | null, defaultValue: T): T =>
    array?.find(() => true) || defaultValue;
//#endregion
//#endregion
//#region variables
export const setCompanySettingsInitialValues:Readonly<CompanySettingModel>={
  Advert: undefined,
  ApplyTax: false,
  ArabicLanguage: "",
  AttachmentFolder: undefined,
  BacckGroundImage: "",
  BackColor: 0,
  BackGroundImageName: "",
  CalcDiscountWithVAT: false,
  CheckBalanceOfItemOnAdd: false,
  CompanyAddress: "",
  CompanySetting_UniqueId: "",
  Company_Address: "",
  CreatedBy: undefined,
  CreationDate: "",
  CurrencyShortCut: "",
  Currency_ID: 0,
  DateType: false,
  DaysOfDeleteBackupFiles: 0,
  DecimalPlace: 0,
  DefaultCurrency_ID: 0,
  EnglishLanguage: "",
  Errors: [],
  FormatDate: "",
  FormatDecimal: "",
  Header: undefined,
  HideItemFromCloseDay: false,
  ID: 0,
  IncludeVatOnEditPrice: false,
  IsCodeTransactionSeparated: false,
  IsEnableToUploadEinvoice: false,
  IsFloorSecondeCurrency: false,
  IsSentEmailReportAfterCloseDayOnly: false,
  IsTrialVersion: false,
  IsUploadShipmentTrackToCloud: false,
  IsUploadTransactionToCloud: false,
  Is_Company_Authorized_Tobacco: false,
  LogoImage: "",
  LogoImage64: "",
  LogoImageName: "",
  Mail: "",
  MobileReceiver: "",
  ModificationDate: "",
  ModifiedBy: undefined,
  Name: "",
  Password: "",
  PasswordSMS: "",
  PathBackUp: "",
  Phone: "",
  PrintItemArabicEngInBill: false,
  PrintItemPrescriptionOfTransaction: false,
  Printer_Tablet_List: "",
  RecivedMail: "",
  SendMailOfSalesOnClose: false,
  SendSMS: false,
  SenderName: "",
  ServiceUI: "",
  SetCostPriceZeroOnSalePriceZero: false,
  SetScaleOnPrice: false,
  SetScaleWeightonKG: false,
  ShowItemPriceWithoutTax: false,
  SoftwareName: undefined,
  SystemSettings: "",
  TaxNumber: "",
  TermsAndCondations: "",
  TextLogo: "",
  Token: undefined,
  TransferTaxToDiscount: false,
  UseOldModelForSearch: false,
  UserNameSMS: "",
  ValueOfCurrency: 0,
  ValueOfPoint: 0,
  VerifyOnUpdate: false,
  WaterMarkImage: "",
  rowState: 0,
  voucherSettings: undefined,
  isInitialRequest: true
};
export const itemInitialValues: Readonly<ItemModel> = {
  Code: "",
  Name_En: "",
  Notes: "",
  Cat_ID: 0,
  StagnantPeriod: 0,
  UserID: 0,
  TaxValue: null,
  OrderLimit: 0,
  OpenPrice: false,
  IsActive: false,
  DisplayIndex: 0,
  TobaccoTax: false,
  TobaccoValue: 0,
  UseExpiryDate: false,
  ExpireDay: 0,
  ShowInPOS: false,
  GatherItem: false,
  OpenItem: false,
  WithoutBalance: false,
  HasModifiers: false,
  CanAddAddationalCost: false,
  HasImage: false,
  IsProduction: false,
  HasSerial: false,
  Status: false,
  IsIncludeTobaccoTax: false,
  TobaccoTaxPercentage: 0,
  IsUnisSeparateInBalance: false,
  IsItemVanishConsign: false,
  ItemsInstores: generateItemStore(),
  DeleteOldSaveItemUnitGather: false,
  IsUpdateItemPriceInOtherStores: false,
  UpdateGatherItemCost: false,
  IsDifferentFactor: false,
  Item_Supplier_ID: 0,
  Is_Filter_Service: false,
  Is_Oil_Service: false,
  DisplySequence: 0,
  ID: 0,
  CreatedBy: 0,
  ModifiedBy: 0,
  Name: "",
  Item_Modifiers: null,
  CreationDate: "",
  ModificationDate: "",
  VerifyOnUpdate: false,
  rowState: Number(RowStateEnum.Add),
  ItemImages: null,
  isNewItem:true
};
//#endregion
