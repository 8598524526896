import React, { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  AuthenticateUserRequestModel,
  AuthenticateUserResponseModel,
} from "../../models";
import { useTranslation } from "react-i18next";
import { LoadingBox, ButtonBox, ErrorValidationBox } from "..";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authenticateUser } from "../../slice/userAuthincateSlice";
import {RoutePageConfigurations, SystemConfiguration} from "../../configuration";
import './loginForm.css';
import {handleLanguageChange, secureLocalStorageGet, secureLocalStorageSet} from "../../utils";
import secureLocalStorage from "react-secure-storage";
export const LoginForm: FC = () => {
  //#region variables
  const redirectUrlPage: string = RoutePageConfigurations.miscellaneous.dashboardPage;
  const initialValues: AuthenticateUserRequestModel = {
    userName: "",
    password: "",
  };
  const loginPageClass = "login-page";
  //#endregion
  //#region state
  const { t } = useTranslation();
  const [validationSchema] = useState(
    Yup.object({
      userName: Yup.string().required(t("user nameMissing")),
      password: Yup.string().required(t("login.passwordMissing")),
    })
  );
  const user: AuthenticateUserResponseModel = useSelector((state: any) => ({
    ...state.user,
  }));
  const dispatch = useDispatch();
  let navigate = useNavigate();
  //#endregion
  //#region functions
  const isFirstLogin = () => {
    const storedValue =secureLocalStorageGet(SystemConfiguration.miscellaneous_Configurations.isFirstLogin);
    return storedValue === null || storedValue === 'true'|| storedValue===true; // Default to true if not set
  };
  const setIsFirstLogin = (value: boolean) => {
    secureLocalStorageSet(SystemConfiguration.miscellaneous_Configurations.isFirstLogin,value.toString());
  };
  const handleUserAuthentication = async (
    request: AuthenticateUserRequestModel
  ) => {
    try {
      // const result = await AuthenticateUser(request);
      dispatch(
        // @ts-ignore
        authenticateUser({
          userName: request.userName,
          password: request.password,
          remember: true,
        })
      );
    } catch (err: any) {
      window.scrollTo(0, 0);
    }
  };
  //#endregion
  //#region formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onReset: () => {},
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleUserAuthentication(values);
    },
  });
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (
      user &&
      user.Errors !== null &&
      user.Errors !== undefined &&
      user.Errors.length !== 0
    ) {
      window.scrollTo(0, 0);
    }
    if (user.isAuthenticated) {
      const _isFirstLogin=isFirstLogin();
      handleLanguageChange(null, _isFirstLogin).then(() => {});
      if(_isFirstLogin){setIsFirstLogin(!_isFirstLogin);}
      navigate(redirectUrlPage);
    }
  }, []);
  useEffect(() => {
    if (
      user &&
      user.Errors !== null &&
      user.Errors !== undefined &&
      user.Errors.length !== 0
    ) {
      window.scrollTo(0, 0);
    }
    if (user.isAuthenticated) {
      const _isFirstLogin=isFirstLogin();
      handleLanguageChange(null, _isFirstLogin).then(() => {});
      if(_isFirstLogin){setIsFirstLogin(!_isFirstLogin);}
      navigate(redirectUrlPage);
      document.body.classList.remove(loginPageClass);
    } else {
      document.body.classList.add(loginPageClass);
    }
  }, [user.isAuthenticated, user.Errors]);
  //#endregion
  //#region functions
  //#endregion
  //#region html
  return (
    <>
      {user.isLoading && <LoadingBox />}

      {user.Errors !== null &&
        user.Errors !== undefined &&
        user.Errors.length !== 0 && <ErrorValidationBox errors={user.Errors} />}
      <form className="pt-3" onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          <label className="form-label">
            {t("user name")} <span className="asterisk text-danger">*</span>
          </label>
          <input
              id="userName"
              name="userName"
              type="text"
              className="form-control login-input"
              placeholder={t("enter user name")}
              value={formik.values.userName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
          />
          {formik.errors.userName ? (
              <span className="field-validate">{formik.errors.userName}</span>
          ) : null}
        </div>
        <div className="mb-3">
          <label className="form-label">
            {t("login.password")}<span className="asterisk text-danger">*</span>
          </label>
          <input
              id="password"
              name="password"
              type="password"
              className="form-control login-input"
              placeholder={t("login.enter_password")}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
          />
          {formik.errors.password ? (
              <span className="field-validate">{formik.errors.password}</span>
          ) : null}
        </div>
        <div className="d-grid">
          <ButtonBox type="submit" className="btn btn-gradient-primary">
            {t("login.submit")}
          </ButtonBox>
        </div>
      </form>
      <div className="bg-div"></div>
    </>
  );
  //#endregion
};
