import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import {
  ActionTypeEnum,
  PermissionModel,
  PermissionTypeEnum,
  RequestActionModel,
} from "../../models";
import {
  getUserPermission,
  saveUserPermissions,
} from "../../serviceBroker/userApiServiceBroker";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import { LoadingBox, ButtonBox } from "..";

export const UserPermissions: FC<{
  userId: number;
  onActionEvent: (o: RequestActionModel) => void;
}> = ({ userId, onActionEvent = () => {} }) => {
  //#region variable
  const isArabic: boolean = isArabicCurrentLanguage();
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [userPermissionObject, setUserPermissionObject] = useState<
    PermissionModel[] | null
  >(null);
  const [searchQuery, setSearchQuery] = useState("");
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      const permissions = await getPermissions(userId || 0);
      setUserPermissionObject(permissions);
      setLoading(false);
    };
    fillData().then(() => {});
  }, [userId]);
  //#endregion
  //#region function
  const getPermissions = async (id: number): Promise<PermissionModel[]> => {
    let permissions = await getUserPermission(id, isArabic);
    return permissions ? _.sortBy(permissions, "ID") : [];
  };
  const handleVerticalChange = (checked: boolean, type: PermissionTypeEnum) => {
    setUserPermissionObject(
      (prevState) =>
        prevState?.map((permission) => {
          switch (type) {
            case PermissionTypeEnum.Save:
              return { ...permission, EnableSave: checked };
            case PermissionTypeEnum.Update:
              return { ...permission, EnableUpdate: checked };
            case PermissionTypeEnum.Delete:
              return { ...permission, EnableDelete: checked };
            case PermissionTypeEnum.Search:
              return { ...permission, EnableSearch: checked };
            case PermissionTypeEnum.Tablet:
              return { ...permission, IsEnabledForTablet: checked };
            case PermissionTypeEnum.All:
              return {
                ...permission,
                EnableSave: checked,
                EnableUpdate: checked,
                EnableDelete: checked,
                EnableSearch: checked,
                IsEnabledForTablet: checked,
              };
            default:
              return permission;
          }
        }) || null
    );
  };
  const handleHorizontalChange = (id: number, checked: boolean) => {
    setUserPermissionObject(
      (prevState) =>
        prevState?.map((permission) =>
          permission.ID === id
            ? {
                ...permission,
                EnableSave: checked,
                EnableUpdate: checked,
                EnableDelete: checked,
                EnableSearch: checked,
                IsEnabledForTablet: checked,
              }
            : permission
        ) || null
    );
  };
  const handleCheckBoxChange = (id: number, type: PermissionTypeEnum) => {
    setUserPermissionObject(
      (prevState) =>
        prevState?.map((permission) => {
          if (permission.ID === id) {
            switch (type) {
              case PermissionTypeEnum.Save:
                return { ...permission, EnableSave: !permission.EnableSave };
              case PermissionTypeEnum.Update:
                return {
                  ...permission,
                  EnableUpdate: !permission.EnableUpdate,
                };
              case PermissionTypeEnum.Delete:
                return {
                  ...permission,
                  EnableDelete: !permission.EnableDelete,
                };
              case PermissionTypeEnum.Search:
                return {
                  ...permission,
                  EnableSearch: !permission.EnableSearch,
                };
              case PermissionTypeEnum.Tablet:
                return {
                  ...permission,
                  IsEnabledForTablet: !permission.IsEnabledForTablet,
                };
              default:
                return permission;
            }
          }
          return permission;
        }) || null
    );
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase());
  };
  const filteredPermissions = userPermissionObject?.filter((permission) =>
    permission.Name?.toLowerCase().includes(searchQuery)
  );
  const handleSave = async () => {
    if (!userPermissionObject) return;
    setLoading(true);
    const data = userPermissionObject.map((perm) => ({
      ...perm,
      rowState: 2,
    }));
    await saveUserPermissions(data);
    setLoading(false);
    onActionEvent({
      action: ActionTypeEnum.UserPermissionSettingOperationCompleted,
      request: { userId },
    });
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}

      {userPermissionObject && userPermissionObject.length > 0 && (
        <>
          <div className="d-flex mb-3">
            <input
              type="text"
              className="form-control"
              placeholder={getLabelName("Search by page name")}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="table-responsive mx-height-500">
            <table className="table table-xs table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{getLabelName("Permission Name")}</th>
                  <th className="text-center">{getLabelName("All")}</th>
                  <th className="text-center">{getLabelName("Enable Save")}</th>
                  <th className="text-center">
                    {getLabelName("Enable Update")}
                  </th>
                  <th className="text-center">
                    {getLabelName("Enable Delete")}
                  </th>
                  <th className="text-center">
                    {getLabelName("Enable Search")}
                  </th>
                  <th className="text-center">
                    {getLabelName("IsEnabledForTablet")}
                  </th>
                </tr>
                <tr>
                  <th className="width-50"></th>
                  <th></th>
                  <th className="text-center">
                    <input
                      type="checkbox"
                      id={`vertical_all`}
                      className="form-check-input"
                      checked={userPermissionObject.every(
                        (p) =>
                          p.EnableSave &&
                          p.EnableUpdate &&
                          p.EnableDelete &&
                          p.EnableSearch &&
                          p.IsEnabledForTablet
                      )}
                      onChange={(o) =>
                        handleVerticalChange(
                          o.target.checked,
                          PermissionTypeEnum.All
                        )
                      }
                    />
                  </th>
                  <th className="text-center">
                    <input
                      type="checkbox"
                      id={`vertical_save`}
                      className="form-check-input"
                      checked={userPermissionObject.every((p) => p.EnableSave)}
                      onChange={(o) =>
                        handleVerticalChange(
                          o.target.checked,
                          PermissionTypeEnum.Save
                        )
                      }
                    />
                  </th>
                  <th className="text-center">
                    <input
                      type="checkbox"
                      id={`vertical_update`}
                      className="form-check-input"
                      checked={userPermissionObject.every(
                        (p) => p.EnableUpdate
                      )}
                      onChange={(o) =>
                        handleVerticalChange(
                          o.target.checked,
                          PermissionTypeEnum.Update
                        )
                      }
                    />
                  </th>
                  <th className="text-center">
                    <input
                      type="checkbox"
                      id={`vertical_delete`}
                      className="form-check-input"
                      checked={userPermissionObject.every(
                        (p) => p.EnableDelete
                      )}
                      onChange={(o) =>
                        handleVerticalChange(
                          o.target.checked,
                          PermissionTypeEnum.Delete
                        )
                      }
                    />
                  </th>
                  <th className="text-center">
                    <input
                      type="checkbox"
                      id={`vertical_search`}
                      className="form-check-input"
                      checked={userPermissionObject.every(
                        (p) => p.EnableSearch
                      )}
                      onChange={(o) =>
                        handleVerticalChange(
                          o.target.checked,
                          PermissionTypeEnum.Search
                        )
                      }
                    />
                  </th>
                  <th className="text-center">
                    <input
                      type="checkbox"
                      id={`vertical_tablet`}
                      className="form-check-input"
                      checked={userPermissionObject.every(
                        (p) => p.IsEnabledForTablet
                      )}
                      onChange={(o) =>
                        handleVerticalChange(
                          o.target.checked,
                          PermissionTypeEnum.Tablet
                        )
                      }
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredPermissions?.map((row, index) => (
                  <tr key={`permission-${index}`}>
                    <td>{index + 1}</td>
                    <td>{row.Name}</td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        id={`horizontal_${index}`}
                        className="form-check-input m-0"
                        checked={
                          row.EnableSave &&
                          row.EnableUpdate &&
                          row.EnableDelete &&
                          row.EnableSearch &&
                          row.IsEnabledForTablet
                        }
                        onChange={(o) =>
                          handleHorizontalChange(row.ID, o.target.checked)
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        id={`EnableSave_${index}`}
                        className="form-check-input m-0"
                        checked={row.EnableSave}
                        onChange={() =>
                          handleCheckBoxChange(row.ID, PermissionTypeEnum.Save)
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        id={`EnableUpdate${index}`}
                        className="form-check-input m-0"
                        checked={row.EnableUpdate}
                        onChange={() =>
                          handleCheckBoxChange(
                            row.ID,
                            PermissionTypeEnum.Update
                          )
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        id={`EnableDelete${index}`}
                        className="form-check-input m-0"
                        checked={row.EnableDelete}
                        onChange={() =>
                          handleCheckBoxChange(
                            row.ID,
                            PermissionTypeEnum.Delete
                          )
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        id={`EnableSearch${index}`}
                        className="form-check-input m-0"
                        checked={row.EnableSearch}
                        onChange={() =>
                          handleCheckBoxChange(
                            row.ID,
                            PermissionTypeEnum.Search
                          )
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        id={`IsEnabledForTablet${index}`}
                        className="form-check-input m-0"
                        checked={row.IsEnabledForTablet}
                        onChange={() =>
                          handleCheckBoxChange(
                            row.ID,
                            PermissionTypeEnum.Tablet
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <ButtonBox
              iconType="content-save"
              size="sm"
              type="submit"
              variant="primary"
              className="btn-gradient-primary mx-3 btn-fw"
              onClick={async () => {
                await handleSave();
              }}
            >
              {getLabelName("save")}
            </ButtonBox>
            <ButtonBox
              iconType="receipt"
              size="sm"
              type="button"
              variant="danger"
              className="btn-fw"
              onClick={async () => {
                onActionEvent({
                  action: ActionTypeEnum.CloseModelPopup,
                });
              }}
            >
              {getLabelName("Close")}
            </ButtonBox>
          </div>
        </>
      )}
    </>
  );
  //#endregion
};
