import {RoutePageConfigurations} from "../routeConfigurations/routePageConfig";
import {getAppConfig} from "../../utils";
export const SystemConfiguration = {
    //#region dynamic configurations
    get apiEndPoint_Configurations() {
        const config = getAppConfig();
        return {
            alyusrApiEndpoint: config?.apiEndPoint_Configurations?.alyusrApiEndpoint || "",
        };
    },
    get image_Configurations() {
        const config = getAppConfig();
        return {
            defaultWidth: config?.image_Configurations?.defaultWidth ,
            defaultHeight: config?.image_Configurations?.defaultHeight ,
        };
    },
    //#endregion
    //#region static configurations
    miscellaneous_Configurations: {
        appConfig: "8yELE",
        token: "cjJ1k4zPcy",
        homePageReloaded: "zN3ti",
        sidebarIsOpen:"rdzjajyky3",
        forceLogoutAfterPrint:"abv0p4sni4",
        isFirstLogin:"mlp0hqp9tv",
    },
    culture_Configurations: {
        defaultUiLanguage: "ar-AE",
        arabicCulture:"ar-AE",
        englishCulture:"en-US",
        currentCulture: "cha7kj4ovm1",
    },
    cacheKey_Configurations: {
        user: {
            user: "WquUJGSg",
        },
        menu:{
            menu: "=XQ#whTQ",
            menuItemName: "u8W9c",
            flattenMenuItem:"0j2s2twxsb",
        },
        setting:{
            companySettings: "8t5Mmn6p6V",
            settingsList: "Tf1Si3LlCp",
            posSettingsList: "et2KHSCjpm",
            userPosSettings: "c226hz1Stp",
            currentUserSettings:"i0v4reejfq",
            storeTransferSettingList:"y10pib9h6l",
        },
        miscellaneous:{
            dictionary: "PtrPwthR",
            privileges: "VmGp*@QQ",
            layoutName: "JHroyuJRsg"
        },
        lruCache:{
            companySetting:"qn4fhon3ph",
            menuFlatData:"kfbfc3x6yh"
        }
    },
    lruCache_Configurations:{
        maxCount:100,
        maxAge:36000000, //10 hour   -->1000 milliseconds (1 second)* 60 seconds (1 minute)*60 minutes (1 hour)
    },
    cookie_Configurations: {
        expiryMinutes: 1440,
        encryptKey: "cookieSecretKey",
        //currentCulture: "RdNnfa#h",
    },
    indexDb_Configurations: {
        indexDbName: "bcqeOt1X56Db",
        // itemObject:"ft7dzThMU8Obj"
    },
    security_Configurations: {
        encryptCryptoJSKey: "SZGlFNgutW",
    },
    others_Configurations: {
        domainName: process.env.REACT_APP_DomainName,
        logoutRedirectUrl: `${RoutePageConfigurations.miscellaneous.logoutRedirectPage}?iOZQilU=LyKiRiQEPDibKuuGECyJ`,
    },
    //#endregion
};
