import { Checkbox } from "antd";
import React from "react";

interface CheckboxProps {
  checked: boolean;
  onChange: any;
  id: string;
  labelName: string;
  inputName: string;
  onBlur?: any;
}

export const CheckBox: React.FC<CheckboxProps> = ({
  checked,
  id,
  inputName,
  labelName,
  onChange,
}) => {
  return (
      <div className="col d-flex align-items-center">
          <Checkbox
              checked={checked}
              name={inputName}
              onChange={onChange}
              id={id}
              style={{
                  marginRight: "0.5rem", // Adds space to the right of the checkbox
                  width: "auto",
              }}
          />
          <label
              htmlFor={inputName}
              className="form-label"
              style={{
                  marginLeft: "0.5rem", // Ensures label is spaced from checkbox
              }}
          >
              {labelName}
          </label>
      </div>
  );
};
