import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  LoadingBox,
  PrivilegesChecker,
  SearchBox,
  GridTable,
} from "..";
import {
  ActionTypeEnum,
  CategoryResponseModel,
  GenericSearchRequestModel,
  HasFormIdModel,
  LoadingManagerProps,
  LoadingObjectsEnum,
  RequestActionModel,
} from "../../models";
import {
  getLabelName,
  isArabicCurrentLanguage,
  sortByDate,
} from "../../utils";
import { getCategories } from "../../serviceBroker/categoryApiServiceBroker";
import { useSearchBox } from "../../hooks";

const getDisplayedCategories = (
  categories: CategoryResponseModel[],
  searchedWord: string
) => {
  const filteredData = categories.filter(
    ({ Name, Name_En }) =>
      Name_En.includes(searchedWord) || Name.includes(searchedWord)
  );
  return sortByDate(filteredData, "desc");
};

interface CategoryListProps extends LoadingManagerProps, HasFormIdModel {
  onActionEvent: (o: RequestActionModel) => void;
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  isRefresh: boolean;
}

export const CategoryList: FC<CategoryListProps> = ({
  onActionEvent = () => {},
  setIsRefresh,
  isRefresh = false,
  hideLoader,
  loadingData,
  showLoader,
  formId,
}) => {
  //#region variables
  const { onChange, onSearch, searchedWord, value } = useSearchBox();
  //#endregion
  //#region state
  const [searchParams] = useState<GenericSearchRequestModel>({
    pageSize: 10,
    pageNumber: 1,
  });
  const [data, setData] = useState<CategoryResponseModel[]>([]);
  const displayedData = getDisplayedCategories(data, searchedWord);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      showLoader(LoadingObjectsEnum.LIST);
      setData(await getList());
      hideLoader(LoadingObjectsEnum.LIST);
      setIsRefresh(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async () => {
      if (isRefresh) {
        showLoader(LoadingObjectsEnum.LIST);
        setData(await getList());
        hideLoader(LoadingObjectsEnum.LIST);
        setIsRefresh(false);
      }
    };
    fillData().then(() => {});
  }, [isRefresh]);
  //#endregion
  //#region functions
  const getList = async (): Promise<CategoryResponseModel[]> => {
    const list = await getCategories();
    return list !== null &&
      list !== undefined &&
      list.Result !== null &&
      list.Result !== undefined &&
      list.Result?.length !== 0
      ? list.Result
      : [];
  };
  //#endregion
  //#region html
  return (
    <LoadingBox isLoading={loadingData.LIST}>
      <PrivilegesChecker formId={formId} action="EnableSearch">
        <SearchBox
          name={getLabelName("category")}
          onChangeHandler={onChange}
          searchedWord={value}
          onSearchHandler={onSearch}
        />
      </PrivilegesChecker>
      <GridTable
        //columns={columns}
        data={displayedData}
        totalRows={data.length}
        currentPage={searchParams.pageNumber}
        pageSize={searchParams.pageSize}
        paginationType="client"
        columnsProps={{
            actions: [ActionTypeEnum.Update, ActionTypeEnum.Delete],
            isArabic: isArabicCurrentLanguage(),
            onActionEvent,
        }}
        formId={formId}
      />
    </LoadingBox>
  );
  //#endregion
};
