import React, {FC, useEffect, useState} from "react";
import { PrintTypeEnum, UserAlertResponseModel } from "../../models";
import {
    getLabelName,
    getUserId,
    isArabicCurrentLanguage,
} from "../../utils";
import {  GenericModelPopupPdfReportViewer } from "..";
import circleSVG from "../../assets/images/dashboard/circle.svg";
import "./dashBoard.css";
import {getUserSetting} from "../../serviceBroker/userApiServiceBroker";
export const DashBoardAlertSummary: FC<{ request: UserAlertResponseModel[] }> = ({ request }) => {
    //#region state
    const [printState, setPrintState] = useState<{
        showPrintoutPdfModel: boolean;
        keysValues: { key: string; value: any }[];
    }>({
        showPrintoutPdfModel: false,
        keysValues: [],
    });
    const [showAlert, setShowAlert] = useState<boolean>(false);
    //#endregion
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    const printRecord = (row: UserAlertResponseModel) => {
        setPrintState({
            showPrintoutPdfModel: true,
            keysValues: [
                { key: "alertSearch.alertTypeId", value: row.ID },
                { key: "alertSearch.messageArabic", value: row.MessageArabic },
                { key: "alertSearch.messageEnglish", value: row.MessageEnglish },
                { key: "alertSearch.userId", value: getUserId() },
            ],
        });
    };
    const getCardClassBasedOnKey = () => {
        const classesList: string[] = [
            "bg-gradient-primary",
            "bg-gradient-secondary",
            "bg-gradient-success",
            "bg-gradient-danger",
           // "bg-gradient-warning",
            "bg-gradient-info",
            "bg-gradient-dark",
        ];
        return classesList[Math.floor(Math.random() * classesList.length)];
    };
    //#endregion
    //#region useEffect

    useEffect(() => {
        const fillData = async () => {
            const userSettingResponse = await getUserSetting(getUserId(), true);
            setShowAlert(userSettingResponse?.ShowAlert || false);
        };
        fillData().then(() => {});
    }, []);
    //#endregion
    //#region html
    return  showAlert && request && request.length > 0 ? (
        <>
            <div className="dashboard-alert-summary">
                {/* Header */}
                <div className="dashboard-header">
                    <h2>{getLabelName("Alert")}</h2>
                </div>
                {/* Grid */}
                <div className="dashboard-grid">
                    {request.map((row, index) => (
                        <div
                            key={`cardId-${index}`}
                            className={`dashboard-tile ${getCardClassBasedOnKey()}`}
                            onClick={() => printRecord(row)}
                        >
                            <div className="dashboard-tile-body">
                                <img
                                    src={circleSVG}
                                    className="dashboard-icon"
                                    alt="icon"
                                />
                                <h4 className="dashboard-tile-title">
                                    {isArabic ? row.MessageArabic : row.MessageEnglish}
                                </h4>
                                <p className="dashboard-tile-count">
                                    {getLabelName("Count")}: {row.Count}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {printState.showPrintoutPdfModel && (
                <GenericModelPopupPdfReportViewer
                    keys={printState.keysValues}
                    type={PrintTypeEnum.UserAlertReport}
                    onCloseEvent={() => {
                        setPrintState({ showPrintoutPdfModel: false, keysValues: [] });
                    }}
                />
            )}
        </>
    ) : null;
    //#endregion
};


// return request !== null && request.length !== 0 ? (<>
    //     <div className="row">
    //         <div className="col-12 grid-margin">
    //             <div className="card">
    //                 <div className="card-body">
    //                     <h4 className="card-title"></h4>
    //                     <div className="table-responsive">
    //                         <GridTable
    //                             columns={columns}
    //                             data={request}
    //                             totalRows={1000}
    //                             currentPage={1}
    //                             pageSize={10000}
    //                             paginationType="none"
    //                         />
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    //     {printState.showPrintoutPdfModel && (
    //         <GenericModelPopupPdfReportViewer
    //             keys={printState.keysValues}
    //             type={PrintTypeEnum.UserAlertReport}
    //             onCloseEvent={() => {
    //                 setPrintState({...printState, showPrintoutPdfModel: false, keysValues: []})
    //             }}
    //         />
    //     )}
    // </>) : null;