import React, { useMemo, useState } from "react";
import {
    GridColumnsHookParamsModel,
    TableActionColumnsStyleEnum,
    PageEnum, ActionTypeEnum,
} from "../../../../../models";
import { Dropdown } from "react-bootstrap";
import {
    CustomDropdown,
    LoadingBox,
    ModelDialogBox,
    TableComponentV2,
} from "../../../../index";
import { getFormPrivileges, getLabelName, isArabicCurrentLanguage } from "../../../../../utils";
import RenderGridColumnsSettings from "../rendering/renderGridColumnsSettings";
import {useGridColumns} from "../../../../../hooks";

//#region interface
interface ServerColumnsComponentProps {
    data: any[];
    tableProps: any;
    columnsProps: GridColumnsHookParamsModel<any>;
    style?: TableActionColumnsStyleEnum | null;
    formId?: number | null;
}
//#endregion
//#region html
export const ServerGridComponent: React.FC<ServerColumnsComponentProps> = ({
                                                                               data,
                                                                               tableProps,
                                                                               columnsProps,
                                                                               style,
                                                                               formId,
                                                                           }) => {
    const { gridColumns, fetchGridColumns, isGridColumnsLoading, columnsSettings, onActionEvent } = useGridColumns(
        {
            ...columnsProps,
            data,
        },
        style || TableActionColumnsStyleEnum.DropDownText,
        formId || PageEnum.none
    );
    const { ColumnsSettingId: gridId } = getFormPrivileges(formId ?? PageEnum.none);
    const [isGridColumnsModalOpen, setIsGridColumnsOpen] = useState(false);

    const columnsWithRowIndex = useMemo(
        () => [
            {
                name: getLabelName("#"),
                selector: (_row: any, index: number) => index + 1,
                width: "60px",
            },
            ...gridColumns,
        ],
        [gridColumns]
    );

    if (data.length === 0)
        return <p className="text-center">{getLabelName("No previous data to show")}</p>;

    return (
        <LoadingBox isLoading={isGridColumnsLoading}>
            <div className="position-relative">
                <CustomDropdown
                    style={{
                        position: "absolute",
                        left: isArabicCurrentLanguage() ? "20px" : "unset",
                        right: isArabicCurrentLanguage() ? "unset" : "20px",
                        top: "11px",
                        zIndex: 100,
                    }}
                    dropdownIcon={{ color: "black", size: 20 }}
                >
                    <Dropdown.Item
                        style={{ minWidth: "15rem" }}
                        onClick={() => setIsGridColumnsOpen(true)}
                    >
                        {getLabelName("Show/Hide Columns")}
                    </Dropdown.Item>
                </CustomDropdown>
                <TableComponentV2
                    {...tableProps}
                    data={data}
                    columns={columnsWithRowIndex}
                    onRowDoubleClicked={(row: any) => {
                        onActionEvent({
                            action: ActionTypeEnum.Update,
                            request: row,
                            id: row.ID,
                        });
                    }}
                />
            </div>
            <ModelDialogBox
                isModelVisible={isGridColumnsModalOpen}
                isXCloseButtonVisible={true}
                isEscapeCloseEnabled={true}
                size="xxl"
                onCloseEvent={() => setIsGridColumnsOpen(false)}
                title={getLabelName("columns")}
            >
                <RenderGridColumnsSettings
                    gridId={gridId}
                    onComplete={() => setIsGridColumnsOpen(false)}
                    fetchGridColumns={fetchGridColumns}
                    columnsSettings={columnsSettings}
                    isColumnsLoading={isGridColumnsLoading}
                />
            </ModelDialogBox>
        </LoadingBox>
    );
};
//#endregion
