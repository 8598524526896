import React, {FC, useEffect, useState} from "react";
import {
  ActionTypeEnum,
  HasFormIdModel,  RequestActionModel,
  UserRegistrationResponseModel,
} from "../../models";
import {  isArabicCurrentLanguage } from "../../utils";
import {GridTable, LoadingBox, PrivilegesChecker, SearchBox} from "..";
import {getUsers} from "../../serviceBroker/userApiServiceBroker";
import {useSearchBox} from "../../hooks";

interface UsersListProps extends HasFormIdModel {
  onActionEvent?:  (o: RequestActionModel) => void;
  isRefresh: boolean;
}

export const UsersList: FC<UsersListProps> = ({
  onActionEvent = () => {},
  formId,isRefresh=false
}) => {
  //#region variables
  const isArabic: boolean = isArabicCurrentLanguage();
  const defaultPageSize: number = 50;
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserRegistrationResponseModel[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserRegistrationResponseModel[]>([]);
  const {onChange, onSearch, searchedWord, value} = useSearchBox();
  //#endregion
  //#region functions
  const getAllUsers = async () => {
    setLoading(true);
    const userList = await getUsers();
    setUsers(userList);
    setFilteredUsers(userList);
    setLoading(false);
  };
  const isUserAdminSort = (
    rowA: UserRegistrationResponseModel,
    rowB: UserRegistrationResponseModel
  ) => {
    const a = rowA.IsAdmin;
    const b = rowB.IsAdmin;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      await getAllUsers();
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    if (isRefresh) {
      const fillData = async () => {
        await getAllUsers();
      };
      fillData().then(() => {});
      onActionEvent({action:ActionTypeEnum.Refresh,request:false});
    }
  }, [isRefresh]);
  useEffect(() => {
    const searchValue = searchedWord && searchedWord.trim() !== "" ? searchedWord : value;
    if (!searchValue.trim()) {
      setFilteredUsers(users);
    } else {
      // Filter based on Arabic or English name
      setFilteredUsers(
          users.filter(
              (p) =>
                  p.User_Name.toLowerCase().trim().includes(searchValue.toLowerCase().trim()) || // Match Username
                  p.Name.toLowerCase().trim().includes(searchValue.toLowerCase().trim()) || // Match Arabic name
                  p.Name_En.toLowerCase().trim().includes(searchValue.toLowerCase().trim()) // Match English name
          )
      );
    }
  }, [searchedWord,value]);
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox/>}
      <PrivilegesChecker formId={formId} action="EnableSearch">
        <SearchBox
            onChangeHandler={onChange}
            onSearchHandler={onSearch}
            searchedWord={value}
            name="name"
        />
      </PrivilegesChecker>
      <GridTable
        columnsProps={{
          actions: [
            ActionTypeEnum.GrantPermissions,
            ActionTypeEnum.UserSetting,
            ActionTypeEnum.TransactionSetting,
            ActionTypeEnum.POSSetting,
            ActionTypeEnum.Update,
            ActionTypeEnum.Delete,
          ],
          isArabic,
          onActionEvent: onActionEvent,
        }}
        formId={formId}
        data={filteredUsers}
        totalRows={filteredUsers.length}
        currentPage={1}
        paginationType="client"
        pageSize={defaultPageSize}
        // style={TableActionColumnsStyleEnum.DropDownText}
        // columns={generateColumnList()}
      />
    </>
  );
  //#endregion
};
