import React, { FC, useEffect, useRef, useState } from "react";
import {
  ActionTypeEnum,
  CloseDaySearchModel,
  LookupItemModel,
  LookupTypeEnum,
  PageEnum,
  PrintTypeEnum,
  RequestActionModel,
  SearchCloseDayRequestModel,
} from "../../models";
import {
  generateGuid,
  getLabelName,
  getLookUpItemValue,
  getPageNameByPageId,
  isArabicCurrentLanguage,
} from "../../utils";
import { Accordion } from "react-bootstrap";
import {
  ButtonBox,
  CloseDayDetails,
  GenericModelPopupPdfReportViewer,
  InputDatePicker,
  LoadingBox,
  SelectBox,
  GridTable,
  TextBox,
  PrivilegesChecker,
} from "..";
import { searchCloseDayData } from "../../serviceBroker/closeDayApiServiceBroker";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";

export const CloseDayList: FC<{ formId?: number | null }> = ({
  formId = PageEnum.POSStation,
}) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const defaultPageSize: number = 50;
  const initialValues: SearchCloseDayRequestModel = {
    pageNumber: 1,
    pageSize: defaultPageSize,
  };
  //#endregion
  //#region state
  const [userLookUps, setUserLookUps] = useState<LookupItemModel[]>([]);
  const [printTransactionId, setPrintTransactionId] = useState(0);
  const [pageName, setPageName] = useState<string | null>(null);
  const [showPrintoutPdfModel, setShowPrintoutPdfModel] = useState(false);
  const [showCloseDayModel, setShowCloseDayModel] = useState(false);
  const [stationId, setStationId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [searchParams, setSearchParams] =
    useState<SearchCloseDayRequestModel>(initialValues);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CloseDaySearchModel[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const usersSelectBoxMultiselectRef = useRef<any>();
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      const users = await getLookupByType(LookupTypeEnum.Users, false, false);
      setUserLookUps(users);
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      await getTransactions(searchParams);
      setLoading(false);
    };
    fillData().then(() => {});
  }, [searchParams.pageNumber, searchParams.pageSize]);
  useEffect(() => {
    if (formId !== 0 && formId !== null && formId !== undefined) {
      setPageName(getPageNameByPageId(formId));
    }
  }, [isArabicCurrentLanguage()]);
  //#endregion
  //#region functions
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
        setLoading(true);
        await getTransactions(searchParams);
        setShowCloseDayModel(false);
        setLoading(false);
        break;
      case ActionTypeEnum.CloseModelPopup:
        setShowCloseDayModel(false);
        break;
      case ActionTypeEnum.CloseDay:
        setStationId(request.id || 0);
        setUserId(request.request.User_ID);
        setLoading(true);
        setShowCloseDayModel(true);
        setLoading(false);
        break;
      case ActionTypeEnum.PrintPdf:
        setPrintTransactionId(request.id || 0);
        setShowPrintoutPdfModel(true);
        break;
    }
  };
  const getTransactions = async (searchReq: SearchCloseDayRequestModel) => {
    const result = await searchCloseDayData(searchReq);
    setData(result?.Result || []);
    setTotalRows(result?.TotalRecoredCount || 0);
  };
  const onCurrentPageChange = async (pageNumber: number) => {
    if (pageNumber !== searchParams.pageNumber) {
      const obj = { ...searchParams };
      obj.pageNumber = pageNumber;
      setSearchParams(obj);
    }
  };
  const onPageSizeChange = async (pageSize: number) => {
    if (pageSize !== searchParams.pageSize) {
      const obj = { ...searchParams, pageNumber: 1 };
      obj.pageSize = pageSize;
      setSearchParams(obj);
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {showPrintoutPdfModel && (
        <GenericModelPopupPdfReportViewer
          keys={[{ key: "stationId", value: printTransactionId }]}
          type={PrintTypeEnum.CloseDay}
          onCloseEvent={() => {
            setShowPrintoutPdfModel(false);
          }}
        />
      )}
      {showCloseDayModel && (
        <CloseDayDetails
          userId={userId}
          stationId={stationId}
          onActionEvent={async (o: RequestActionModel) => {
            await handleAction(o);
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{pageName}</Accordion.Header>
          <Accordion.Body>
            <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
              <SelectBox
                labelName={getLabelName("User")}
                isSingleSelect={true}
                selectedValues={
                  searchParams.userId ? [searchParams.userId.toString()] : [""]
                }
                source={userLookUps}
                onStatusChange={(e: LookupItemModel) => {
                  const val = getLookUpItemValue(e);
                  setSearchParams({
                    ...searchParams,
                    userId: val === null ? null : Number(val),
                  });
                }}
                multiselectRef={usersSelectBoxMultiselectRef}
              />

              <InputDatePicker
                selectedDate={
                  searchParams.fromDate
                    ? new Date(searchParams.fromDate)
                    : undefined
                }
                InputLabel={"From Date"}
                selectsEnd
                startDate={
                  searchParams.fromDate
                    ? new Date(searchParams.fromDate)
                    : undefined
                }
                className="form-control"
                onChange={(date) => {
                  setSearchParams({
                    ...searchParams,
                    fromDate: date === null ? undefined : date.toISOString(),
                  });
                }}
              />
              <InputDatePicker
                selectedDate={
                  searchParams.toDate
                    ? new Date(searchParams.toDate)
                    : undefined
                }
                InputLabel={"To Date"}
                selectsEnd
                startDate={
                  searchParams.toDate
                    ? new Date(searchParams.toDate)
                    : undefined
                }
                className="form-control"
                onChange={(date) => {
                  setSearchParams({
                    ...searchParams,
                    toDate: date === null ? undefined : date.toISOString(),
                  });
                }}
              />
              <TextBox
                labelName={getLabelName("is closed")}
                type="checkbox"
                onChange={(e: any) => {
                  const isChecked = e.target.checked;

                  setSearchParams({
                    ...searchParams,
                    isStationStillOpen: isChecked,
                  });
                }}
                inputName={"isStationStillOpen"}
                inputValue={searchParams.isStationStillOpen}
              />
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end mt-4 mb-4 gap-3">
              <PrivilegesChecker
                formId={PageEnum.POSStation}
                action="EnableSearch"
              >
                <ButtonBox
                  className="btn-sm"
                  variant="primary"
                  onClick={async () => {
                    setLoading(true);
                    await getTransactions(searchParams);
                    setLoading(false);
                  }}
                >
                  {getLabelName("Search")}
                </ButtonBox>
              </PrivilegesChecker>
              <ButtonBox
                variant="danger"
                className="btn-sm"
                onClick={async () => {
                  setLoading(true);
                  usersSelectBoxMultiselectRef.current.clearValue();
                  setSearchParams({
                    ...initialValues,
                    searchGuid: generateGuid(),
                  });
                  await getTransactions(initialValues);
                  setLoading(false);
                }}
              >
                {getLabelName("Cancel")}
              </ButtonBox>
            </div>
            <GridTable
              //columns={columns}
              data={data}
              totalRows={totalRows}
              currentPage={searchParams.pageNumber || 1}
              pageSize={searchParams.pageSize || defaultPageSize}
              onCurrentPageChange={onCurrentPageChange}
              onPageSizeChange={onPageSizeChange}
              paginationType="server"
              formId={PageEnum.POSStation}
              columnsProps={{
                actions: [ActionTypeEnum.PrintPdf, ActionTypeEnum.CloseDay],
                isArabic,
                onActionEvent: handleAction,
              }}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
