import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import "./i18n";
import {  secureLocalStorageGet, secureLocalStorageSet} from "../../../utils";
import {SystemConfiguration} from "../../../configuration";
import arabicFlag from "../../../assets/images/flags/arabicFlag.png";
import englishFlag from "../../../assets/images/flags/englishFlag.png";

const languageLocalStorageName = SystemConfiguration.culture_Configurations.currentCulture;
const defaultUiLanguage = SystemConfiguration.culture_Configurations.defaultUiLanguage;
const getDefaultLanguage = (): string => {
    // @ts-ignore
    const localeValueFomStorage = secureLocalStorageGet(languageLocalStorageName);
    if (
        localeValueFomStorage === null ||
        localeValueFomStorage === undefined ||
        localeValueFomStorage === ""
    ) {
        secureLocalStorageSet(languageLocalStorageName, defaultUiLanguage);
        return defaultUiLanguage;
    }
    return localeValueFomStorage as string;
};
const LangSwitcherReactI18: FC = () => {
    //#region state
    const {i18n} = useTranslation();
    const [language, setLanguage] = useState(getDefaultLanguage);
    //#endregion
    //#region function
    const handleLanguageSelect = (e: any) => {
        i18n.changeLanguage(e.target.value).then(() => {
        });
        setLanguage(e.target.value);
        secureLocalStorageSet(languageLocalStorageName || "", e.target.value);
    };
    const handleLanguageChange = (language: string) => {
        i18n.changeLanguage(language).then(() => {});
        setLanguage(language);
        secureLocalStorageSet(languageLocalStorageName || "", language);
        // Set the language in your application state or context if necessary
        // Then refresh the page to apply the changes
        window.location.reload();
    };
//#endregion
    //#region html
    return (
        <>
            <div className="language-switch">
                {language !== "ar-AE" && (
                    <img
                        src={arabicFlag}
                        alt="Arabic"
                        className="btn-ar"
                        onClick={() => handleLanguageChange(SystemConfiguration.culture_Configurations.arabicCulture)}
                    />
                )}
                {language === "ar-AE" && (
                    <img
                        src={englishFlag}
                        alt="English"
                        className="btn-en"
                        onClick={() => handleLanguageChange(SystemConfiguration.culture_Configurations.englishCulture)}
                    />
                )}
            </div>
        </>
    );
    //#endregion
};
export {LangSwitcherReactI18, getDefaultLanguage};
