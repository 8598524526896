import React, {Dispatch, FC, SetStateAction, useEffect, useRef} from "react";
import {AntdTableComponent, ButtonBox} from "..";
import {
    LookupItemModel,
    RequestActionModel,
    RowStateEnum, ItemModel,
} from "../../models";
import {
  generateGuid,
  getLabelName,
  isArabicCurrentLanguage,
} from "../../utils";
import {handleGenerateAntdItemUnitColumns} from "./uiHelper/itemUnitDataTableColumns";
import { getItemUnits, handleAddItemUnitRow} from "./businessLogic/itemStoresBl";

export const ItemUnitManager: FC<{
  setState: Dispatch<SetStateAction<ItemModel>>,
  state: ItemModel,
  setIsRefresh: Dispatch<SetStateAction<boolean>>,
  isRefresh: boolean,
  taxValue:number,
  unitList: LookupItemModel[];
  onActionEvent: (o: RequestActionModel) => void;
}> = ({ setState,state,setIsRefresh,isRefresh, taxValue, unitList, onActionEvent = () => {} }) => {
    //#region state
    const prevStateRef = useRef<ItemModel>();
    //#endregion
    //#region variables
    const currentItemUnits = state?.ItemsInstores?.[0]?.Item_unit || [];
    //#endregion
    //#region useEffect
    useEffect(() => {
        if (isRefresh) {
            const updatedItemUnits = currentItemUnits.map(unit => ({
                ...unit,
                Unit_ID: Number(unitList[0].value),
                factor: 1,
                rowKey: generateGuid()
            }));
            setState(prev => ({
                ...prev,
                ItemsInstores: [{
                    ...prev?.ItemsInstores?.[0],
                    Item_unit: updatedItemUnits
                }]
            }));
        }
        prevStateRef.current = state;
        setIsRefresh(false);
    }, [isRefresh]);
    //#endregion
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    const generateItemUnitsAntdColumns = [...handleGenerateAntdItemUnitColumns(taxValue, unitList, setState, isRefresh)];
    //#endregion
    //#region html
    return (
        <>
            <div className="item-unit-list mt-3">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                    <ButtonBox
                        iconType="plus"
                        variant="primary"
                        size="sm"
                        className="mb-3"
                        onClick={() => {
                            handleAddItemUnitRow(
                                setState,
                                unitList[0].value === null ? 0 : +unitList[0].value
                            );
                        }}
                    >
                        {getLabelName("Add")}
                    </ButtonBox>
                </div>
                <AntdTableComponent
                    data={getItemUnits(state).filter(
                        (row) => row.rowState !== RowStateEnum.Delete
                    )}
                    columns={generateItemUnitsAntdColumns}
                    rowKey="rowKey"
                />
            </div>
        </>
    );
    //#endregion
};
