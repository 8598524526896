import React, { useState } from "react";
import { getLabelName, isArabicCurrentLanguage } from "../../../../../utils";
import {LoadingBox, ErrorValidationBox, ButtonBox} from "../../../..";
import DataTable from "react-data-table-component";
import { generateGridSettingsColumns } from "../utils/generateGridSettingsColumns";
import { saveGridColumns } from "../../../../../serviceBroker/commonApiServiceBroker";
import {
  GridColumnsResponseModel,
  RowStateEnum,
  ValidationErrorModel,
} from "../../../../../models";

interface GridColumnsSettingsProps {
  onComplete: () => void;
  gridId: number;
  fetchGridColumns: () => Promise<void>;
  isColumnsLoading: boolean;
  columnsSettings: GridColumnsResponseModel[];
}

const RenderGridColumnsSettings: React.FC<GridColumnsSettingsProps> = ({
  onComplete,
  fetchGridColumns,
  columnsSettings,
  isColumnsLoading,
}) => {
  //#region variables
  const maxTotalWidth: number = 90;
  //#endregion
  //#region state
  const [columns, setColumns] =
      useState<GridColumnsResponseModel[]>(columnsSettings);
  const [errors, setErrors] = useState<ValidationErrorModel[]>([]);
  const [modifiedGridColumnsIDs, setModifiedGridColumnsIDs] = useState<
      number[]
  >([]);
  const isArabic = isArabicCurrentLanguage();
  const [isSaving, setIsSaving] = useState(false);
  const isLoading = isColumnsLoading || isSaving;
  //#endregion
  //#region functions
  const validatorGridColumns = (columns: GridColumnsResponseModel[]) => {
    return validateTotalWidthBelowHundred(columns);
  };
  const validateTotalWidthBelowHundred = (
      columns: GridColumnsResponseModel[]
  ) => {
    const totalWidth = columns
        .filter(({Visable}) => Visable)
        .reduce((acc, cur) => acc + Number(cur.Width), 0);
    return totalWidth <= maxTotalWidth;

  };
  const handleFieldChange = (
      fieldName: string,
      value: string | boolean,
      ID: number
  ) => {
    // update columns state
    setColumns((prev) =>
        prev.map((field) => {
          if (field.ID === ID)
            return {
              ...field,
              [fieldName]: value,
            };
          else return field;
        })
    );
    // add column to modified columns only
    let isModifiedColumn = modifiedGridColumnsIDs.find(
        (settingID) => settingID === ID
    );
    if (!isModifiedColumn) {
      setModifiedGridColumnsIDs((prev) => [...prev, ID]);
    }
  };
  const validateColumns = (columns: GridColumnsResponseModel[]): boolean => {

    // Check if any checkbox is selected
    const isAnyCheckboxSelected = columns.some(({Visable}) => Visable);
    if (!isAnyCheckboxSelected) {
      setErrors([
        {
          MessageAr: getLabelName("Please select at least one column."),
          MessageEn: getLabelName("Please select at least one column."),
        },
      ]);
      return false;
    }
    // Validate total width
    const areColumnsValid = validatorGridColumns(columns);
    if (!areColumnsValid) {
      setErrors([
        {
          MessageAr: `${getLabelName("total width cannot be more than")} ${maxTotalWidth}`,
          MessageEn: `${getLabelName("total width cannot be more than")} ${maxTotalWidth}`,
        },
      ]);
      return false;
    }

    // Clear errors if validation passes
    setErrors([]);
    return true;
  };
  const handleSaveModifiedColumns = async () => {
    // Validate columns
    const isValid = validateColumns(columns);
    if (!isValid) return; // Exit if validation fails
    if (modifiedGridColumnsIDs.length === 0) return onComplete();
    try {
      const gridColumns = columns
          .filter(({ID}) => modifiedGridColumnsIDs.includes(ID))
          .map((columns) => ({
            ...columns,
            rowState: RowStateEnum.Update,
          }));

      setIsSaving(true);
      await saveGridColumns(gridColumns);
      onComplete();
      await fetchGridColumns();
      setIsSaving(false);
    } catch (err) {
      console.log(err);
      onComplete();
    }
  };
//#endregion
  //#region html
  return (
      <LoadingBox isLoading={isLoading}>
        <div className="p-3 flex flex-col">
          <div style={{maxHeight: "70vh", overflow: "auto"}}>
            <ErrorValidationBox errors={errors}/>
            <DataTable
                columns={generateGridSettingsColumns(isArabic, handleFieldChange)}
                data={columns}
                customStyles={{
                  headCells: {
                    style: {
                      whiteSpace: "normal",   // Allow wrapping
                      wordBreak: "break-word", // Break long words
                      textAlign: "center",    // Optional alignment
                      lineHeight: "1.2",      // Adjust spacing
                      padding: "8px 10px",    // Add proper padding
                      minHeight: "48px",      // Ensure a consistent header height
                    },
                  },
                  cells: {
                    style: {
                      whiteSpace: "normal",  // Ensure cells also wrap if needed
                    },
                  },
                }}
            />
          </div>
          <div className="d-flex justify-content-between mt-3">
            <ButtonBox
                size="sm"
                type="submit"
                variant="primary"
                className="btn btn-orange"
                onClick={handleSaveModifiedColumns}
            >
              {getLabelName("save")}
            </ButtonBox>
            <ButtonBox
                size="sm"
                type="button"
                // variant="outline-primary"
                variant="danger"
                className="btn btn-orange"
                onClick={onComplete}
            >
              {getLabelName("cancel")}
            </ButtonBox>
          </div>
        </div>
      </LoadingBox>
  );
  //#endregion
};
export default RenderGridColumnsSettings;
