import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ActionTypeEnum,
  BeneficiaryTypeEnum,
  RequestActionModel,
  TransactionDetailResponseModel,
  InvoiceTypeEnum,
} from "../../../../models";
import { getLabelName } from "../../../../utils";
import {
  ModelDialogBox,
  BeneficiaryButtonList,
  RestaurantTables,
} from "../../..";

export interface POSInvoiceTypeProps {
  setTransactionData: Dispatch<SetStateAction<TransactionDetailResponseModel>>;
  children: ReactNode;
  InvoiceType?: null | InvoiceTypeEnum;
  onActionEvent: (o: any) => Promise<void>;
}

interface POSInvoiceTypeComponent extends FC<POSInvoiceTypeProps> {
  DeliveryButton: FC<{
    onActionEvent: (o: any) => Promise<void>;
  }>;
  TakeawayButton: FC<{
    onActionEvent: (o: any) => Promise<void>;
  }>;
  DineInButton: FC<{
    table_id: number;
    onActionEvent: (o: any) => Promise<void>;
  }>;
}

const InvoiceTypeContext = createContext<any>({
  InvoiceType: InvoiceTypeEnum.TAKEAWAY,
  Table_ID: 0,
});

const POSInvoiceType: POSInvoiceTypeComponent = ({
  children,
  InvoiceType,
  setTransactionData,
  onActionEvent,
}) => {
  const setInvoiceType = (InvoiceType: InvoiceTypeEnum) =>
    setTransactionData((prev) => ({ ...prev, InvoiceType: InvoiceType }));

  const setInvoiceTable = (tableID: number) => {
    setTransactionData((prev) => ({ ...prev, Table_ID: tableID }));
  };

  const handleReset = () => {
    setInvoiceType(InvoiceTypeEnum.TAKEAWAY);
  };
  useEffect(() => {
    if (InvoiceType === null) handleReset();
  }, [InvoiceType]);
  return (
    <InvoiceTypeContext.Provider
      value={{ InvoiceType, setInvoiceType, handleReset, setInvoiceTable }}
    >
      <div className="mb-3 d-flex gap-2">{children}</div>
    </InvoiceTypeContext.Provider>
  );
};

const DeliveryButton: React.FC<{
  onActionEvent: (o: any) => Promise<void>;
}> = ({ onActionEvent }) => {
  const [showSelectBeneficiary, setShowSelectBeneficiary] = useState(false);
  const { setInvoiceType } = useContext(InvoiceTypeContext);

  const handleSelect = () => {
    setShowSelectBeneficiary(true);
  };

  return (
    <>
      <SelectInvoiceButton
        handleSelect={handleSelect}
        label={getLabelName("DELIVERY")}
        InvoiceType={InvoiceTypeEnum.DELIVERY}
      />
      <ModelDialogBox
        isXCloseButtonVisible={true}
        isModelVisible={showSelectBeneficiary}
        isCloseButtonVisible={false}
        isEscapeCloseEnabled={true}
        title={getLabelName("Customer")}
        size="lg"
        onCloseEvent={() => {
          setShowSelectBeneficiary(false);
        }}
      >
        <BeneficiaryButtonList
          type={BeneficiaryTypeEnum.Customer}
          onActionEvent={async (o: RequestActionModel) => {
            setShowSelectBeneficiary(false);
            await onActionEvent(o);
            // await onActionEvent({ action: ActionTypeEnum.ResetObject });
            setInvoiceType(InvoiceTypeEnum.DELIVERY);
          }}
        />
      </ModelDialogBox>
    </>
  );
};

const DineInButton: FC<{
  table_id: number;
  onActionEvent: (o: any) => Promise<void>;
}> = ({ table_id, onActionEvent }) => {
  const [showTablesList, setShowTablesList] = useState(false);
  const { setInvoiceTable, setInvoiceType, InvoiceType } =
    useContext(InvoiceTypeContext);
  const handleSelect = () => {
    setShowTablesList(true);
  };
  const onSelectTable = async (
    tableID: number,
    tableReserved?: TransactionDetailResponseModel | null
  ) => {
    await onActionEvent({ action: ActionTypeEnum.ResetObject });
    setShowTablesList(false);
    setInvoiceTable(tableID);
    setInvoiceType(InvoiceTypeEnum.DINE_IN);
    if (tableReserved)
      await onActionEvent({
        action: ActionTypeEnum.SelectReservedTransaction,
        request: tableReserved,
      });
  };
  return (
    <>
      <SelectInvoiceButton
        handleSelect={handleSelect}
        label={getLabelName("DINE_IN")}
        InvoiceType={InvoiceTypeEnum.DINE_IN}
      />
      <ModelDialogBox
        isXCloseButtonVisible={true}
        isModelVisible={showTablesList}
        isCloseButtonVisible={false}
        isEscapeCloseEnabled={true}
        title={getLabelName("Tables")}
        size="lg"
        onCloseEvent={() => {
          setShowTablesList(false);
        }}
      >
        <RestaurantTables onSelectTable={onSelectTable} table_id={table_id} />
      </ModelDialogBox>
    </>
  );
};

const TakeawayButton: FC<{
  onActionEvent: (o: any) => Promise<void>;
}> = ({ onActionEvent }) => {
  const { setInvoiceType, handleReset } = useContext(InvoiceTypeContext);
  const handleSelect = async () => {
    handleReset();
    await onActionEvent({ action: ActionTypeEnum.ResetObject });

    setInvoiceType(InvoiceTypeEnum.TAKEAWAY);
  };

  return (
    <SelectInvoiceButton
      handleSelect={handleSelect}
      label={getLabelName("takeaway")}
      InvoiceType={InvoiceTypeEnum.TAKEAWAY}
    />
  );
};

const SelectInvoiceButton: React.FC<{
  handleSelect: () => void;
  label: string;
  InvoiceType: InvoiceTypeEnum;
}> = ({ handleSelect, label, InvoiceType }) => {
  const { InvoiceType: selectedInvoiceType } = useContext(InvoiceTypeContext);
  const isSelected = selectedInvoiceType === InvoiceType;

  return (
    <button
      className={`btn ${isSelected ? "btn-info" : "btn-secondary"} `}
      onClick={handleSelect}
    >
      {label}
    </button>
  );
};

POSInvoiceType.DeliveryButton = DeliveryButton;
POSInvoiceType.TakeawayButton = TakeawayButton;
POSInvoiceType.DineInButton = DineInButton;

export default POSInvoiceType;
