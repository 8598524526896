import React, { FC, useEffect, useRef } from "react";
import { useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  addCategory,
  getCategoryImageById,
} from "../../serviceBroker/categoryApiServiceBroker";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ActionTypeEnum,
  CategoryResponseModel,
  HasFormIdModel,
  ItemImageModel,
  LoadingManagerProps,
  LookupItemModel,
  LookupTypeEnum,
  PageEnum,
  RequestActionModel,
  RowStateEnum,
  ValidationErrorModel,
  LoadingObjectsEnum,
} from "../../models";
import {
  LoadingBox,
  TextBox,
  SelectBox,
  ButtonBox,
  PrivilegesChecker,
  ItemImageManager,
} from "..";
import {
  getLabelName,
  getLookUpItemNumericValue,
  getPageNameByPageId, validateNoSpaces, validateRequired, yubCombineValidationsManager,
} from "../../utils";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";

interface AddCategoryProps extends LoadingManagerProps, HasFormIdModel {
  request: CategoryResponseModel | null;
  onActionEvent: (o: RequestActionModel) => void;
}

export const AddCategory: FC<AddCategoryProps> = ({
  request,
  onActionEvent,
  hideLoader,
  loadingData,
  showLoader,
  formId,
}) => {
  //#region variables
  const initialValues: CategoryResponseModel = {
    Name: "",
    Name_En: "",
    CreatedBy: 0,
    IsDefault: false,
    IsParent: false,
    Parent_ID: null,
    AllParent: null,
    Code: "",
    CategorySetting: null,
    HasImage: false,
    CreationDate: "",
    Default_Discount_Percentage: 0,
    DisplySequence: 0,
    Errors: [],
    ID: 0,
    IsIgnoreServiceMoneyAdd: false,
    ModificationDate: null,
    ShowInPOS: false,
    VerifyOnUpdate: false,
    ModifiedBy: 0,
    Notes: "",
    rowState: Number(RowStateEnum.Add),
    CategpryImage: null,
  };
  //#endregion
  //#region state
  const selectCategoryRef = useRef<any>(null);
  const [categoryList, setCategoryList] = useState<LookupItemModel[]>([]);
  const validationSchema = Yup.object({
    //Name: Yup.string().required(getLabelName("required")),
    Name: yubCombineValidationsManager([
      validateRequired(),
      validateNoSpaces(),
    ]),
    Name_En:  yubCombineValidationsManager([validateNoSpaces()]),
  });
  //#endregion
  //#region functions
  const getImageDataIfExist = async (category: CategoryResponseModel) => {
    if (!category.HasImage) return null;
    showLoader(LoadingObjectsEnum.FORM);
    const ImageData = await getCategoryImageById(category.ID);
    hideLoader(LoadingObjectsEnum.FORM);
    return ImageData.Result as ItemImageModel;
  };
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (selectCategoryRef.current) selectCategoryRef?.current?.clearValue();
    const fillData = async () => {
      if (request) {
        const imageData = await getImageDataIfExist(request);
        const categoryImage = imageData
          ? { ...imageData, File64Bit: imageData.FileBinary }
          : null;
        await formik.setValues({
          ...request,
          rowState: request ? RowStateEnum.Update : RowStateEnum.Add,
          CategorySetting: request.CategorySetting
            ? {
                ...request.CategorySetting,
                RowState: RowStateEnum.Update,
                rowState: RowStateEnum.Update,
              }
            : null,
          CategpryImage: categoryImage,
        });
      } else {
        await formik.setValues(initialValues);
      }
      const catList = await getLookupByType(LookupTypeEnum.CategoryType);
      setCategoryList(catList);
    };
    fillData().then(() => {});
  }, [request]);
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    // enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);
      resetForm();
    },
  });
  //#endregion
  //#region functions
  const handleSubmit = async (request: CategoryResponseModel) => {
    try {
      showLoader(LoadingObjectsEnum.FORM);
      //@ts-ignore
      const res: ResponseBase<StoreResponse> = await addCategory(request);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        onActionEvent({
          action: ActionTypeEnum.Failed,
          request: res.Errors,
        });
      } else {
        onActionEvent({ action: ActionTypeEnum.AddSuccess });
      }
    } catch (err: any) {
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      onActionEvent({
        action: ActionTypeEnum.Failed,
        request: errors,
      });
    } finally {
      hideLoader(LoadingObjectsEnum.FORM);
    }
  };
  //#endregion
  //#region html
  return (
    <LoadingBox isLoading={loadingData.FORM}>
      <form className="forms-sample" onSubmit={formik.handleSubmit}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {getPageNameByPageId(PageEnum.Categories)}
            </Accordion.Header>
            <Accordion.Body>
              <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
                <SelectBox
                  labelName={getLabelName("Category")}
                  source={categoryList}
                  isSingleSelect={true}
                  onStatusChange={async (e: any) => {
                    const value = getLookUpItemNumericValue(e);
                    formik.values.Parent_ID = value;
                    await formik.setFieldValue("Parent_ID", value);
                  }}
                  selectedValues={[
                    formik.values.Parent_ID !== null &&
                    formik.values.Parent_ID !== 0
                      ? formik.values.Parent_ID.toString()
                      : formik.values?.CategorySetting?.Category_ID.toString() ||
                        "",
                  ]}
                  multiselectRef={selectCategoryRef}
                />
                <TextBox
                  labelName={getLabelName("Arabic Name")}
                  inputName={"Name"}
                  errorText={formik.errors.Name}
                  inputValue={formik.values.Name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <TextBox
                  labelName={getLabelName("Name English")}
                  inputName={"Name_En"}
                  errorText={formik.errors.Name_En}
                  inputValue={formik.values.Name_En}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <TextBox
                  labelName={getLabelName("Code")}
                  inputName={"Code"}
                  errorText={formik.errors.Code}
                  inputValue={formik.values.Code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <TextBox
                  labelName={getLabelName("Discount Default Ration")}
                  inputName={"Default_Discount_Percentage"}
                  errorText={formik.errors.Default_Discount_Percentage}
                  inputValue={formik.values.Default_Discount_Percentage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                />
                <TextBox
                  labelName={getLabelName("Disply Sequence")}
                  inputName={"DisplySequence"}
                  errorText={formik.errors.DisplySequence}
                  inputValue={formik.values.DisplySequence}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                />
              </div>
              <div className="my-4">
                <TextBox
                  labelName={getLabelName("Notes")}
                  inputName={"Notes"}
                  errorText={formik.errors.Notes}
                  inputValue={formik.values.Notes}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="textarea"
                />
              </div>
              <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
                <TextBox
                  labelName={getLabelName("ShowInPOS")}
                  inputName={"ShowInPOS"}
                  errorText={formik.errors.ShowInPOS}
                  inputValue={formik.values.ShowInPOS}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="checkbox"
                />
                <TextBox
                  labelName={getLabelName("Is Default")}
                  inputName={"IsDefault"}
                  errorText={formik.errors.IsDefault}
                  inputValue={formik.values.IsDefault}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="checkbox"
                />
                <TextBox
                  labelName={getLabelName("Is Parent")}
                  inputName={"IsParent"}
                  errorText={formik.errors.IsParent}
                  inputValue={formik.values.IsParent}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="checkbox"
                />

                <TextBox
                  labelName={getLabelName("Is Ignore Service")}
                  inputName={"IsIgnoreServiceMoneyAdd"}
                  errorText={formik.errors.IsIgnoreServiceMoneyAdd}
                  inputValue={formik.values.IsIgnoreServiceMoneyAdd}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="checkbox"
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                <PrivilegesChecker
                  formId={formId}
                  action={request?.ID ? "EnableUpdate" : "EnableSave"}
                >
                  <ButtonBox
                    iconType="content-save"
                    type="submit"
                    className="btn-primary btn-sm mx-3"
                  >
                    {getLabelName(request?.ID ? "Update" : "Save")}
                  </ButtonBox>
                </PrivilegesChecker>
                <ButtonBox
                  iconType="receipt"
                  variant="danger"
                  type="button"
                  className="btn-sm"
                  onClick={(e) => {
                    formik.handleReset(e);
                    onActionEvent({ action: ActionTypeEnum.Clear });
                  }}
                >
                  {getLabelName("New")}
                </ButtonBox>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              {getLabelName("image category")}
            </Accordion.Header>
            <Accordion.Body
              className="overflow-visible position-relative"
              style={{ minHeight: 200 }}
            >
              <ItemImageManager
                isImageLoading={false}
                image={formik.values.CategpryImage}
                deleteImage={() => {
                  if (!request?.ID)
                    return formik.setFieldValue("CategpryImage", null);
                  formik.setFieldValue("CategpryImage", {
                    ...formik.values.CategpryImage,
                    rowState: RowStateEnum.Delete,
                  });
                  formik.setFieldValue("HasImage", false);
                }}
                setFileBase64={(base64: string | null) => {
                  if (base64 === null)
                    return formik.setFieldValue("CategpryImage", null);
                  formik.setFieldValue("CategpryImage", {
                    File64Bit: base64,
                    ID: request?.CategpryImage?.ID
                      ? request?.CategpryImage?.ID
                      : 0,
                    ImageDescription: `${formik.values.Name} item`,
                    ImageName: `${formik.values.Name} item`,
                    Name: formik.values.Name,
                    rowState: request?.ID
                      ? RowStateEnum.Update
                      : RowStateEnum.Add,
                  });
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </form>
    </LoadingBox>
  );
  //#endregion
};
