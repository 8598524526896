import React, {FC, ReactNode, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {LayoutHeader, LayoutFooter, LoadingBox, ButtonBox, CloseDayDetails} from "../../..";
import {useCulture,useSidebar} from "../../../../hooks";
import { getDefaultLanguage, getLabelName, getUserId,
    getUserName, getUserStationId,
    isArabicCurrentLanguage,
    isUserAuthenticated,secureLocalStorageClearLookupCache,
    secureLocalStorageGet,
} from "../../../../utils";
import {ActionTypeEnum, MenuItemNameModel, RequestActionModel} from "../../../../models";
import {SystemConfiguration} from "../../../../configuration";
import {LangSwitcherReactI18} from "../../../languageSwitcher/react-i18/langSwitcher";
import face1 from "../../../../assets/images/faces/face1.jpg";
import {logoutUser} from "../../../../slice/userAuthincateSlice";

export const DefaultLayout: FC<{ children?: ReactNode | undefined }> = ({
                                                                            children,
                                                                        }) => {
    //#region state
    const {changeCulture} = useCulture("");
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showCloseDayModel, setShowCloseDayModel] = useState(false);
    //const [isOpen, setIsOpen] =  useState(false);
    const {isOpen, setIsOpen} =  useSidebar();// useState(false);
    //#endregion
    //#region variables
    const language: string = getDefaultLanguage();
    //#endregion
    //#region use-Effect

    useEffect(() => {
            document.body.classList.toggle('sidebar-close', isOpen);
    }, [isOpen])
    useEffect(() => {
        const updateCulture = async () => {
            setLoading(true);
            await changeCulture(language);
            setLoading(false);
        };
        updateCulture().then(() => {});
        //changeCulture(language).then(() => {});
    }, [language]);
    //#endregion
    //#region  functions
    const getPageName = (): string => {
        let pageName: string = "";
        const menuItems: MenuItemNameModel[] = JSON.parse(
            secureLocalStorageGet(SystemConfiguration.cacheKey_Configurations.menu.menuItemName || "") as string
        ) as MenuItemNameModel[];
        if (
            menuItems !== null &&
            menuItems !== undefined &&
            menuItems.length !== 0
        ) {
            const page = menuItems.filter(
                (p) => p.url === window.location.pathname.toLowerCase()
            )[0];
            pageName =
                page !== null && page !== undefined
                    ? isArabicCurrentLanguage()
                        ? page.nameAr
                        : page.name
                    : "";
        }
        return pageName;
    };
    const handleLogout = async () => {
        dispatch(
            // @ts-ignore
            logoutUser());
        navigate(SystemConfiguration.others_Configurations.logoutRedirectUrl);
    }
    const handleAction = async (request: RequestActionModel) => {
        switch (request.action) {
            case ActionTypeEnum.CloseModelPopup:
                setShowCloseDayModel(false);
                break;
        }
    }
    //#endregion
    //#region html
    return (
        <div className="container-scroller">
            {loading && <LoadingBox/>}
            <div className="container-fluid page-body-wrapper">
                {<LayoutHeader/>}
                <div className="main-panel">
                    {isUserAuthenticated() && (
                        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 d-flex flex-row">
                            <div className="navbar-menu-wrapper d-flex align-items-stretch">
                                <ButtonBox
                                    variant=""
                                    className="navbar-toggler navbar-toggler-right align-self-center"
                                    type="button"
                                    data-toggle="minimize"
                                    onClick={() => {setIsOpen(!isOpen)}}
                                >
                                    <span className="mdi mdi-menu"></span>
                                </ButtonBox>
                                <ul className="navbar-nav navbar-nav-right">
                                    <li className="nav-item nav-profile dropdown">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            id="profileDropdown"
                                            href="#"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <div className="nav-profile-img">
                                                <img src={face1} alt="image"/>
                                                <span className="availability-status online"></span>
                                            </div>
                                            <div className="nav-profile-text">
                                                <p className="mb-1 text-black">{getUserName()}</p>
                                            </div>
                                        </a>
                                        <div
                                            className="dropdown-menu navbar-dropdown"
                                            aria-labelledby="profileDropdown"
                                        >
                                            {/* {isUserAuthenticated() && ( */}
                                            <>
                                                <div className="dropdown-item" onClick={async () => {
                                                    setLoading(true);
                                                    await secureLocalStorageClearLookupCache();
                                                    setLoading(false);
                                                }}>
                                                    <i className="mdi me-2 text-primary"></i>
                                                    {getLabelName("cache Refresh")}
                                                </div>
                                                <div className="dropdown-item" onClick={async () => {
                                                    setLoading(true);
                                                    setShowCloseDayModel(true)
                                                    setLoading(false);
                                                }}>
                                                    <i className="mdi mdi-logout me-2 text-primary"></i>
                                                    {getLabelName("Close Day")}
                                                </div>
                                                <div className="dropdown-item" onClick={async () => {
                                                    await handleLogout();
                                                }}>
                                                    <i className="mdi mdi-logout me-2 text-primary"></i>
                                                    {getLabelName("logout")}
                                                </div>
                                                {showCloseDayModel &&
                                                    <CloseDayDetails
                                                        userId={getUserId()}
                                                        stationId={getUserStationId()}
                                                        onActionEvent={async (o: RequestActionModel) => {
                                                            await handleAction(o);
                                                        }}
                                                    />}
                                                {/*<ButtonBox*/}
                                                {/*  variant=""*/}
                                                {/*  className="dropdown-item"*/}
                                                {/*  onClick={async () => {*/}
                                                {/*    dispatch(*/}
                                                {/*      // @ts-ignore*/}
                                                {/*      logoutUser()*/}
                                                {/*    );*/}
                                                {/*    navigate(*/}
                                                {/*      "/logoutRedirect?iOZQilU=LyKiRiQEPDibKuuGECyJ"*/}
                                                {/*    );*/}
                                                {/*  }}*/}
                                                {/*>*/}
                                                {/*  <i className="mdi mdi-logout me-2 text-primary"></i>*/}
                                                {/*  {t("logout.button")}*/}
                                                {/*</ButtonBox>*/}
                                                {/*<ButtonBox*/}
                                                {/*  variant=""*/}
                                                {/*  className="dropdown-item"*/}
                                                {/*  onClick={async ()=>{*/}
                                                {/*    setLoading(true);*/}
                                                {/*    await  SecureLocalStorageClearLookupCache();*/}
                                                {/*     setLoading(false);*/}
                                                {/*  }}*/}
                                                {/*>*/}
                                                {/*  <i className="mdi  me-2 text-primary"></i>*/}
                                                {/*  {t("cache.Refreshe")}*/}
                                                {/*</ButtonBox>*/}
                                            </>
                                        </div>
                                    </li>
                                    <li>
                                        <LangSwitcherReactI18/>
                                    </li>
                                </ul>
                                {/*<ButtonBox
                  className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                  type="button"
                  data-toggle="offcanvas"
                >
                  <span className="mdi mdi-menu"></span>
                </ButtonBox>
                 <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                            </div>
                        </nav>
                    )}
                    <div className="content-wrapper">
                        {/*{<label>{getPageName()}</label>}*/}
                        {children}
                    </div>
                    {<LayoutFooter/>}
                </div>
            </div>
        </div>
    );
    //#endregion
};
