import {useState} from "react";
import {SearchFieldRequestModel} from "../../models";

const useSearchBox = () => {
    const [value, setValue] = useState("");
    const [searchedWord, setSearchWord] = useState("");
    const onChange = (e: any) => setValue(e.target.value);
    const onSearch = () => setSearchWord(value);
    const onReset = () => setValue("");

    return {value, onReset, onChange, onSearch, searchedWord};
};

const useSearchBoxGeneric = (initialFields: SearchFieldRequestModel[]) => {
    //#region state
    const [fields, setFields] = useState(initialFields);
    const [searchedFields, setSearchedFields] = useState<SearchFieldRequestModel[]>([]);
    //#endregion
    //#region functions
    const onChange = (key: string, newValue: string) => {
        setFields((prevFields) =>
            prevFields.map((field) =>
                field.key === key ? {...field, value: newValue} : field
            )
        );
        const field = fields.find((f) => f.key === key);
        if (field?.triggerSearchOnChange) {
            setSearchedFields((prevSearchedFields) =>
                prevSearchedFields.map((field) =>
                    field.key === key ? {...field, value: newValue} : field
                )
            );
        }
    };
    const onSearch = () => {
        setSearchedFields(fields);
    };
    const onReset = () => {
        setFields(initialFields);
        setSearchedFields(initialFields);
        // setFields((prevFields) =>
        //     prevFields.map((field) => ({ ...field, value: "" }))
        // );
    };
    //#endregion
    return {fields, onChange, onSearch, onReset, searchedFields};
};
export {useSearchBox, useSearchBoxGeneric} ;
