import React, { FC, useEffect, useState } from "react";
import {
  DashBoardAlertSummary,
  DashBoardStatisticsSummary,
  LoadingBox,
} from "../../components";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";
import {
  CategorySettingModel,
  DashboardSummaryResponseModel,
  LookupTypeEnum,
  UserAlertResponseModel,
} from "../../models";
import {
  getUserAlert,
  getUserDashboardSummary,
} from "../../serviceBroker/userApiServiceBroker";
import { getLabelName } from "../../utils";
export const DashboardPage: FC = () => {
  //#region state
  const [loading, setLoading] = useState(false);
  const [userDashBoardData, setUserDashBoardData] =
    useState<DashboardSummaryResponseModel | null>(null);
  const [userAlertData, setUserAlertData] = useState<UserAlertResponseModel[]>(
    []
  );
  //#endregion
  //#region useEffect
  useEffect(() => {
    //checkUserMultipleBranchesEnabled();
    const fillData = async () => {
      setLoading(true);
      //const px= getLookupByType<CategorySettingModel>(LookupTypeEnum.PosCategoryType, true, true);
      await fillLookupDataPromiseAll();
      await fillSummaryDataPromiseAll();
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region functions
  const fillLookupDataPromiseAll = async () => {
    const [, , , , , , , ,] = await Promise.all([
      getLookupByType(LookupTypeEnum.Customers, true, false),
      getLookupByType(LookupTypeEnum.Supplier, true, false),
      getLookupByType(LookupTypeEnum.CategoryType, true, true),
      getLookupByType<CategorySettingModel>(
        LookupTypeEnum.PosCategoryType,
        true,
        true
      ),
      getLookupByType(LookupTypeEnum.Items, true, true),
      getLookupByType(LookupTypeEnum.PaymentType, true, true),
      getLookupByType(LookupTypeEnum.Currency, true, true),
      getLookupByType(LookupTypeEnum.Employee, true, true),
      getLookupByType(LookupTypeEnum.CalcType, true, true),
      getLookupByType(LookupTypeEnum.CostCenter, true, true),
    ]);
  };
  const fillSummaryDataPromiseAll = async () => {
    const [userDahBoardResult, userAlertsResult] = await Promise.all([
      getUserDashboardSummary(),
      getUserAlert(),
    ]);
    setUserDashBoardData(userDahBoardResult);
    setUserAlertData(userAlertsResult);
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <div className="page-header">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i className="mdi mdi-home"></i>
          </span>{" "}
          {getLabelName("Dashboard")}
        </h3>
      </div>
      <DashBoardStatisticsSummary request={userDashBoardData} />
      <DashBoardAlertSummary request={userAlertData} />
    </>
  );
  //#endregion
};
