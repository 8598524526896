import React, {FC, useRef, useState} from "react";
import {
    ActionButtonsModel,
    ActionTypeEnum,
    ItemModel, LookupTypeEnum,
    RequestActionModel,
    ResponseBaseModel, RowStateEnum,
    ToastModel,
    ValidationErrorModel
} from "../../models";
import {
    generateGuid,
    getLabelName,
    isArabicCurrentLanguage,
    reValidateLocalStorageCachedData,
    scrollToTop
} from "../../utils";
import {deleteItem, getItemByItemInStoreId} from "../../serviceBroker/itemApiServiceBroker";
import {ErrorValidationBox} from "../common/box/errorValidationBox";
import {ConfirmModelDialogBox, ItemListManager, ItemRegistrationManager, ToastBox} from "../index";
import {Accordion} from "react-bootstrap";
import { handleCalculatePriceWithTax} from "./businessLogic/itemStoresBl";

export  const ItemManager:FC = () => {
    //#region state
    const [isFormLoading, setFormLoading] = useState(false);
    const [isTableLoading, setTableLoading] = useState(false);
    const [isModalLoading, setModalLoading] = useState(false);
    const [isRefreshItemList, setIsRefreshItemList] = useState(false);
    const [showDeleteUserModel, setShowDeleteUserModel] = useState(false);
    const [isRefreshItem, setIsRefreshItem] = useState(false);
    const [object, setObject] = useState<ItemModel | null>(null);
    const isArabic = isArabicCurrentLanguage();
    const [toastModel, setToastModel] = useState<ToastModel>({
        show: false,
    });
    const pageRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [validationErrors, setValidationErrors] = useState<
        ValidationErrorModel[]
    >([]);
    //#endregion
    //#region function
    const handleAction = async (request: RequestActionModel) => {
        setValidationErrors([]);
        switch (request.action) {
            case ActionTypeEnum.Add:
                setObject(null);
                break;
            case ActionTypeEnum.Update:
                setFormLoading(true);
                setTableLoading(true);
                let item = (await getItemByItemInStoreId(
                    request.request.ItemInstore_ID!
                )) as ResponseBaseModel<ItemModel>;
                item.Result!.rowState = 2;
                item.Result!.ItemsInstores = item.Result!.ItemsInstores.map(
                    (itemInStore) => ({
                        ...itemInStore,
                        rowState: RowStateEnum.Update,
                        Item_unit: itemInStore?.Item_unit?.map((itemUnit) => ({
                            ...itemUnit,
                            PriceSaleWithTax:handleCalculatePriceWithTax(itemUnit.PriceSale,item.Result?.TaxValue??0),
                            PriceQutyBegBalWithTax:handleCalculatePriceWithTax(itemUnit.PriceQutyBegBal,item.Result?.TaxValue??0),
                            rowState: RowStateEnum.Update,
                            rowKey:generateGuid()

                        })),
                        ItemGathers:itemInStore?.ItemGathers?.map((itemGather) => ({
                            ...itemGather,
                            rowState: RowStateEnum.Update,
                            rowKey:generateGuid()
                        }))
                    })
                );
                if (item.Result!.Item_Modifiers !== null)
                    item.Result!.Item_Modifiers = item.Result!.Item_Modifiers?.map(
                        (item) => ({
                            ...item,
                            rowState:  RowStateEnum.Update,
                            rowKey:generateGuid()
                        })
                    );
                item.Result!.rowState = 2;
                setObject(item.Result!);
                setIsRefreshItem(true);
                setTableLoading(false);
                setFormLoading(false);
                scrollToTop(pageRef);
                break;
            case ActionTypeEnum.Delete:
                setObject(null);
                const deleteObject=(await getItemByItemInStoreId(request.request.ItemInstore_ID!)).Result;
                setObject(deleteObject??null);
                setShowDeleteUserModel(true);
                break;
            case ActionTypeEnum.AddSuccess:
            case ActionTypeEnum.DeleteSuccess:
            case ActionTypeEnum.Success:
                setToastModel({ ...toastModel, show: true, variant: "success" });
                setIsRefreshItemList(true);
                setIsRefreshItem(true);
                setObject(null);
                setShowDeleteUserModel(false);
                await reValidateLocalStorageCachedData(LookupTypeEnum.Items)
                scrollToTop(pageRef);
                break;
            case ActionTypeEnum.RaiseError:
                setValidationErrors(request.request);
                scrollToTop(pageRef);
                break;
            case ActionTypeEnum.DeleteFailed:
                setShowDeleteUserModel(false);
                setValidationErrors(request.request);
                scrollToTop(pageRef);
                break;
            case ActionTypeEnum.Clear:
                setObject(null);
                // setIsRefreshItem(true);
                scrollToTop(pageRef);
                break;
            case ActionTypeEnum.ScrollToTop:
                scrollToTop(pageRef);
                break;
        }
    };
    const deleteItemAction: (id: number) => ActionButtonsModel[] = (
        id: number
    ) => {
        return [
            {
                text: getLabelName("yes"),
                onClick: async () => {
                    setModalLoading(true);
                    const result = await deleteItem(id);
                    setModalLoading(false);
                    await handleAction({
                        request: result.Result?.Result?null : result?.Errors,
                        action: result.Result?.Result
                            ? ActionTypeEnum.Success
                            : ActionTypeEnum.DeleteFailed,
                    });
                },
            },
            {
                text: getLabelName("no"),
                onClick: () => {
                    setObject(null);
                    setShowDeleteUserModel(false);
                },
            },
        ];
    };
    //#endregion
    //#region html
    return (
        <>
            <div ref={pageRef}/>
            {<ErrorValidationBox errors={validationErrors}/>}
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: false});
                    }}
                />
            )}
            <ConfirmModelDialogBox
                isModelVisible={showDeleteUserModel}
                onCloseEvent={() => {
                    setShowDeleteUserModel(false);
                    setObject(null);
                }}
                isLoading={isModalLoading}
                actions={deleteItemAction(object?.ID as number)}
            >
                <div className="alert alert-warning">
                    {`${getLabelName(
                        "are you sure you want delete"
                    )} ${isArabic ? object?.Name : object?.Name_En}`}
                </div>
            </ConfirmModelDialogBox>
            <ItemRegistrationManager
                request={object}
                setIsRefresh={setIsRefreshItem}
                isRefresh={isRefreshItem}
                onActionEvent={async (o: RequestActionModel) => {
                    await handleAction(o);
                }}
                isFormLoading={isFormLoading}
                setFormLoading={setFormLoading}
            />
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
                    <Accordion.Body className="TUEFO-header">
                        <ItemListManager
                            onActionEvent={async (o: RequestActionModel) => {
                                await handleAction(o);
                            }}
                            setIsRefresh={setIsRefreshItemList}
                            isRefresh={isRefreshItemList}
                            isTableLoading={isTableLoading}
                            setTableLoading={setTableLoading}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
    //#endregion
}