import {FC, useEffect, useState} from "react";
import { isArabicCurrentLanguage } from "../../utils";
import {GridTable, LoadingBox, PrivilegesChecker, SearchBox} from "..";
import {ActionTypeEnum, HasFormIdModel, PageEnum, RequestActionModel, UnitModel} from "../../models";
import { useSearchBox } from "../../hooks";
import {getUnits} from "../../serviceBroker/unitApiServiceBroker";


interface UnitListProps extends HasFormIdModel {
    onActionEvent: (o: RequestActionModel) => void;
    isRefresh: boolean;
}

export const UnitList: FC<UnitListProps> = ({
onActionEvent = () => {},isRefresh=false, formId
}) => {
    //#region variable
    const isArabic: boolean = isArabicCurrentLanguage();
    const defaultPageSize: number = 50;
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [units, setUnits] = useState<UnitModel[]>([]);
    const [filteredUnits, setFilteredUnits] = useState<UnitModel[]>([]);
    const {onChange, onSearch, searchedWord, value} = useSearchBox();
    //#endregion
    //#region function
    const getAllUnits = async () => {
        setLoading(true);
        const unitList = await getUnits();
        setUnits(unitList?.Result == null ? units : unitList?.Result);
        setFilteredUnits(unitList?.Result == null ? units : unitList?.Result);
        setLoading(false);
    };
    // #endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            await getAllUnits();
        };
        fillData().then(() => {});
    }, []);
    useEffect(() => {
        if (isRefresh) {
            const fillData = async () => {
                await getAllUnits();
            };
            fillData().then(() => {});
            onActionEvent({action:ActionTypeEnum.Refresh,request:false});
        }
    }, [isRefresh]);
    useEffect(() => {
        const searchValue = searchedWord && searchedWord.trim() !== "" ? searchedWord : value;
        if (!searchValue.trim()) {
            setFilteredUnits(units);
        } else {
            // Filter based on Arabic or English name
            setFilteredUnits(
                units.filter(
                    (p) =>
                        p.Name.toLowerCase().trim().includes(searchValue.toLowerCase().trim()) || // Match Arabic name
                        p.Name_En.toLowerCase().trim().includes(searchValue.toLowerCase().trim()) // Match English name
                )
            );
        }
    }, [searchedWord,value]);
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            <PrivilegesChecker formId={PageEnum.Units} action="EnableSearch">
                <SearchBox
                    onChangeHandler={onChange}
                    onSearchHandler={onSearch}
                    searchedWord={value}
                    name="name"
                />
            </PrivilegesChecker>
            <GridTable
                data={filteredUnits}
                totalRows={filteredUnits.length}
                currentPage={1}
                pageSize={defaultPageSize}
                paginationType="client"
                columnsProps={{
                    actions: [ActionTypeEnum.Update, ActionTypeEnum.Delete],
                    onActionEvent,
                    isArabic,
                }}
                formId={formId}
            />
        </>
    );
    //#endregion
};
