import React from "react";
import { getBase64, getLabelName} from "../../utils";
import {ItemImageModel, RowStateEnum} from "../../models";
import {ButtonBox, Loading, EntityUploader} from "../";
import {SystemConfiguration} from "../../configuration";

export interface ItemImageFormProps {
    image: ItemImageModel | null;
    setFileBase64: (base64: string | null) => void;
    isImageLoading: boolean;
    deleteImage: () => void;
    width?: number;
    height?: number;
}

export const ItemImageManager: React.FC<ItemImageFormProps> = ({
                                                                image,
                                                                setFileBase64,
                                                                isImageLoading,
                                                                deleteImage,
                                                                width=SystemConfiguration.image_Configurations.defaultWidth,
                                                                height=SystemConfiguration.image_Configurations.defaultHeight
                                                            }) => {
    //#region variables
    const isImageLoaded = !!image;
    //#endregion
    //#region functions
    const handleFileDrop = async (files: any) => {
        if (files.length === 0) return;
        const base64 = await getBase64(files[0]);
        setFileBase64(base64.replace(/^data:image\/(jpeg|png|jpg);base64,/, ""));
    };
    const handleFileDelete = async () => {
        deleteImage();
    };
//#endregion
    //#region html
    if (isImageLoading) return <Loading/>;
    if (isImageLoaded && image.rowState !== RowStateEnum.Delete)
        return (
            <>
                <img
                    src={`data:image/png;base64,${image.File64Bit}`}
                    style={{
                        display: "block",
                        width: width, // Set fixed width
                        height: height, // Set fixed height
                        objectFit: "contain", // Ensure aspect ratio is maintained
                        margin: "0px auto",
                    }}
                    alt=""/>
                <div className="my-4 d-flex justify-content-end">
                    <ButtonBox
                    iconType="delete"
                        variant="danger"
                        type="button"
                        className="btn btn-sm"
                        onClick={handleFileDelete}
                    >
                        {getLabelName("delete")}
                    </ButtonBox>
                </div>
            </>
        );
    return (
        <>
            {
                <EntityUploader
                    acceptTypes={{
                        allowedMimeTypes: ["image/png", "image/jpg", "image/jpeg"],
                    }}
                    handleFileDrop={handleFileDrop}
                />
            }
        </>
    );
    //#endregion
};
