import React, {useState} from "react";
import {Accordion} from "react-bootstrap";
import {getLabelName} from "../../utils";
import {BranchList, BranchForm, ToastBox, ErrorValidationBox} from "..";
import {ActionTypeEnum, RequestActionModel, ToastModel, ValidationErrorModel} from "../../models";
import {useBranchesData} from "../../hooks";

export interface BranchesChildComponent {
    currentBranchID: number | null;
    setCurrentBranchID: React.Dispatch<React.SetStateAction<number | null>>;
    fetchBranches: () => Promise<void>;
}
export const BranchesMainComponent = () => {
    //#region state
    const [toastModel, setToastModel] = useState<ToastModel>({
        show: false,
    });
    const [currentBranchID, setCurrentBranchID] = useState<null | number>(null);
    const {branchesLoading, branches, fetchBranches, setBranches} =
        useBranchesData();
    const [branchesFormLoading, setBranchesFormLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<
        ValidationErrorModel[]
    >([]);
    //#endregion
    //#region function
    const handleUserAction = async (request: RequestActionModel) => {
        switch (request.action) {
            case ActionTypeEnum.Failed:
                setValidationErrors(request.request);
                break;
            case ActionTypeEnum.Success:
            default:
                setValidationErrors([]);
                break;
        }
    }
    //#endregion
    //#region html
    return (
        <>
            {<ErrorValidationBox errors={validationErrors} />}
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: false});
                    }}
                />
            )}
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{getLabelName("Branches")}</Accordion.Header>
                    <Accordion.Body className="TUEFO-header">
                        <BranchForm
                            setToastModel={setToastModel}
                            currentBranchID={currentBranchID}
                            setCurrentBranchID={setCurrentBranchID}
                            fetchBranches={fetchBranches}
                            branchesFormLoading={branchesFormLoading}
                            setBranchesFormLoading={setBranchesFormLoading}
                            onActionEvent={async (o: RequestActionModel) => {
                                await handleUserAction(o);
                            }}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
                    <Accordion.Body className="TUEFO-header">
                        <BranchList
                            isTableLoading={false}
                            currentBranchID={currentBranchID}
                            setCurrentBranchID={setCurrentBranchID}
                            branches={branches}
                            branchesLoading={branchesLoading}
                            fetchBranches={fetchBranches}
                            setBranchesFormLoading={setBranchesFormLoading}
                            setBranches={setBranches}
                            onActionEvent={async (o: RequestActionModel) => {
                                await handleUserAction(o);
                            }}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
    //#endregion
};
