import {FC, useState} from "react";
import {Accordion} from "react-bootstrap";
import {
  ConfirmModelDialogBox,
  ErrorValidationBox,
  LoadingBox,
  ModelDialogBox,
  POSSettings,
  RegisterUser,
  ToastBox,
  TransactionSettings,
  UserPermissions,
  UserSettings,
  UsersList,
} from "../../components";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  HasFormIdModel, LookupTypeEnum,
  RequestActionModel,
  RowStateEnum,
  ToastModel,
  UserRegistrationResponseModel,
  ValidationErrorModel,
} from "../../models";
import {deleteUser, getUserInformation} from "../../serviceBroker/userApiServiceBroker";
import {
  getActionMessage,
  getLabelName,
  getUserId,
  handleLanguageChange,
  reValidateLocalStorageCachedData,
} from "../../utils";
import {SystemConfiguration} from "../../configuration";

export const UsersPage: FC<HasFormIdModel> = ({ formId }) => {
  //#region variables
  const deleteUserActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        await handleDeleteUser();
      },
    },
    {
      text: getLabelName("no"),
      variant: "danger",
      onClick: () => {
        setUser(null);
        setShowDeleteUserModel(false);
      },
    },
  ];
  //#endregion
  //#region state
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [user, setUser] = useState<UserRegistrationResponseModel | null>(null);
  const [userId,setUserId]=useState(0);
  const [showTransactionSettingModel, setShowTransactionSettingModel] =
    useState(false);
  const [showUserPOSSettingModel, setShowUserPOSSettingModel] = useState(false);
  const [toastModel, setToastModel] = useState<ToastModel>({ show: false });
  const [showDeleteUserModel, setShowDeleteUserModel] = useState(false);
  const [showUserPermissionModel, setShowUserPermissionModel] = useState(false);
  const [showUserSettingModel, setShowUserSettingModel] = useState(false);
  //#endregion
  //#region function
  const getDefaultUserId: () => number = () => {return 0;};
  const handleUserAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.CloseModelPopup:
        await initializeStates();
        break;
      case ActionTypeEnum.AddSuccess:
      case ActionTypeEnum.Success:
        setLoading(true);
        await initializeStates();
        setIsRefresh(true);
        setToastModel({
          ...toastModel,
          variant: "success",
          body: getActionMessage(request.id as ActionTypeEnum),
          show: true,
        });

        setLoading(false);
        break;
      case ActionTypeEnum.UserSettingOperationCompleted:
        setLoading(true);
        setToastModel({
          ...toastModel,
          variant: "success",
          show: true,
        });
        await initializeStates();
        if(request.request.isLanguageSettingUpdated && request.request.userId==getUserId()) {
          await reValidateLocalStorageCachedData(LookupTypeEnum.UserSettingCacheKey, request.request.userId);
          await handleLanguageChange(request.request.userId,true);
        }
        setLoading(false);
        break;
      case ActionTypeEnum.UserPosSettingOperationCompleted:
      case ActionTypeEnum.UserTransactionSettingOperationCompleted:
        setLoading(true);
        setToastModel({
          ...toastModel,
          variant: "success",
          show: true,
        });
        await initializeStates();
        setLoading(false);
        break;
      case ActionTypeEnum.UserPermissionSettingOperationCompleted:
        setLoading(true);
        setToastModel({
          ...toastModel,
          variant: "success",
          show: true,
        });
        await initializeStates();
        await reValidateLocalStorageCachedData(LookupTypeEnum.UserPosSettingCacheKey, request.request.userId);
        setLoading(false);
        break;
      case ActionTypeEnum.Update:
        setValidationErrors([]);
        window.scrollTo(0, 0);
        const userObjectUpdate = await getUserInformation(request.id!);
        // @ts-ignore
        userObjectUpdate.rowState = Number(RowStateEnum.Update);
        setUser(userObjectUpdate);
        break;
      case ActionTypeEnum.Delete:
        setValidationErrors([]);
        const userObjectDelete = await getUserInformation(request.id!);
        setUser(userObjectDelete);
        setShowDeleteUserModel(true);
        break;
      case ActionTypeEnum.TransactionSetting:
        setUserId(request.id!);
        setShowTransactionSettingModel(true);
        break;
      case ActionTypeEnum.POSSetting:
        setUserId(request.id!);
        setValidationErrors([]);
        setShowUserPOSSettingModel(true);
        break;
      case ActionTypeEnum.GrantPermissions:
        setValidationErrors([]);
        setUserId(request.id!);
        setShowUserPermissionModel(true);
        break;
      case ActionTypeEnum.UserSetting:
        setValidationErrors([]);
        setUserId(request.id!);
        setShowUserSettingModel(true);
        break;
      case ActionTypeEnum.Failed:
        setValidationErrors(request.request);
        switch (request.id) {
          case ActionTypeEnum.UserSetting:
            setLoading(false);
            break;
          case ActionTypeEnum.TransactionSetting:
            break;
          case ActionTypeEnum.POSSetting:
            break;
        }
        break;
      case ActionTypeEnum.Clear:
        setUser(null);
        setValidationErrors([]);
        break;
      case ActionTypeEnum.Refresh:
        setIsRefresh(request.request);
        break;
    }
  };

  const handleDeleteUser = async () => {
    setShowDeleteUserModel(false);
    const deleteUserResponse = await deleteUser(user !== null ? user.ID : 0);
    const isSuccess: boolean = !(
      deleteUserResponse.Errors != null &&
      deleteUserResponse.Errors.length !== 0
    );
    if (isSuccess) {
      setIsRefresh(true);
    } else {
      setValidationErrors(deleteUserResponse.Errors);
    }
    setUser(null);
    isSuccess && setToastModel({ ...toastModel, show: true,body:getLabelName("Deleted successfully"), variant: "success" });
  };
  const initializeStates =async () => {
    setValidationErrors([]);
    setUser(null);
    setUserId(getDefaultUserId());
    setShowDeleteUserModel(false);
    setShowUserPOSSettingModel(false);
    setShowUserPermissionModel(false);
    setShowUserSettingModel(false);
    setShowTransactionSettingModel(false);
  }
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {getLabelName("user")}
          </Accordion.Header>
          <Accordion.Body>
            <RegisterUser
              request={user}
              onActionEvent={async (o: RequestActionModel) => {
                await handleUserAction(o);
              }}
              formId={formId}
            />
            {/* transaction  setting*/}
            <ModelDialogBox
              isModelVisible={showTransactionSettingModel}
              isCloseButtonVisible={false}
              title={getLabelName("transaction settings")}
              size="xl"
            >
              <TransactionSettings
                userId={userId}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleUserAction(o);
                }}
              />
            </ModelDialogBox>
            {/* Point Of Sale  setting*/}
            <ModelDialogBox
              isModelVisible={showUserPOSSettingModel}
              isCloseButtonVisible={false}
              title={getLabelName("pos setting")}
              size="xl"
            >
              <POSSettings
                userId={userId}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleUserAction(o);
                }}
              />
            </ModelDialogBox>
            <ModelDialogBox
              isModelVisible={showUserPermissionModel}
              isCloseButtonVisible={false}
              size="xl"
              title={getLabelName("user permissions")}
            >
              <UserPermissions
                  userId={userId}
                onActionEvent={async (o) => {
                  await handleUserAction(o);
                }}
              />
            </ModelDialogBox>
            {/* user  setting*/}
            <ModelDialogBox
              isModelVisible={showUserSettingModel}
              isCloseButtonVisible={false}
              title={getLabelName("user settings")}
              size="xl"
            >
              <UserSettings
                  userId={userId}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleUserAction(o);
                }}
              />
            </ModelDialogBox>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("users")}</Accordion.Header>
          <Accordion.Body>
            <ConfirmModelDialogBox
              isModelVisible={showDeleteUserModel}
              onCloseEvent={() => {
                setShowDeleteUserModel(false);
                setUser(null);
              }}
              actions={deleteUserActions}
            >
              <div className="alert alert-warning">
                {`${getLabelName(
                  "are you sure you want delete"
                )} ${getLabelName("user")} ${user?.User_Name}`}
              </div>
            </ConfirmModelDialogBox>
              <UsersList
                isRefresh={isRefresh}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleUserAction(o);
                }}
                formId={formId}
              />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
